import {
  InboxSection,
  InboxSectionHeaderColor,
} from 'app/inbox/components/InboxSection';
import { InboxSections } from 'app/inbox/types';
import { useAppState } from 'app/state';

import styles from './styles/InboxV2.module.scss';

export const InboxV2 = () => {
  const showInbox = useAppState((state) => state?.inboxV2?.showInbox);

  return (
    <>
      {showInbox ? (
        <div data-testid="inboxV2" className={styles.inboxContainer}>
          <h1>InboxV2</h1>
          <InboxSection
            headerColor={InboxSectionHeaderColor.LIGHT_GREY}
            sectionTitle="Section Title1"
            section={InboxSections.SCHEDULED}
          >
            test
          </InboxSection>
        </div>
      ) : null}
    </>
  );
};
