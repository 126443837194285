import { InboxContainer } from 'app/inbox/InboxContainer';
import VerticalMenu from 'app/inbox/vertical-menu';
import { InboxV2 } from 'app/inboxV2';
import { classNameCombiner } from 'utils';
import React, { ReactNode } from 'react';

import styles from './CareHubScreen.module.scss';

/** The core "frame" / "layout" of the app - e.g. top-bar, content area etc */
type Props = {
  children?: ReactNode;
};

function _CareHubScreen(props: Props) {
  return (
    <div className={classNameCombiner([styles.careHubScreen])}>
      <div className={styles.verticalMenuContainer}>
        <VerticalMenu />
      </div>

      <InboxContainer />
      <InboxV2 />

      <div className={styles.careHubScreenWrapper}>{props.children}</div>
    </div>
  );
}
const CareHubScreen = React.memo(_CareHubScreen);
export default CareHubScreen;
