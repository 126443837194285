import {
  ChannelsToUpdateState,
  MessageToDisplay,
  MessageType,
  RefetchHistoryActionPayload,
} from 'app/coach/pubnub/types';
import { formatAndFilterHistory } from 'app/coach/pubnub/utils';
import { setShouldPlayNotificationSound } from 'app/state/chat/actions';

import {
  insertNewMessagesAndUpdateStatuses,
  updateStatusesOfMessages,
  updateUnreadMessages,
} from '../actions';
import {
  noHistoryForRequestedChannelErrorMessage,
  pubnubTimeTokenToMoment,
  refetchHistoryErrorMessage,
} from '../utils';
import { ActionHandler } from './types';

/* to update the messages correctly we need:
(1) not to lose anything we already display on the client,
(2) insert new messages (if any) that we get when re-fetching the messages.
Sometimes pubnub can "forget" to return some messages it has, which means we can't simply replace the messages kept on the client w/ the
messages from the response. */
export const onRefetchHistory = async ({
  action,
  redux,
  context,
}: ActionHandler<RefetchHistoryActionPayload>) => {
  const { logger } = context.services;
  const { payload: refetchHistoryPayload } = action;
  const {
    inbox: { messagesMap },
  } = redux.getState();

  const {
    convosMapForRefetching,
    channelsToUpdateUnreadMessagesCount,
  } = refetchHistoryPayload;

  logger.info(
    'onRefetchHistory: Refetching history for channels with unread message',
    {
      channelsToUpdateUnreadMessagesCount,
      convosMapForRefetching,
    },
  );

  // the messages history hasn't been inited for these channels yet, so we only need to update the unread messages
  if (channelsToUpdateUnreadMessagesCount.length > 0)
    redux.dispatch(updateUnreadMessages(channelsToUpdateUnreadMessagesCount));

  // the messages have already been uploaded for these channels so we need to getHistory for these channels, compare it against existing messages and update the messagesMap if there're any updates
  const channelIds = Object.keys(convosMapForRefetching);
  // it means that this time we only had channels for which we needed to update the unread messages only
  if (!channelIds.length) return;
  try {
    const history = await context.services.pubnub.getHistory({ channelIds });

    const {
      channelsToUpdateStatuses,
      channelsToInsertNewMessages,
    }: ChannelsToUpdateState = channelIds.reduce<ChannelsToUpdateState>(
      (accumulator, channelId) => {
        const hasPubnubReturnedHistory = channelId in history.channels;
        if (!hasPubnubReturnedHistory)
          throw new Error(
            `${noHistoryForRequestedChannelErrorMessage} ChannelId ${channelId}`,
          );

        /* We use the Set here since its "has" method is more performant than the .includes method of the Array */
        const existingMessagesSet: Set<string> = new Set();
        const currentMessages =
          channelId in messagesMap ? messagesMap[channelId] : [];
        const {
          hasLastMemberReadChanged,
          lastMemberReadTimeToken,
        } = convosMapForRefetching[channelId];
        currentMessages.forEach((message) =>
          existingMessagesSet.add(message.id),
        );
        const formattedAndFilteredMessagesFromRefetch = formatAndFilterHistory(
          history.channels[channelId],
          channelId,
          logger,
        );
        const newMessagesFromRefetch = formattedAndFilteredMessagesFromRefetch.filter(
          (message) => !existingMessagesSet.has(message.id),
        );
        const numberOfNewMessages = newMessagesFromRefetch.length;
        // if no new messages and !hasLasMemberReadChanged - no need to do anything
        if (!numberOfNewMessages && !hasLastMemberReadChanged) {
          logger.info(
            `Refetched history but got 0 displayable new messages and the lastMemberTimetoken hasn't changed, no need to update the messages state. ChannelId: ${channelId}`,
          );
          return accumulator;
        }
        if (!numberOfNewMessages && hasLastMemberReadChanged) {
          // if only hasLastMemberRead has changed - only update statuses
          accumulator.channelsToUpdateStatuses.push({
            channel: channelId,
            lastMemberReadTimeToken,
          });
          return accumulator;
        }
        /* if we're in this block it means that either there're new messages only
          or there're new messages AND the last member read time token has changed.
          In both cases we need to insert new messages AND update statuses */
        accumulator.channelsToInsertNewMessages.push({
          channel: channelId,
          lastMemberReadTimeToken,
          newMessagesFromRefetch,
        });
        return accumulator;
      },
      {
        channelsToInsertNewMessages: [],
        channelsToUpdateStatuses: [],
      } as ChannelsToUpdateState,
    );
    if (channelsToUpdateStatuses.length > 0) {
      logger.info(
        'onRefetchHistory: Updating statuses of messages for channels',
        { channelsToUpdateStatuses },
      );
      redux.dispatch(updateStatusesOfMessages(channelsToUpdateStatuses));
    }
    if (channelsToInsertNewMessages.length > 0) {
      // at this point we now that the messages have been loaded on the client on a moment of re-fetching AND we've got a new message at least for one of the channels
      const shouldPlaySound = channelsToInsertNewMessages.find(
        (channelWithNewMessages) =>
          channelWithNewMessages.newMessagesFromRefetch.find(
            (message: MessageToDisplay) =>
              message.type === MessageType.TEXT_FROM_MEMBER,
          ),
      );
      // play a notification only if new messages from the re-fetch contain at least one message from a member
      if (shouldPlaySound)
        redux.dispatch(
          setShouldPlayNotificationSound({ shouldPlaySound: true }),
        );
      logger.info(
        'onRefetchHistory: Inserting new messages and updating statuses',
        {
          affectedChannels: channelsToInsertNewMessages.map((c) => ({
            channel: c.channel,
            lastMemberReadTimeToken: pubnubTimeTokenToMoment(
              c.lastMemberReadTimeToken,
            ).toISOString(),
            messagePublishTimestamps: c.newMessagesFromRefetch.map((m) => ({
              [m.id]: pubnubTimeTokenToMoment(m.timetoken).toISOString(),
            })),
          })),
        },
      );
      redux.dispatch(
        insertNewMessagesAndUpdateStatuses(channelsToInsertNewMessages),
      );
    }
  } catch (e) {
    logger.error(new Error(refetchHistoryErrorMessage), {
      error: e,
      status: e.status,
    });
  }
};
