import { DEFAULT_TIMEZONE } from 'app/clinician/ClinicianSettingsComponent';
import { useAppState } from 'app/state';
import { viewIntakeTabAction } from 'app/state/amplitude/actions/etc';
import { GraphQLProps } from 'app/typeUtils';
import { useMemberChartIndicators } from 'hooks/useMemberChartIndicators';
import moment from 'moment-timezone';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DeprecatedCard } from 'shared-components/DeprecatedCard';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';

import { IntakeTabFragment as IntakeTabData } from './generated/IntakeTabFragment';
import { Relationships } from './Relationships';
import { RiskIndicators } from './RiskIndicators';
import { SelfReportedSymptoms } from './SelfReportedSymptoms';

export function IntakeTab(
  props: GraphQLProps<IntakeTabData> & { memberId: string },
) {
  const {
    clinicalServicesSeeking,
    reasonsForSeekingClinicalServices,
    mentalIllnessIndicators,
    additionalInformationForClinician,
    preExistingMedicalConditions,
    currentMedications,
    relationships,
    riskKeywordsExtractedFromIntake,
    substanceConsumption,
    frequencyOfSubstanceConsumption,
    thoughtsOfHarm,
    reasonsForThoughtsOfHarm,
    religion,
  } = props.chart;

  const { phq9s } =
    props.surveyResponses !== null ? props.surveyResponses : { phq9s: [] };
  const { intakeCompletedAt, memberId } = props;

  const referralNoteExists = props.referralNote != null;
  const { note, source, serviceType } =
    props.referralNote !== null
      ? props.referralNote
      : { note: '', serviceType: null, source: '' };

  const {
    updateIntakeIndicator,
    showIntakeIndicator,
  } = useMemberChartIndicators(memberId);

  useEffect(() => {
    if (showIntakeIndicator) {
      void updateIntakeIndicator?.();
    }
  }, [showIntakeIndicator]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewIntakeTabAction());
  }, [dispatch]);
  const timezone = useAppState((_) => _.user.timezone) ?? DEFAULT_TIMEZONE;
  const formattedTime = intakeCompletedAt
    ? moment(intakeCompletedAt).tz(timezone).format('MM/DD/YYYY')
    : null;
  const careTerms = {
    'Additional information for Clinician': additionalInformationForClinician,
    'Intake Form Submitted': formattedTime,
    'Reasons for Seeking Care': reasonsForSeekingClinicalServices,
    'Seeking Services': clinicalServicesSeeking,
  };

  return (
    <Grid>
      <Columns widths={[COLUMN_WIDTH.HALF, COLUMN_WIDTH.HALF]}>
        <DeprecatedCard dataTestId="careCard" title="Care" terms={careTerms} />

        <DeprecatedCard
          dataTestId="medHistoryCard"
          title="Medical History"
          terms={{
            'Current Medications': currentMedications,
            'Frequency of substance use': frequencyOfSubstanceConsumption,
            'Preexisting Medical Conditions': preExistingMedicalConditions,
            'Substance use': substanceConsumption
              ? substanceConsumption.join(', ')
              : 'N/A',
          }}
        />
      </Columns>

      <Columns widths={[COLUMN_WIDTH.HALF, COLUMN_WIDTH.HALF]}>
        {mentalIllnessIndicators && (
          <SelfReportedSymptoms {...mentalIllnessIndicators} />
        )}
        <Grid>
          <Columns widths={[COLUMN_WIDTH.FULL]}>
            <RiskIndicators
              thoughtsOfHarm={thoughtsOfHarm}
              reasonsForThoughtsOfHarm={reasonsForThoughtsOfHarm}
              phq9SelfHarmAnswers={phq9s}
              riskKeywords={riskKeywordsExtractedFromIntake}
            />
          </Columns>
        </Grid>
      </Columns>

      <Columns widths={[COLUMN_WIDTH.HALF, COLUMN_WIDTH.HALF]}>
        <Relationships
          family={{
            additionalDetails: relationships.familyDetails,
            score: relationships.familyScore,
          }}
          spouse={{
            additionalDetails: relationships.spouseDetails,
            hasRelationship: relationships.hasSpouse,
            score: relationships.spouseScore,
          }}
          children={{
            additionalDetails: relationships.childrenDetails,
            hasRelationship: relationships.hasChildren,
            score: relationships.childrenScore,
          }}
        />

        <DeprecatedCard
          dataTestId="religionCard"
          title="Religion"
          terms={{
            'Additional Info:': religion.description,
            'Is a member of religious or spiritual group': religion.isMemberOfReligiousGroup.toString(),
          }}
        />
      </Columns>

      {referralNoteExists && (
        <Columns>
          <DeprecatedCard
            dataTestId="referralInfoCard"
            title="Referral Information"
            terms={{
              'Referral Note': note,
              'Service Type': serviceType,
              Source: source,
            }}
          />
        </Columns>
      )}
    </Grid>
  );
}
