import {
  GetMemberById_getMember,
  GetMemberById_getMember_coverageDetails,
} from '@headspace/carehub-graphql/dist/queries/generated/GetMemberById';
import { useAppState } from 'app/state';
import { SchedulerService } from 'app/state/features/scheduler/SchedulerService';
import { D2cSessionInfo } from 'app/state/features/scheduler/types';
import { CoverageType } from 'generated/globalTypes';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TooltipWithRedux } from 'shared-components/tooltip/TooltipWithRedux';
import { classNameCombiner } from 'utils';

import headerStyles from './header.module.scss';
import styles from './MemberHeaderBar.module.scss';
import { SpecialtyTagsContainer } from './SpecialtyTagsContainer';
import {
  formatCoverageRenewalInfo,
  formatCustomerType,
  formatGuardianRelationship,
  isD2cCoaching,
  moreInfoText,
} from './utils';

interface TagsAndDemographicsProps {
  memberId: string;
  getMember: GetMemberById_getMember;
}

interface DemographicsProps {
  coverageDetails: GetMemberById_getMember_coverageDetails | null;
  bottomLineText: string;
  isLongBottomLineCase: boolean;
  d2cSessionInfo?: D2cSessionInfo;
}

// this is a safe length of a string that does not lead to the overlap
const maxBottomLineLength = 49;

function Demographics({
  bottomLineText,
  coverageDetails,
  d2cSessionInfo,
  isLongBottomLineCase,
}: DemographicsProps) {
  const { transientFeatureFlags } = useFeatureFlags();
  const {
    enable_d2c_coaching_coverage_info,
    enable_tooltip_label_underline,
  } = transientFeatureFlags;
  const renewalTooltip = formatCoverageRenewalInfo(
    coverageDetails,
    d2cSessionInfo,
    enable_d2c_coaching_coverage_info,
  );

  if (isLongBottomLineCase) {
    return (
      <TooltipWithRedux
        labelForAnalytics="Member Header: Coverage Info And Demographics"
        title={[
          <span
            className={classNameCombiner([
              styles.tooltipText,
              enable_tooltip_label_underline ? headerStyles.underline : '',
            ])}
            key="span"
          >
            {bottomLineText}
          </span>,
          <br key="br" />,
          renewalTooltip,
        ]}
      >
        <div className={styles.moreInfo}>{moreInfoText}</div>
      </TooltipWithRedux>
    );
  }
  return (
    <TooltipWithRedux
      labelForAnalytics="Member Header: Coverage Info"
      title={renewalTooltip}
    >
      <span
        className={classNameCombiner([
          styles.demographicsItem,
          styles.bottomLine,
          enable_tooltip_label_underline ? headerStyles.underline : '',
        ])}
        data-testid="coverage-info"
      >
        {bottomLineText}
      </span>
    </TooltipWithRedux>
  );
}

export function TagsAndDemographics({
  memberId,
  getMember,
}: TagsAndDemographicsProps) {
  const { isD2c, isFusion } = getMember;
  const { transientFeatureFlags } = useFeatureFlags();
  const { enable_d2c_coaching_coverage_info } = transientFeatureFlags;

  const payerType = formatCustomerType(
    getMember,
    enable_d2c_coaching_coverage_info,
  );
  const guardianRelationship = formatGuardianRelationship(
    getMember.guardianRelationship?.guardianRelationshipType,
  );

  const bottomLineText = [payerType, guardianRelationship]
    .filter(Boolean)
    .join(' • ');

  const { d2cSessionInfo } = useAppState(({ scheduler }) => ({
    d2cSessionInfo: scheduler.d2cSessionInfo?.[memberId],
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (isD2cCoaching(getMember) && enable_d2c_coaching_coverage_info) {
      dispatch(SchedulerService.getD2cSessionInfo({ memberId }));
    }
  }, [
    isD2c,
    isFusion,
    memberId,
    dispatch,
    getMember,
    enable_d2c_coaching_coverage_info,
  ]);

  const isLongBottomLineCase = bottomLineText.length >= maxBottomLineLength;
  const isHeadspaceEAP =
    getMember.coverageDetails?.coverageType === CoverageType.EAP &&
    getMember.coverageDetails?.eapCarrierName === 'CCA';

  return (
    <div
      className={
        isLongBottomLineCase
          ? classNameCombiner([styles.bottomLineContainer, styles.row])
          : styles.bottomLineContainer
      }
    >
      <SpecialtyTagsContainer
        preferredLanguage={getMember.preferredLanguage}
        guardianRelationshipType={
          getMember.guardianRelationship?.guardianRelationshipType
        }
        isFusion={Boolean(getMember.isFusion)}
        isD2c={Boolean(getMember.isD2c)}
        isVideoCoachingEligible={Boolean(getMember.isVideoCoachingEligible)}
        isHeadspaceEAP={isHeadspaceEAP}
        memberId={memberId}
      />
      <Demographics
        bottomLineText={bottomLineText}
        coverageDetails={getMember.coverageDetails}
        d2cSessionInfo={d2cSessionInfo}
        isLongBottomLineCase={isLongBottomLineCase}
      />
    </div>
  );
}
