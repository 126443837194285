import { State } from 'app/state/schema';
import { useSelector } from 'react-redux';

export const selectUserRole = () =>
  useSelector((state: State) => state.user.role);

export const selectUserIdAndRole = ({ user }: State) => ({
  role: user.role,
  userId: user.userId,
});
