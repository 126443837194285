import React from 'react';
import gql from 'graphql-tag';
import { Card } from 'shared-components/Card';
import {
  CardField,
  CardFieldAndGridCell,
} from 'app/member-chart-cards/card-field/CardField';
import { GridCell } from 'shared-components/grid-layout/GridCell';
import Titles from 'app/member-chart-cards/constants/cards-titles';
import {
  NumberOfCardColumns,
  CardColumnGaps,
  CardGrid,
  CardRowGaps,
} from 'shared-components/grid-layout/CardGrid.module';
import { ContactInfoCardFragment as ContactInfoCardData } from './generated/ContactInfoCardFragment';
import { ContactInfoCardFields } from './MemberContactInfoTypes';
import { formatName, formatPhoneNumber } from 'utils';
import styles from 'shared-components/Card/Card.module.scss';
import { GraphQLProps } from 'app/typeUtils';

export const ContactInfoCardFragment = gql`
  fragment ContactInfoCardFragment on User {
    firstName
    lastName
    email
    phone

    address {
      street
      city
      zip
      state
    }

    emergencyContact {
      name
      phone
      relationship
    }
  }
`;

export const ContactInfoCard = ({
  member,
  'data-testid': testId = 'contactInfoCard',
}: {
  member: GraphQLProps<ContactInfoCardData> | null;
  'data-testid'?: string;
}): React.ReactElement => {
  const { firstName, lastName, email, phone, address, emergencyContact } =
    member || {};
  const { street, city, state, zip } = address || {};
  const { name, relationship, phone: emergencyPhone } = emergencyContact || {};
  const contactInfoCardFields: CardFieldAndGridCell[] = [
    {
      label: ContactInfoCardFields.NAME,
      labelForAnalytics: `Contact Info: ${ContactInfoCardFields.NAME}`,
      value: formatName(firstName, lastName),
    },
    {
      label: ContactInfoCardFields.EMAIL,
      labelForAnalytics: `Contact Info: ${ContactInfoCardFields.EMAIL}`,
      value: email,
    },
    {
      label: ContactInfoCardFields.ADDRESS,
      labelForAnalytics: `Contact Info: ${ContactInfoCardFields.ADDRESS}`,
      value: address
        ? `${street}\n${city}${state ? `, ${state},` : ''} ${zip}`
        : null,
    },
    {
      label: ContactInfoCardFields.PHONE,
      labelForAnalytics: `Contact Info: ${ContactInfoCardFields.PHONE}`,
      value: formatPhoneNumber(phone),
    },
    {
      label: ContactInfoCardFields.EMERGENCY_CONTACT,
      labelForAnalytics: `Contact Info: ${ContactInfoCardFields.EMERGENCY_CONTACT}`,
      value: emergencyContact
        ? `${name}${
            relationship ? `\n${relationship}` : ''
          }\n${formatPhoneNumber(emergencyPhone)}`
        : null,
    },
  ];

  return (
    <Card data-testid={testId} boxTitle={Titles.CONTACT_INFO_TITLE}>
      <div className={styles.cardSection}>
        <CardGrid
          rowGap={CardRowGaps.STANDARD}
          columnGap={CardColumnGaps.STANDARD}
          numberOfColumns={NumberOfCardColumns.ONE}
        >
          {contactInfoCardFields.map((field) => {
            const isAnonymous =
              field.label === ContactInfoCardFields.NAME &&
              field.value === null;

            return (
              <GridCell key={field.label}>
                <CardField field={field} hideTooltip={isAnonymous} />
              </GridCell>
            );
          })}
        </CardGrid>
      </div>
    </Card>
  );
};
