import {
  getVaultListenerUserId,
  getVaultWebUserId,
} from '@ginger.io/vault-core/dist/IdHelpers';
import memberChartStorage from 'app/state/member-tabs/memberChartStorage';
import userStorage from 'app/state/user/userStorage';
import tokenStorage from 'app/vault/VaultTokenStorage';
import { UserRole } from 'generated/globalTypes';
import { createReducer } from 'redux-reloaded';

import {
  completeVaultAuth,
  initiateVaultAuth,
  login,
  logout,
  setVaultKeys,
  updateShiftStatus,
  updateTimezone,
} from './actions';
import { getInitialState, State } from './schema';

export const reducer = createReducer<State>(getInitialState());

reducer.on(login, (state, { payload }) => {
  const vaultJWTToken = tokenStorage.getIfNotExpired() || null;
  const {
    appUser: {
      userId,
      role,
      timezone,
      listenerUserId,
      clinicianId,
      pubnubAuthKey,
      listenerId,
      coachinghubUsername,
      pubnubConfig: { rpcToCoachChannelId },
      shiftStatus,
    },
    useVaultUserHeader,
    oktaUser,
  } = payload;

  const useVaultListenerUserId =
    (role === UserRole.COACH || role === UserRole.COACH_SUPERVISOR) &&
    listenerUserId !== null;
  const vaultUserId = useVaultListenerUserId
    ? getVaultListenerUserId(listenerUserId)
    : getVaultWebUserId(userId);

  useVaultUserHeader
    ? userStorage.set({ useOktaAuth: 'true' })
    : userStorage.clear();

  return {
    ...state,
    clinicianId,
    coachinghubUsername,
    firstName: oktaUser.given_name ?? null,
    lastName: oktaUser.family_name ?? null,
    listenerId,
    listenerUserId,
    loggedIntoVault: vaultJWTToken !== null,
    pubnubAuthKey,
    role,
    rpcToCoachChannelId,
    shiftStatus,
    timezone,
    userId,
    vaultJWTToken,
    vaultUserId,
  };
});

reducer.on(logout, (state, action) => {
  tokenStorage.clear();
  memberChartStorage.clear();
  userStorage.clear();

  return {
    ...state,
    coachinghubUsername: null,
    listenerId: null,
    pubnubAuthKey: null,
    role: null,
    showAuthorizationModal: false,
    timezone: null,
    userId: null,
    vaultAuthChallenge: null,
    vaultJWTToken: null,
    vaultMasterKeys: null,
    vaultUserId: null,
  };
});

reducer.on(initiateVaultAuth, (state, { payload }) => ({
  ...state,
  vaultAuthChallenge: payload.authChallenge,
}));

reducer.on(completeVaultAuth, (state, { payload }) => {
  tokenStorage.set({ token: payload.jwtToken });
  return {
    ...state,
    loggedIntoVault: true,
    vaultAuthChallenge: null,
    vaultJWTToken: payload.jwtToken,
  };
});

reducer.on(setVaultKeys, (state, { payload }) => ({
  ...state,
  vaultMasterKeys: payload,
}));

reducer.on(updateShiftStatus, (state, { payload }) => ({
  ...state,
  shiftStatus: payload,
}));

reducer.on(updateTimezone, (state, { payload }) => ({
  ...state,
  timezone: payload.timezone,
}));
