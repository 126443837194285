import gql from 'graphql-tag';

export const getClinicalAppointmentsSummariesQuery = gql`
  query GetClinicalAppointmentsSummaries(
    $input: GetClinicianByIdAndTimeRange!
  ) {
    getClinicalAppointmentsSummaries(input: $input) {
      summaries {
        date
        offered
        scheduled
        completed
        canceled
      }
    }
  }
`;

export const getClinicalAppointmentsTargetsQuery = gql`
  query GetClinicalAppointmentsTargets($input: GetClinicianByIdAndTimeRange!) {
    getClinicalAppointmentsTargets(input: $input) {
      offered
      scheduled
      completed
    }
  }
`;

export const getClinicalAppointmentsBulkDataQuery = gql`
  query GetClinicalAppointmentsBulkData($input: GetClinicianByIdAndTimeRange!) {
    getClinicalAppointmentsBulkData(input: $input) {
      averageScore
      comments {
        comment
        sentAt
      }
    }
  }
`;

export const getClinicianInfoQuery = gql`
  query GetClinicianInfo($input: GetClinicianInput!) {
    getClinician(input: $input) {
      fullName
      id
      avatar
      licensedStates
      firstAppointmentDate
      fullTimeCommitment
    }
  }
`;
