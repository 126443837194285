import { CardFieldAndGridCell } from 'app/member-chart-cards/card-field/CardField';
import { CardSection } from 'app/member-chart-cards/card-section/CardSection';
import Titles from 'app/member-chart-cards/constants/cards-titles';
import Placeholders from 'app/member-chart-cards/constants/member-chart-card.json';
import { GraphQLProps } from 'app/typeUtils';
import { CoverageType } from 'generated/globalTypes';
import gql from 'graphql-tag';
import moment from 'moment';
import React from 'react';
import { Accordion } from 'shared-components/Accordion';
import { Card } from 'shared-components/Card';

import styles from './BenefitsCard.module.scss';
import { InsuranceFields } from './BenefitsCardTypes';
import { BenefitsCardFragment as BenefitsCardData } from './generated/BenefitsCardFragment';
import {
  formatClinicalCoverage,
  formatClinicalUsage,
  formatCoverageType,
  formatOrgDetails,
  formatServicesCovered,
} from './utils';

export const BenefitsCardFragment = gql`
  fragment BenefitsCardFragment on User {
    insurance {
      company
      idNumber
      carrierId
    }
    coverageDetails {
      coverageType
      clinicalCoverageExtent
      servicesCovered
      therapy {
        sessionsAggregateUsed
      }
      psychiatry {
        sessionsAggregateUsed
      }
      totalSessionsCovered
      totalCoveredSessionsRemaining
      renewalDate
      planDescription
      orgBenefits
      eapCarrierName
    }
  }
`;

export const BenefitsCard = ({
  member,
  'data-testid': testId = 'benefitsCard',
}: {
  member: GraphQLProps<BenefitsCardData> | null;
  'data-testid'?: string;
}): React.ReactElement => {
  const { coverageDetails, insurance } = member || {};

  let coverageType = formatCoverageType(
    coverageDetails?.coverageType,
    coverageDetails?.clinicalCoverageExtent,
  );
  if (
    coverageType === CoverageType.EAP &&
    coverageDetails?.eapCarrierName === 'CCA'
  ) {
    coverageType = `Ginger ${coverageType}`;
  }

  const insuranceFields: CardFieldAndGridCell[] = [
    {
      'data-testid': 'coverage-type',
      label: InsuranceFields.TYPE,
      labelForAnalytics: `Benefits Card: ${InsuranceFields.TYPE}`,
      value: coverageType,
    },
    {
      label: InsuranceFields.SERVICES_COVERED,
      labelForAnalytics: `Benefits Card: ${InsuranceFields.SERVICES_COVERED}`,
      value: coverageDetails?.servicesCovered
        ? formatServicesCovered(coverageDetails.servicesCovered)
        : null,
    },
    {
      'data-testid': 'clinical-coverage',
      label: InsuranceFields.CLINICAL_COVERAGE,
      labelForAnalytics: `Benefits Card: ${InsuranceFields.CLINICAL_COVERAGE}`,
      value: formatClinicalCoverage(
        coverageDetails?.servicesCovered,
        coverageDetails?.totalSessionsCovered,
        coverageDetails?.totalCoveredSessionsRemaining,
      ),
    },
    {
      'data-testid': 'clinical-usage',
      label: InsuranceFields.CLINICAL_USAGE,
      labelForAnalytics: `Benefits Card: ${InsuranceFields.CLINICAL_USAGE}`,
      value: formatClinicalUsage(
        coverageDetails?.therapy.sessionsAggregateUsed,
        coverageDetails?.psychiatry.sessionsAggregateUsed,
      ),
    },
    {
      label: InsuranceFields.COVERAGE_RESET_DATE,
      labelForAnalytics: `Benefits Card: ${InsuranceFields.COVERAGE_RESET_DATE}`,
      value: coverageDetails?.renewalDate
        ? moment(coverageDetails.renewalDate).format('LL')
        : null,
    },
    {
      label: InsuranceFields.PLAN_DESCRIPTION,
      labelForAnalytics: `Benefits Card: ${InsuranceFields.PLAN_DESCRIPTION}`,
      value: coverageDetails ? coverageDetails.planDescription : null,
    },
  ];

  const planFields: CardFieldAndGridCell[] = [
    {
      label: InsuranceFields.INSURANCE_COMPANY,
      labelForAnalytics: `Benefits Card: ${InsuranceFields.INSURANCE_COMPANY}`,
      value: insurance ? insurance.company : null,
    },
    {
      label: InsuranceFields.INSURANCE_ID,
      labelForAnalytics: `Benefits Card: ${InsuranceFields.INSURANCE_ID}`,
      value: insurance ? insurance.idNumber : null,
    },
  ];

  const orgDetailsFields: CardFieldAndGridCell[] = [
    {
      label: InsuranceFields.ORG_DETAILS,
      labelForAnalytics: `Benefits Card: ${InsuranceFields.ORG_DETAILS}`,
      markdown: !!coverageDetails?.orgBenefits,
      value: coverageDetails?.orgBenefits
        ? formatOrgDetails(coverageDetails.orgBenefits)
        : null,
    },
  ];

  return (
    <Card data-testid={testId} boxTitle={Titles.BENEFITS_TITLE}>
      <Accordion
        subsectionIndex={0}
        accordionTitle={Titles.GINGER_PLAN_SUBTITLE}
      >
        <CardSection
          className={styles.cardSection}
          fields={insuranceFields}
          placeholder={Placeholders.VALUE_NOT_PROVIDED_PLACEHOLDER}
          hideTooltip={true}
        />
      </Accordion>
      <Accordion
        subsectionIndex={1}
        accordionTitle={Titles.BENEFITS_INSURANCE_SUBTITLE}
      >
        <CardSection
          className={styles.cardSection}
          fields={planFields}
          placeholder={Placeholders.VALUE_NOT_PROVIDED_PLACEHOLDER}
          hideTooltip={true}
        />
      </Accordion>
      <Accordion
        subsectionIndex={2}
        accordionTitle={Titles.ORG_DETAILS_SUBTITLE}
      >
        <CardSection
          className={styles.cardSectionNoLabel}
          fields={orgDetailsFields}
          placeholder={Placeholders.VALUE_NOT_PROVIDED_PLACEHOLDER}
          hideTooltip={true}
        />
      </Accordion>
    </Card>
  );
};
