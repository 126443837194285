import { QuickLinks } from 'app/inbox/vertical-menu/QuickLinks';
import styles from 'app/inbox/vertical-menu/styles/index.module.scss';
import { toggleInbox as toggleInboxV2 } from 'app/inboxV2/state/inboxSlice';
import { useAppState } from 'app/state';
import { toggleDrawer } from 'app/state/inbox/actions';
import { State } from 'app/state/schema';
import { TopLevelRoutes } from 'app/top-nav/Routes';
import { classNameCombiner, isClinicianOrSupervisor } from 'utils';
import MenuV2 from 'assets/icons/menu-v2.svg';
import InboxNotificationIcon from 'assets/inbox/alert.svg';
import { UserRole } from 'generated/globalTypes';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import Tooltip from 'shared-components/tooltip/Tooltip';

import { StatusMenu } from './StatusMenu';

const routeToLabel: { [k in TopLevelRoutes]?: string } = {
  [TopLevelRoutes.PATIENTS]: 'Member charts',
  [TopLevelRoutes.COACHING]: 'Member charts',
  [TopLevelRoutes.SCHEDULE]: 'Schedule',
  [TopLevelRoutes.APPOINTMENTS]: 'Appointments',
  [TopLevelRoutes.ERX_NOTIFICATIONS]: 'Prescriptions',
};

const LinkWithTooltip = forwardRef<HTMLButtonElement>((props: any, ref) => {
  const {
    href,
    navigate,
    onClick: propOnClick,
    ...restProps
  } = (props as unknown) as any;
  const onClick = propOnClick ?? (() => navigate(href));
  return (
    <Tooltip
      classes={{ popper: styles.popper, tooltip: styles.tooltip }}
      title={routeToLabel[href as TopLevelRoutes] ?? 'Unknown'}
    >
      <button
        onClick={onClick}
        ref={ref}
        className={classNameCombiner([styles.menuItemBtn])}
      >
        <div {...restProps} />
      </button>
    </Tooltip>
  );
});

function VerticalMenu() {
  const role = useAppState(({ user }) => user.role!);
  const showBlueDot = useAppState(selectHasNotification);

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    transientFeatureFlags: { carehub_enable_inbox_v2: enableInboxV2 },
  } = useFeatureFlags();

  return (
    <>
      <div className={styles.top}>
        <Tooltip
          classes={{ popper: styles.popper, tooltip: styles.tooltip }}
          title="Tasks & Members"
        >
          <button
            onClick={() => {
              dispatch(toggleDrawer({}));
            }}
            className={classNameCombiner([styles.menuItemBtn])}
          >
            <div
              className={classNameCombiner([styles.menuItem, styles.inboxIcon])}
            >
              {showBlueDot && (
                <img
                  className={styles.inboxNotificationIcon}
                  src={InboxNotificationIcon}
                  alt="Inbox Notification"
                />
              )}
            </div>
          </button>
        </Tooltip>

        {enableInboxV2 ? (
          <Tooltip
            classes={{ popper: styles.popper, tooltip: styles.tooltip }}
            title="Inbox V2"
            data-testid="inboxV2-menu-item"
          >
            <button
              onClick={() => {
                dispatch(toggleInboxV2());
              }}
              className={classNameCombiner([styles.menuItemBtn])}
              type="button"
              aria-label="inbox v2 menu item"
            >
              <div className={classNameCombiner([styles.menuItem])}>
                <img src={MenuV2} className={styles.inboxIconV2} alt="" />
              </div>
            </button>
          </Tooltip>
        ) : null}

        <NavLink
          component={LinkWithTooltip}
          to={
            isClinicianOrSupervisor(role)
              ? TopLevelRoutes.PATIENTS
              : TopLevelRoutes.COACHING
          }
          activeClassName={styles.active}
          className={classNameCombiner([
            styles.menuItem,
            styles.memberChartsIcon,
          ])}
        />
        {isClinicianOrSupervisor(role) && (
          <>
            <NavLink
              component={LinkWithTooltip}
              to={TopLevelRoutes.SCHEDULE}
              activeClassName={styles.active}
              className={classNameCombiner([
                styles.menuItem,
                styles.scheduleIcon,
              ])}
            />
          </>
        )}
        {[UserRole.PSYCHIATRIST, UserRole.PSYCHIATRIST_SUPERVISOR].includes(
          role,
        ) && (
          <NavLink
            component={LinkWithTooltip}
            to={TopLevelRoutes.ERX_NOTIFICATIONS}
            activeClassName={styles.active}
            className={classNameCombiner([
              styles.menuItem,
              styles.prescriptionsIcon,
            ])}
          />
        )}
        <hr />
        <StatusMenu redirectTo={history.push} />
        <QuickLinks role={role} redirectTo={history.push} />
      </div>

      <div className={styles.bottom}>
        <div
          className={classNameCombiner([
            styles.menuItem,
            styles.gingerLogoIcon,
          ])}
        />
      </div>
    </>
  );
}
export default React.memo(VerticalMenu);

const selectHasNotification = ({ inbox }: State) => {
  const { unreadMessagesMap, inboxItems, tabSections, showInboxDrawer } = inbox;
  const hasUnreadMessages =
    Object.values(unreadMessagesMap).flatMap((_) => _).length > 0;
  const hasUnViewedRiskAlerts = Array.from(tabSections.RISK_ALERTS.ids)
    .filter((id) => inboxItems[id])
    .map((id) => inboxItems[id].showNewRiskTaskIndicator)
    .some((_) => _);
  const hasNotification = hasUnreadMessages || hasUnViewedRiskAlerts;

  return hasNotification && !showInboxDrawer;
};
