import {
  CardField,
  CardFieldAndGridCell,
} from 'app/member-chart-cards/card-field/CardField';
import Titles from 'app/member-chart-cards/constants/cards-titles';
import { useAppState } from 'app/state';
import { GraphQLProps } from 'app/typeUtils';
import { formatDeviceDescription } from 'utils';
import { getTimezone } from 'utils/dateTime';
import gql from 'graphql-tag';
import moment from 'moment';
import React from 'react';
import { Card } from 'shared-components/Card';
import styles from 'shared-components/Card/Card.module.scss';
import {
  CardColumnGaps,
  CardGrid,
  CardRowGaps,
  NumberOfCardColumns,
} from 'shared-components/grid-layout/CardGrid.module';
import { GridCell } from 'shared-components/grid-layout/GridCell';

import { AccessCardFields } from './AccessCardTypes';
import { AccessCardFragment as AccessCardData } from './generated/AccessCardFragment';

export const AccessCardFragment = gql`
  fragment AccessCardFragment on User {
    device {
      model
      version
    }
    eligibility {
      couponCode
      activationDateTime
    }
  }
`;

export const AccessCard = ({
  member,
}: {
  member: GraphQLProps<AccessCardData> | null;
}): React.ReactElement => {
  const { device, eligibility } = member || {};
  const { model, version } = device || {};
  const { couponCode, activationDateTime } = eligibility || {};
  const { timezone } = useAppState((_) => _.user);
  const localTimezone = getTimezone(timezone);

  const accessCardFields: CardFieldAndGridCell[] = [
    {
      label: AccessCardFields.PRIMARY_CODE,
      labelForAnalytics: `Access Card: ${AccessCardFields.PRIMARY_CODE}`,
      value: couponCode,
    },
    {
      label: AccessCardFields.DEVICE,
      labelForAnalytics: `Access Card: ${AccessCardFields.DEVICE}`,
      value: model ? formatDeviceDescription(model, version) : null,
    },
    {
      label: AccessCardFields.JOINED,
      labelForAnalytics: `Access Card: ${AccessCardFields.JOINED}`,
      value: activationDateTime
        ? moment(activationDateTime).tz(localTimezone).format('lll')
        : null,
    },
  ];

  return (
    <Card boxTitle={Titles.ACCESS_INFO_TITLE}>
      <div className={styles.cardSection}>
        <CardGrid
          rowGap={CardRowGaps.STANDARD}
          columnGap={CardColumnGaps.STANDARD}
          numberOfColumns={NumberOfCardColumns.ONE}
        >
          {accessCardFields.map((field) => (
            <GridCell key={field.label}>
              <CardField field={field} />
            </GridCell>
          ))}
        </CardGrid>
      </div>
    </Card>
  );
};
