import { Gad7LineChart } from 'app/charts/Gad7LineChart';
import { Phq9LineChart } from 'app/charts/Phq9LineChart';
import { Box } from 'shared-components/Box';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';
import { viewSurveyTabAction } from 'app/state/amplitude/actions/surveys';
import { GraphQLProps } from 'app/typeUtils';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { SurveysTabFragment as SurveysTabData } from './generated/SurveysTabFragment';

export function SurveysTab(props: GraphQLProps<SurveysTabData>) {
  const { surveyResponses } = props;
  const phq9s = surveyResponses !== null ? surveyResponses.phq9s : [];
  const gad7s = surveyResponses !== null ? surveyResponses.gad7s : [];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewSurveyTabAction());
  }, [dispatch]);

  return (
    <Grid>
      <Columns widths={[COLUMN_WIDTH.FULL]}>
        <Box>
          <h2>PHQ-9s</h2>
          <Phq9LineChart responses={phq9s} />
        </Box>
      </Columns>

      <Columns widths={[COLUMN_WIDTH.FULL]}>
        <Box>
          <h2>GAD-7s</h2>
          <Gad7LineChart responses={gad7s} />
        </Box>
      </Columns>
    </Grid>
  );
}
