import {
  DeprecatedMasonryLayout,
  MasonryItem,
  TABS_MASONRY_2_COLUMNS_CONFIG,
} from 'app/masonry-layout/DeprecatedMasonryLayout';
import { BenefitsCard } from 'app/member-chart-cards/benefits-card/BenefitsCard';
import { BenefitsCardFragment } from 'app/member-chart-cards/benefits-card/generated/BenefitsCardFragment';
import { ContactInfoCard } from 'app/member-chart-cards/contact-info/ContactInfoCard';
import { ContactInfoCardFragment } from 'app/member-chart-cards/contact-info/generated/ContactInfoCardFragment';
import { BackgroundCard } from 'app/member-chart-cards/member-background/BackgroundCard';
import { MemberProfileFragment } from 'app/member-chart-cards/member-profile/generated/MemberProfileFragment';
import { MemberProfile } from 'app/member-chart-cards/member-profile/MemberProfile';
import { PrescriptionsCard } from 'app/member-chart-cards/prescriptions/PrescriptionsCard';
import { SchedulerCard } from 'app/member-chart-cards/scheduler/SchedulerCard';
import { TasksCard } from 'app/member-chart-cards/tasks/TasksCard';
import { IntakeTabFragment } from 'app/patients/tabs/intake/generated/IntakeTabFragment';
import { shouldShowPrescriptionsCard } from 'app/patients/tabs/summary/utils';
import { useAppState } from 'app/state';
import { viewSummaryTabAction } from 'app/state/amplitude/actions/etc';
import { GraphQLProps } from 'app/typeUtils';
import { isTeen } from 'utils';
import { UserRole } from 'generated/globalTypes';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import styles from './SummaryTab.module.scss';

export interface SummaryTabData
  extends GraphQLProps<MemberProfileFragment>,
    GraphQLProps<ContactInfoCardFragment>,
    GraphQLProps<BenefitsCardFragment>,
    GraphQLProps<Pick<IntakeTabFragment, 'intakeCompletedAt'>> {
  isSupervisorAndMSUI?: boolean;
  dosespotId?: string | null;
}

export function SummaryTabV2(props: SummaryTabData) {
  const {
    id,
    preferredFirstName,
    preferredLastName,
    preferences,
    timezone,
    gender,
    genderIdentification,
    ethnicities,
    pronouns,
    dateOfBirth,
    firstName,
    lastName,
    email,
    address,
    phone,
    emergencyContact,
    insurance,
    coverageDetails,
    guardianRelationship,
    dosespotId,
    intakeCompletedAt,
    isSupervisorAndMSUI = false,
  } = props;

  const {
    enable_care_hub_member_background_card_clinical_view: enableMemberBackgroundCard,
    enable_member_tasks: enableMemberTasks,
    enable_scheduler_v2: enableSchedulerV2,
  } = useFeatureFlags().transientFeatureFlags;

  const _isTeen = isTeen(dateOfBirth);

  const role = useAppState(({ user }) => user.role);

  const showPrescriptionsCard = shouldShowPrescriptionsCard(role, dosespotId);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewSummaryTabAction());
  }, [dispatch]);

  return (
    <div className={styles.tabContent}>
      <DeprecatedMasonryLayout
        breakPointCols={TABS_MASONRY_2_COLUMNS_CONFIG.breakPointCols}
        className={styles.layout}
      >
        <MasonryItem>
          <ContactInfoCard
            member={{
              address,
              email,
              emergencyContact,
              firstName,
              lastName,
              phone,
            }}
          />
        </MasonryItem>
        {!isSupervisorAndMSUI && enableMemberTasks && (
          <MasonryItem>
            <TasksCard memberId={id} />
          </MasonryItem>
        )}
        {enableSchedulerV2 &&
        role === (UserRole.COACH || UserRole.COACH_SUPERVISOR) ? (
          <MasonryItem>
            <SchedulerCard
              memberId={id}
              memberTimezone={preferences?.timezone}
            />
          </MasonryItem>
        ) : null}
        <MasonryItem>
          <MemberProfile
            member={{
              dateOfBirth,
              ethnicities,
              gender,
              genderIdentification,
              guardianRelationship,
              id,
              preferences,
              preferredFirstName,
              preferredLastName,
              pronouns,
              timezone,
            }}
          />
        </MasonryItem>
        <MasonryItem>
          <BenefitsCard member={{ coverageDetails, insurance }} />
        </MasonryItem>
        {showPrescriptionsCard && (
          <MasonryItem>
            <PrescriptionsCard memberId={id} />
          </MasonryItem>
        )}
        {enableMemberBackgroundCard && (
          <MasonryItem>
            <BackgroundCard
              memberId={id}
              isTeen={_isTeen}
              intakeCompleted={!!intakeCompletedAt}
            />
          </MasonryItem>
        )}
      </DeprecatedMasonryLayout>
    </div>
  );
}
