import { Stage, useEnvironment } from '@ginger.io/core-ui';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import { EnumRadioField, NumberField } from 'app/notes-ui/forms/fields';
import { NoteFormControlLabelStyles } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { RadioListOrientations } from 'app/notes-ui/forms/form-controls/RadioButtonGroup';
import { useAppState } from 'app/state';
import { ReactComponent as ChatBubbleIcon } from 'assets/icons/chat-bubble.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video-icon.svg';
import {
  CancelMemberCoachingSessionInput,
  CreateMemberCoachingSessionInput,
  UpdateMemberCoachingSessionInput,
  UserRole,
} from 'generated/globalTypes';
import moment from 'moment';
import React from 'react';
import { Button } from 'shared-components/button/Button';
import ChipCard from 'shared-components/Card/ChipCard';
import { Dropdown } from 'shared-components/Dropdown';
import { Width } from 'types/StyleTypes';

import Titles from '../constants/cards-titles';
import CreationButtons from './CreationButtons';
import { DateInputField } from './form-fields/DateInputField';
import { useCoachingSessionForm } from './hooks/useCoachingSessionForm';
import { DialogDetails } from './modals/CancelSessionModal';
import { ReminderInfoBox } from './ReminderInfoBox';
import styles from './SchedulerCard.module.scss';
import { CancelAndEditButtons } from './session-form-components/CancelAndEditButtons';
import { DisplayTime } from './session-form-components/DisplayTime';
import { ServiceNowButton } from './session-form-components/ServiceNowButton';
import { ViewCalendarLink } from './session-form-components/ViewCalendarLink';
import {
  Duration,
  ExistingCoachingSession,
  IntakeOrFollowUpSession,
  RECURRENCE_VALUES,
  RecurrenceEnum,
  SessionDisplayOptions,
  SessionFormat,
  SessionType,
} from './types';
import {
  eventTypeToSessionTypeMap,
  getCoachAndMemberDisplayTimes,
  SESSION_DISPLAY_OPTION_TO_SESSION_OPTION,
  SESSION_OPTION_TO_SESSION_DISPLAY_OPTIONS,
} from './utils';

export const SERVICE_NOW_PROD_URL =
  'https://headspace.service-now.com/now/cwf/agent/calendar';

export const SERVICE_NOW_DEV_URL =
  'https://headspacetest.service-now.com/now/cwf/agent/calendar';

const SESSION_DISPLAY_OPTIONS_WITH_ICONS = Object.entries(
  SESSION_DISPLAY_OPTION_TO_SESSION_OPTION,
).map(([sessionOption, sessionOptionProps]) => ({
  name: (
    <div className={styles.optionContainer}>
      {sessionOptionProps.sessionFormat === SessionFormat.CHAT ? (
        <ChatBubbleIcon className={styles.optionIcon} />
      ) : (
        <VideoIcon className={styles.optionIcon} />
      )}
      <span className={styles.optionText}>{sessionOption}</span>
    </div>
  ),
  value: sessionOption as SessionDisplayOptions,
}));

export interface Props {
  memberId: string;
  existingCoachingSession?: ExistingCoachingSession;
  onClose: () => void;
  onCreate: (input: CreateMemberCoachingSessionInput) => void;
  onUpdate: (input: UpdateMemberCoachingSessionInput) => void;
  onCancelSession: (input: CancelMemberCoachingSessionInput) => void;
  onCheckAvailability?: () => void;
  coachTimezone: string;
  memberTimezone: string;
  isD2c?: boolean;
}

const SessionForm = (props: Props) => {
  const {
    memberId,
    existingCoachingSession,
    onClose,
    onCreate,
    onUpdate,
    onCancelSession,
    onCheckAvailability,
    coachTimezone,
    memberTimezone,
    isD2c,
  } = props;
  const {
    fields,
    onClickSave,
    onConfirmEdit,
    onDismissConfirmEdit,
    onClickCancel,
    onClickEdit,
    onConfirmCancel,
    onDismissConfirmCancel,
    showConfirmEdit,
    showConfirmCancel,
  } = useCoachingSessionForm({
    coachTimeZone: coachTimezone,
    existingCoachingSession,
    memberId,
    memberTimeZone: memberTimezone,
    onCancelSession,
    onCreate,
    onUpdate,
  });
  const { role } = useAppState(({ user }) => ({
    role: user.role,
  }));
  const stage = useEnvironment();

  let cancelDialogDetails: DialogDetails;
  let editDialogDetails: DialogDetails;

  if (existingCoachingSession && existingCoachingSession.recurrence === null) {
    cancelDialogDetails = {
      dialogContent: 'Are you sure you want to cancel this session?',
      dialogTitle: 'Cancel session',
    };
    editDialogDetails = {
      dialogContent:
        'Are you sure you want to save your edits for this session?',
      dialogTitle: 'Save session',
    };
  } else {
    cancelDialogDetails = {
      dialogContent: '',
      dialogTitle: 'Cancel recurring session',
    };
    editDialogDetails = {
      dialogContent: '',
      dialogTitle: 'Save recurring session',
    };
  }

  const noteStyles: NoteFormControlLabelStyles = {
    label: styles.noteLabel,
    root: styles.noteRoot,
  };

  let existingSessionType: MaybeUndefined<IntakeOrFollowUpSession>;
  if (existingCoachingSession?.coachingSession.eventType) {
    const sessionType =
      eventTypeToSessionTypeMap[
        existingCoachingSession.coachingSession.eventType
      ];

    if (
      sessionType === SessionType.INTAKE ||
      sessionType === SessionType.FOLLOW_UP
    ) {
      existingSessionType = sessionType;
    }
  }

  const sessionStartTime = existingCoachingSession?.coachingSession.startTime;
  const displayTimes = sessionStartTime
    ? getCoachAndMemberDisplayTimes({
        coachTimezone,
        memberTimezone,
        referenceTime: moment(sessionStartTime),
      })
    : undefined;

  const openServiceNow = () => {
    window.open(
      stage === Stage.PROD ? SERVICE_NOW_PROD_URL : SERVICE_NOW_DEV_URL,
      '_blank',
      'noopener,noreferrer',
    );
  };

  const handleSelectSessionType = (selectedValue: SessionDisplayOptions) => {
    const {
      sessionType,
      sessionFormat,
    } = SESSION_DISPLAY_OPTION_TO_SESSION_OPTION[selectedValue];

    fields.sessionType.setValue(sessionType);
    fields.sessionFormat.setValue(sessionFormat);

    if (sessionFormat === SessionFormat.VIDEO) {
      fields.everyNWeeks.setValue(0);
      fields.numOccurrences.setValue(1);
    }
  };

  const selectedSessionFormat =
    fields.sessionFormat.value ?? SessionFormat.CHAT;
  const selectedSessionType =
    existingSessionType ??
    (fields.sessionType.value as IntakeOrFollowUpSession) ??
    SessionType.FOLLOW_UP;

  const isFollowUpSession = existingSessionType === SessionType.FOLLOW_UP;
  const isIntakeSession = existingSessionType === SessionType.INTAKE;

  const showCheckAvailabilityButton =
    !existingCoachingSession || isFollowUpSession;

  const showCancelButton =
    existingCoachingSession &&
    ((role === UserRole.COACH && isFollowUpSession) ||
      (role === UserRole.CLINICIAN && isIntakeSession));

  const showServiceNowButton =
    existingCoachingSession && role === UserRole.COACH && isIntakeSession;

  return (
    <ChipCard
      boxTitle={Titles.SCHEDULER_TITLE}
      size="fit-container"
      chip={
        <CreationButtons
          existingCoachingSession={existingCoachingSession}
          onClickEdit={onClickEdit}
          onClickSave={onClickSave}
          onClose={onClose}
        />
      }
    >
      <div className={styles.createContainer}>
        <ViewCalendarLink />

        <div className={styles.sessionInfoContainer}>
          <div>
            <FieldLabel className={styles.label} labelText="Session Type" />
            <Dropdown
              options={SESSION_DISPLAY_OPTIONS_WITH_ICONS}
              onSelect={handleSelectSessionType}
              initialValue={
                SESSION_OPTION_TO_SESSION_DISPLAY_OPTIONS[
                  selectedSessionFormat
                ][selectedSessionType]
              }
              menuItemClass={styles.dropdownMenuItem}
              selectClass={styles.dropdownSelectClass}
              dataTestId="sessionTypeDropdown"
              width={Width.FULL}
            />
          </div>

          <div>
            <EnumRadioField
              label="Duration (min)"
              disabled={true}
              options={Duration}
              field={fields.duration}
              formControlStyles={{
                label: styles.schedulingLabel,
                root: styles.root,
              }}
              radioListOrientation={RadioListOrientations.HORIZONTAL}
              keyLabels={{
                FIFTY: '50',
                FORTY: '40',
                SIXTY: '60',
                THIRTY: '30',
                TWENTY: '20',
              }}
              formatSelectedValue={(value) => `${value} minutes`}
              showSelectedOnly={true}
            />
          </div>
        </div>

        {existingCoachingSession && displayTimes && (
          <DisplayTime
            displayTimes={displayTimes}
            sessionStartTime={sessionStartTime}
            coachTimezone={coachTimezone}
            memberTimezone={memberTimezone}
          />
        )}

        <div className={styles.dateContainerV2}>
          <DateInputField
            className={styles.datePicker}
            inputClassName={styles.datePickerInput}
            noteStyles={{ ...noteStyles, label: styles.schedulingLabel }}
            fields={fields.startDate}
            label="Date"
            disabled={
              existingSessionType &&
              existingSessionType !== SessionType.FOLLOW_UP
            }
          />
        </div>

        <EnumRadioField
          label="Repeats"
          disabled={Boolean(existingCoachingSession)}
          options={
            fields.sessionFormat.value === SessionFormat.VIDEO
              ? { DOES_NOT_REPEAT: RecurrenceEnum.DOES_NOT_REPEAT }
              : RecurrenceEnum
          }
          field={fields.everyNWeeks}
          formControlStyles={{
            label: styles.schedulingLabel,
            root: styles.root,
          }}
          keyLabels={
            fields.sessionFormat.value === SessionFormat.VIDEO
              ? {
                  DOES_NOT_REPEAT: 'Does not repeat (single session)',
                }
              : {
                  DOES_NOT_REPEAT: 'Does not repeat (single session)',
                  EVERY_2_WEEKS: 'Every 2 weeks',
                  EVERY_3_WEEKS: 'Every 3 weeks',
                  EVERY_4_WEEKS: 'Every 4 weeks',
                  ONCE_A_WEEK: 'Once a week',
                }
          }
        />
        {fields.sessionFormat.value !== SessionFormat.VIDEO &&
          RECURRENCE_VALUES.includes(fields.everyNWeeks.value) && (
            <div className={styles.sessionCountContainer}>
              <FieldLabel
                className={styles.label}
                labelText="Number of sessions"
              />
              <NumberField
                testId="sessionNumberField"
                inputClass={styles.numberField}
                field={fields.numOccurrences}
                inputProps={{
                  max: 12,
                  min: 1,
                  step: 1,
                  type: 'number',
                }}
                disabled={!!existingCoachingSession}
              />
            </div>
          )}

        {showCheckAvailabilityButton && (
          <Button
            className={styles.checkAvailability}
            size="small"
            onClick={onCheckAvailability}
            testId="checkAvailabilityBtn"
          >
            Search for available times
          </Button>
        )}

        {showCancelButton && (
          <CancelAndEditButtons
            existingCoachingSession={existingCoachingSession}
            onClickCancel={onClickCancel}
            onDismissConfirmEdit={onDismissConfirmEdit}
            onConfirmEdit={onConfirmEdit}
            showConfirmEdit={showConfirmEdit}
            editDialogDetails={editDialogDetails}
            onDismissConfirmCancel={onDismissConfirmCancel}
            onConfirmCancel={(modificationScope) =>
              onConfirmCancel({
                coachingSessionId: existingCoachingSession?.coachingSession.id,
                modificationScope,
              })
            }
            showConfirmCancel={showConfirmCancel}
            cancelDialogDetails={cancelDialogDetails}
          />
        )}

        {showServiceNowButton && <ServiceNowButton onClick={openServiceNow} />}

        <ReminderInfoBox
          className={styles.schedulerInfoBox}
          memberId={memberId}
          showSessionInfo={isD2c}
          showReminderInfo={false}
        />
      </div>
    </ChipCard>
  );
};

export default SessionForm;
