import { useQuery } from 'hooks/useQuery';
import React from 'react';

import FeedbackQuotesComponent from './FeedbackQuotesComponent';
import FeedbackRatingComponent from './FeedbackRatingComponent';
import {
  GetClinicalAppointmentsBulkData,
  GetClinicalAppointmentsBulkDataVariables,
} from './generated/GetClinicalAppointmentsBulkData';
import { getClinicalAppointmentsBulkDataQuery } from './queries';

function ClinicianFeedbackComponent(props: { clinicianId: string }) {
  return useQuery<
    GetClinicalAppointmentsBulkData,
    GetClinicalAppointmentsBulkDataVariables
  >(
    (data) => {
      if (data.getClinicalAppointmentsBulkData) {
        const { averageScore, comments } = data.getClinicalAppointmentsBulkData;
        return (
          <>
            <FeedbackRatingComponent rating={averageScore} />
            {comments != null && comments.length > 0 && (
              <FeedbackQuotesComponent
                quotes={comments}
                clinicianId={props.clinicianId}
              />
            )}
          </>
        );
      }
      return <></>;
    },
    getClinicalAppointmentsBulkDataQuery,
    { variables: { input: { clinicianId: props.clinicianId } } },
  );
}

export default ClinicianFeedbackComponent;
