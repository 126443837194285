import { CareProviderTaskFragment } from 'app/queries/generated/CareProviderTaskFragment';
import { ConversationStatsFragment as ConversationStats } from 'app/queries/generated/ConversationStatsFragment';
import { StateSlice } from 'app/state/status/types/StateSlice';
import { PaginationInput, ScheduledSessionType } from 'generated/globalTypes';

interface RiskTaskDetails {
  tasks: CareProviderTaskFragment[];
  previewText?: string;
  highestPriority?: number;
  // we'll use these fields for sorting
  latestCompletedTaskDatetime?: string;
  latestCreatedTaskDatetime?: string;
}

interface TaskDetails {
  tasks: CareProviderTaskFragment[];
  previewText?: string;
  highestPriority?: number;
  // we'll use these fields for sorting
  latestCompletedTaskDatetime?: string;
  latestCreatedTaskDatetime?: string;
}

// state doesn't have the conversationsStats that before partially duplicated the ConversationMap in the conversation obj
// the data from the convoStats and ConversationsMap has been moved to a smaller objects on the higher level of the inbox slice: timetokensMap, unreadMessagesMap, conversationStateMap, messagesMap
export interface InboxItemState {
  id: string;
  name: string;
  isTyping?: boolean;
  isD2c?: boolean;
  isTeen?: boolean;
  isFrench?: boolean;
  isSpanish?: boolean;
  terminated?: boolean;
  transferred?: boolean;
  summary?: string;
  nextAppointment?: string;
  prevAppointment?: string;
  todayScheduledSessionStartTime?: string;
  todayScheduledSessionEndTime?: string;
  showNewRiskTaskIndicator?: boolean;
  memberCoachChannelId?: string;
  conversationId?: string | null;
  scheduledSessionType?: ScheduledSessionType;
  riskTaskDetails?: RiskTaskDetails;
  taskDetails?: TaskDetails;
}

export interface InboxItem extends InboxItemState {
  conversationStats?: ConversationStats;
}

export type Results = {
  items: InboxItem[];
  cursor: string | null;
  hasMore?: boolean;
};

export type Section = {
  state: StateSlice<Results>;
  loadMore: (paginationInput: PaginationInput) => Promise<void>;
};

export interface AllTab {
  scheduled: Section;
  closed: Section;
  past: Section;
}

export interface CoachTodaysTab {
  scheduleCheckin: Section;
  conversationsAndTasks: Section;
  completed: Section;
  risks: Section;
}

export interface ClinicianTodaysTab {
  appointments: Section;
  riskTasks: Section;
  activeTasks: Section;
  completed: Section;
}

export enum InboxTab {
  TODAYS = 'TODAYS',
  ALL = 'ALL',
}

export enum InboxSections {
  RISKS = 'RISK_ALERTS',
  TASKS = 'TASKS',
  COMPLETED = 'COMPLETED',
  SCHEDULED = 'SCHEDULED',
  CLOSED = 'CLOSED',
  PAST = 'PAST',
  SCHEDULED_CHECKINS = 'SCHEDULED_CHECKINS',
  CONVERSATIONS_AND_TASKS = 'CONVERSATIONS_AND_TASKS',
  CONVERSATIONS = 'CONVERSATIONS',
  APPOINTMENTS = 'APPOINTMENTS',
}

export enum InboxSource {
  COACH_INBOX = 'COACH_INBOX',
  CLINICIAN_INBOX = 'CLINICIAN_INBOX',
}

export interface InboxViewResponse {
  onSearch: (query: string) => Promise<void>;
  allTab: StateSlice<void>;
  todaysTab: StateSlice<void>;
  loadMore: (input: PaginationInput, section: InboxSections) => Promise<void>;
}
