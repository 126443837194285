import { CareTeamRole } from 'generated/globalTypes';
import React, { ReactElement } from 'react';
import Tooltip from 'shared-components/tooltip/Tooltip';
import { daysBetweenCurrentDate, formatDate } from 'utils/dateTime';

import styles from './CareTeamTooltip.module.scss';

export type TooltipsProps = {
  children: ReactElement;
  role: CareTeamRole;
  lastEngagement?: string | null;
  nextAppointment?: string | null;
  lastAppointment?: string | null;
  totalMessagesExchanged?: number;
};

type TooltipData = {
  label: string;
  value: string | number;
};

export default function CareTeamTooltip(props: TooltipsProps) {
  const tooltipData = getTooltipData(props);

  const title = tooltipData.map(({ label, value }, index) => (
    <div className={styles.tooltipContent} key={index}>
      <span>{label}</span>:<strong>{value}</strong>
    </div>
  ));
  return <Tooltip title={title}>{props.children}</Tooltip>;
}

export function getTooltipData(props: Omit<TooltipsProps, 'children'>) {
  const {
    role,
    nextAppointment,
    lastAppointment,
    totalMessagesExchanged,
    lastEngagement,
  } = props;
  const tooltipData: TooltipData[] = [];
  if (role === CareTeamRole.PSYCHIATRIST || role === CareTeamRole.THERAPIST) {
    tooltipData.push({
      label: 'Days since last appointment',
      value: lastAppointment ? daysBetweenCurrentDate(lastAppointment) : 'None',
    });
    tooltipData.push({
      label: 'Days until next appointment',
      value: nextAppointment ? daysBetweenCurrentDate(nextAppointment) : 'None',
    });
  } else {
    const sentBy =
      role === CareTeamRole.MEMBER_SUPPORT ? 'member support' : 'coach';
    tooltipData.push({
      label: `Last message sent by ${sentBy}`,
      value: lastEngagement ? formatDate(lastEngagement) : 'None',
    });
    tooltipData.push({
      label: 'Total messages exchanged',
      value: totalMessagesExchanged || 0,
    });
  }
  return tooltipData;
}
