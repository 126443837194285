import gql from 'graphql-tag';

export const MemberFragment = gql`
  fragment MemberFragment on User {
    id
    isD2c
    preferredFirstName
    preferredLastName
    firstName
    lastName
    dateOfBirth
    preferredLanguage
    guardianRelationship {
      guardianRelationshipType
    }
  }
`;

export const CareProviderTaskFragment = gql`
  fragment CareProviderTaskFragment on CareProviderTask {
    id
    reasonForCreation
    priority
    hasViewed
    relatedCareProviderName
    createdAt
  }
`;

export const ConversationStatsFragment = gql`
  fragment ConversationStatsFragment on ConversationStats {
    id
    memberCoachChannelId
    stateLastUpdatedAt
    state
  }
`;

export const TaskTodayFragment = gql`
  fragment TaskTodayFragment on TaskToday {
    latestTaskDatetime
    latestCompletedTaskDatetime
    member {
      ...MemberFragment
    }
    tasks {
      ...CareProviderTaskFragment
    }
  }
  ${MemberFragment}
  ${CareProviderTaskFragment}
`;

export const PaginationFragment = gql`
  fragment PaginationFragment on Pagination {
    cursor
    hasNextPage
    maxItemsPerPage
    totalCount
  }
`;

export const InboxItemFragment = gql`
  fragment InboxItemFragment on InboxItem {
    prevAppointmentDatetime
    nextAppointmentDatetime
    terminated
    transferred
    conversationStats @include(if: $isCoach) {
      ...ConversationStatsFragment
    }
    member {
      id
      isD2c
      preferredFirstName
      preferredLastName
      firstName
      lastName
      preferredLanguage
      guardianRelationship {
        guardianRelationshipType
      }
      activeCareProviderTasks {
        ...CareProviderTaskFragment
      }
    }
  }
  ${CareProviderTaskFragment}
  ${ConversationStatsFragment}
`;

export const TodaysCoachingConversationFragment = gql`
  fragment TodaysCoachingConversationFragment on TodaysCoachingConversation {
    nextTodayScheduledSession {
      startTime
      endTime
      scheduledSessionType
    }
    conversationStats {
      ...ConversationStatsFragment
    }
    member {
      ...MemberFragment
      activeCareProviderTasks {
        ...CareProviderTaskFragment
      }
    }
  }
  ${ConversationStatsFragment}
`;

export const coachInboxQuery = gql`
  query CoachInbox(
    $input: GetCoachingMemberForMeInput
    $pagination: PaginationInput = { maxItemsPerPage: 20 }
    $isCoach: Boolean = false
    $includeScheduled: Boolean = true
    $includePast: Boolean = true
    $includeClosed: Boolean = true
  ) {
    getCoachingMembersForMe(input: $input) {
      scheduled(pagination: $pagination) @include(if: $includeScheduled) {
        items {
          ...InboxItemFragment
        }
        pagination {
          ...PaginationFragment
        }
      }
      past(pagination: $pagination) @include(if: $includePast) {
        items {
          ...InboxItemFragment
        }
        pagination {
          ...PaginationFragment
        }
      }
      closed(pagination: $pagination) @include(if: $includeClosed) {
        items {
          ...InboxItemFragment
        }
        pagination {
          ...PaginationFragment
        }
      }
    }
  }
  ${PaginationFragment}
  ${InboxItemFragment}
`;

export const coachTodaysInbox = gql`
  query CoachTodaysInbox(
    $timezone: String!
    $pagination: PaginationInput!
    $excludeMemberIds: [String!]
    $includeScheduledCheckin: Boolean = true
    $includeRiskTasks: Boolean = true
    $includeActiveTasks: Boolean = false
    $includeCompletedTasks: Boolean = true
    $includeConvo: Boolean = true
    $activeTasksPagination: PaginationInput!
    $riskTasksPagination: PaginationInput!
    $openConvoPagination: PaginationInput!
    $scheduledCheckinPagination: PaginationInput!
  ) {
    activeTasks: getTodaysMembersWithActiveTasksForMe(
      input: {
        pagination: $activeTasksPagination
        timezone: $timezone
        excludeMemberIds: $excludeMemberIds
      }
    ) @include(if: $includeActiveTasks) {
      items {
        ...TaskTodayFragment
        conversationStats {
          ...ConversationStatsFragment
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
    riskTasks: getTodaysMembersWithRiskAlertForMe(
      input: { pagination: $riskTasksPagination, timezone: $timezone }
    ) @include(if: $includeRiskTasks) {
      items {
        ...TaskTodayFragment
        conversationStats {
          ...ConversationStatsFragment
        }
      }
      pagination {
        ...PaginationFragment
      }
    }
    completedTasks: getTodaysMembersCompletedTasksForMe(
      input: { pagination: $pagination, timezone: $timezone }
    ) @include(if: $includeCompletedTasks) {
      ...TaskTodayFragment
      conversationStats {
        ...ConversationStatsFragment
      }
    }
    openConversations: getMemberWithOpenChatConversationsForMe(
      pagination: $openConvoPagination
    ) @include(if: $includeConvo) {
      items {
        ...TodaysCoachingConversationFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
    # we are not interested in paginating the completed section in the UI so we'll request for a large
    closedConversations: getTodaysClosedChatConversationsForMe(
      pagination: { maxItemsPerPage: 200 }
    ) @include(if: $includeCompletedTasks) {
      items {
        ...TodaysCoachingConversationFragment
      }
    }
    scheduledSessions: getTodaysScheduledSessionsTodayForMe(
      pagination: $scheduledCheckinPagination
    ) @include(if: $includeScheduledCheckin) {
      items {
        ...TodaysCoachingConversationFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
  }
  ${TaskTodayFragment}
  ${TodaysCoachingConversationFragment}
  ${PaginationFragment}
  ${ConversationStatsFragment}
`;

export const clinicianInboxQuery = gql`
  query ClinicianInbox(
    $input: GetClinicalMembersForMeInput
    $pagination: PaginationInput = { maxItemsPerPage: 20 }
    $isCoach: Boolean = false
    $includeScheduled: Boolean = true
    $includePast: Boolean = true
    $includeClosed: Boolean = true
  ) {
    getClinicalMembersForMe(input: $input) {
      scheduled(pagination: $pagination) @include(if: $includeScheduled) {
        items {
          ...InboxItemFragment
        }
        pagination {
          ...PaginationFragment
        }
      }
      past(pagination: $pagination) @include(if: $includePast) {
        items {
          ...InboxItemFragment
        }
        pagination {
          ...PaginationFragment
        }
      }
      closed(pagination: $pagination) @include(if: $includeClosed) {
        items {
          ...InboxItemFragment
        }
        pagination {
          ...PaginationFragment
        }
      }
    }
  }
  ${PaginationFragment}
  ${InboxItemFragment}
`;

export const clinicianTodaysInbox = gql`
  query ClinicianTodaysInbox(
    $pagination: PaginationInput!
    $includeAppointments: Boolean = true
    $includeRiskTasks: Boolean = true
    $includeActiveTasks: Boolean = true
    $includeCompletedTasks: Boolean = true
    $activeTasksPagination: PaginationInput!
    $riskTasksPagination: PaginationInput!
    $appointmentPagination: PaginationInput!
  ) {
    activeTasks: getTodaysMembersWithActiveTasksForMe(
      input: { pagination: $activeTasksPagination }
    ) @include(if: $includeActiveTasks) {
      items {
        ...TaskTodayFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
    riskTasks: getTodaysMembersWithRiskAlertForMe(
      input: { pagination: $riskTasksPagination }
    ) @include(if: $includeRiskTasks) {
      items {
        ...TaskTodayFragment
      }
      pagination {
        ...PaginationFragment
      }
    }
    completedTasks: getTodaysMembersCompletedTasksForMe(
      input: { pagination: $pagination }
    ) @include(if: $includeCompletedTasks) {
      ...TaskTodayFragment
    }
    appointments: getTodaysClinicalAppointmentsForMe
      @include(if: $includeAppointments) {
      appointments(pagination: $appointmentPagination) {
        items {
          appointment {
            id
            start
            type
            member {
              ...MemberFragment
              activeCareProviderTasks {
                ...CareProviderTaskFragment
              }
            }
          }
        }
        pagination {
          ...PaginationFragment
        }
      }
    }
  }
  ${CareProviderTaskFragment}
  ${MemberFragment}
  ${TaskTodayFragment}
  ${PaginationFragment}
`;
