import {
  CancelMemberCoachingSessionInput,
  CreateMemberCoachingSessionInput,
  UpdateMemberCoachingSessionInput,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions as CoachingSession } from '@headspace/carehub-graphql/dist/scheduler/generated/GetMemberUpcomingCoachingSessions';
import {
  CancelMemberCoachingSession,
  CancelMemberCoachingSessionVariables,
} from 'app/scheduler/generated/CancelMemberCoachingSession';
import {
  CreateMemberCoachingSession,
  CreateMemberCoachingSessionVariables,
} from 'app/scheduler/generated/CreateMemberCoachingSession';
import {
  UpdateMemberCoachingSession,
  UpdateMemberCoachingSessionVariables,
} from 'app/scheduler/generated/UpdateMemberCoachingSession';
import {
  cancelMemberCoachingSessionMutation,
  createMemberCoachingSessionMutation,
  updateMemberCoachingSessionMutation,
} from 'app/scheduler/queries';
import { SchedulerService } from 'app/state/features/scheduler/SchedulerService';
import { toggleD2cSessionInfoBanner } from 'app/state/features/scheduler/schedulerSlice';
import { GraphQLError } from 'graphql';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useMutationWithGlobalState } from 'hooks/useMutationWithGlobalState';
import Messages from 'i18n/en/scheduler.json';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export interface CoachingSessionInterface {
  coachingSessions: CoachingSession[];
}

export interface SchedulerInterface {
  createMemberCoachingSession: (
    input: CreateMemberCoachingSessionInput,
  ) => Promise<void>;
  updateMemberCoachingSession: (
    input: UpdateMemberCoachingSessionInput,
  ) => Promise<void>;
  cancelMemberCoachingSession: (
    input: CancelMemberCoachingSessionInput,
  ) => Promise<void>;
}

export default function useScheduler(
  memberId: string,
  isD2c?: boolean,
): SchedulerInterface {
  const dispatch = useDispatch();
  const {
    enable_coaching_scheduler: enableCoachingScheduler,
  } = useFeatureFlags().transientFeatureFlags;

  const [createMemberCoachingSessionMutationFn] = useMutationWithGlobalState<
    CreateMemberCoachingSession,
    CreateMemberCoachingSessionVariables
  >(createMemberCoachingSessionMutation);

  const [updateMemberCoachingSessionMutationFn] = useMutationWithGlobalState<
    UpdateMemberCoachingSession,
    UpdateMemberCoachingSessionVariables
  >(updateMemberCoachingSessionMutation);

  const [cancelMemberCoachingSessionMutationFn] = useMutationWithGlobalState<
    CancelMemberCoachingSession,
    CancelMemberCoachingSessionVariables
  >(cancelMemberCoachingSessionMutation);

  useEffect(() => {
    dispatch(SchedulerService.getMemberUpcomingCoachingSessions({ memberId }));

    if (enableCoachingScheduler) {
      dispatch(toggleD2cSessionInfoBanner({ showD2cSessionInfoBanner: isD2c }));
      if (isD2c) {
        dispatch(SchedulerService.getD2cSessionInfo({ memberId }));
      }
    }
  }, [memberId, isD2c, enableCoachingScheduler, dispatch]);

  const handleMutationResponse = (
    defaultErrorMessage: string,
    errors: readonly GraphQLError[] | undefined,
    data: any,
  ) => {
    let error: string | null = null;
    if (errors) {
      if (errors.length >= 1) {
        error = errors.map((e) => e.message).join(' ');
      } else {
        error = 'An unknown error occurred.';
      }
    } else if (data.createMemberCoachingSession) {
      error = data.createMemberCoachingSession?.error;
    } else if (data.updateMemberCoachingSession) {
      error = data.updateMemberCoachingSession?.error;
    } else if (data.cancelMemberCoachingSession) {
      error = data.cancelMemberCoachingSession?.error;
    }

    if (error) {
      throw new Error(`${defaultErrorMessage} ${error}`);
    }
  };

  const createMemberCoachingSession = async (
    input: CreateMemberCoachingSessionInput,
  ) => {
    const { errors, data } = await createMemberCoachingSessionMutationFn({
      input,
    });
    handleMutationResponse(
      Messages.failureToCreateCoachingSession,
      errors,
      data,
    );
  };

  const updateMemberCoachingSession = async (
    input: UpdateMemberCoachingSessionInput,
  ) => {
    const { errors, data } = await updateMemberCoachingSessionMutationFn({
      input,
    });
    handleMutationResponse(
      Messages.failureToUpdateCoachingSession,
      errors,
      data,
    );
  };

  const cancelMemberCoachingSession = async (
    input: CancelMemberCoachingSessionInput,
  ) => {
    const { errors, data } = await cancelMemberCoachingSessionMutationFn({
      input,
    });
    handleMutationResponse(
      Messages.failureToCancelCoachingSession,
      errors,
      data,
    );
  };

  return {
    cancelMemberCoachingSession,
    createMemberCoachingSession,
    updateMemberCoachingSession,
  };
}
