import { useMutation } from '@apollo/client';
import { taskRoute } from 'app/top-nav/Routes';
import { PatientProfileWrapper } from 'app/patients/PatientProfile';
import styles from 'app/patients/PatientsScreen.module.scss';
import * as PatientRoutes from 'app/patients/Routes';
import { selectPatientAction } from 'app/state/amplitude/actions/etc';
import {
  markTaskAsCompleteAction,
  viewTaskListAction,
  viewTaskScreenAction,
} from 'app/state/amplitude/actions/tasks';
import {
  ReasonForCreation,
  TaskStatus,
  UpdateTaskStatusInput,
} from 'generated/globalTypes';
import gql from 'graphql-tag';
import { useQuery } from 'hooks/useQuery';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';

import {
  getTasks,
  getTasks_getTasks_all as ClinicalTask,
} from './generated/getTasks';
import { TaskList } from './TaskList';

export const query = gql`
  query getTasks {
    getTasks {
      all {
        id
        status
        reasonForCreation
        callToAction
        priority
        clinicalNote {
          id
        }
        appointment {
          id
        }
        survey {
          id
        }
        updatedAt
        member {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const mutation = gql`
  mutation updateTaskStatus($input: UpdateTaskStatusInput!) {
    updateTaskStatus(input: $input) {
      id
      status
      reasonForCreation
      callToAction
      priority
      appointment {
        id
      }
      survey {
        id
      }
      updatedAt
      member {
        id
        firstName
        lastName
      }
    }
  }
`;

export function getRouteFromTask(task: ClinicalTask) {
  switch (task.reasonForCreation) {
    case ReasonForCreation.AT_RISK_PHQ9_Q9:
    case ReasonForCreation.SURVEY_SCORE_IMPROVED:
    case ReasonForCreation.SURVEY_SCORE_WORSENED:
      return PatientRoutes.surveysRoute;
    case ReasonForCreation.STALE_APPOINTMENT_STATUS:
    case ReasonForCreation.CLINICAL_NOTES_FOR_APPOINTMENT_PAST_DUE:
      return PatientRoutes.appointmentsRoute;
    case ReasonForCreation.NEW_ENTITY_CREATED:
      if (task.survey && task.appointment) {
        return PatientRoutes.intakeRoute;
      }
      if (task.survey) {
        return PatientRoutes.surveysRoute;
      }
      if (task.appointment) {
        return PatientRoutes.appointmentsRoute;
      }
      return PatientRoutes.summaryRoute;
    case ReasonForCreation.NEW_COLLABORATION_CHAT_MESSAGE:
    case ReasonForCreation.NEW_COACH_NOTE_UPDATE:
      return PatientRoutes.chatCollaborationRoute;
    case ReasonForCreation.NEW_CLINICAL_DOCUMENT_UPLOAD:
      return PatientRoutes.documentsRoute;
    case ReasonForCreation.NEW_ERX_ALERT:
      // RefillsErrors=1 is a parameter that can be passed to the Dosespot iframe url, redirecting clinician to notification summary page
      return PatientRoutes.prescriptionsRoute;
    default:
      return PatientRoutes.summaryRoute;
  }
}

function TasksDataState(props: {
  taskId: string;
  data: getTasks;
  redirectTo: (path: string) => void;
}) {
  const { taskId, data, redirectTo } = props;
  const [updateStatus] = useMutation(mutation);
  const dispatch = useDispatch();

  useEffect(() => {
    // log amplitude for successfully loading task screen
    dispatch(viewTaskListAction());
  }, [dispatch]);

  const onSelect = (taskId: string) => {
    const task = data!.getTasks.all.find((t) => t.id === taskId)!;
    const pathPrefix = taskRoute(taskId);
    const showRefillsErrors = true;
    const path = getRouteFromTask(task)(
      pathPrefix,
      task.member.id,
      showRefillsErrors,
    );
    redirectTo(path);
    dispatch(
      selectPatientAction({
        location: 'Task tab',
        patientId: task.member.id,
        taskId,
      }),
    );
  };

  const onCheck = (taskId: string) => {
    const task = data!.getTasks.all.find((t) => t.id === taskId)!;
    const input: UpdateTaskStatusInput = {
      status: TaskStatus.COMPLETED,
      taskId,
    };
    void updateStatus({ variables: { input } });
    dispatch(markTaskAsCompleteAction({ patientId: task.member.id, taskId }));
  };

  let profile;
  const task = data.getTasks.all.find((t) => t.id === taskId);
  if (task) {
    const patientId = task.member.id;
    const pathPrefix = taskRoute(taskId);
    profile = (
      <PatientProfileWrapper
        patientId={patientId}
        className={styles.profileWrapper}
        pathPrefix={pathPrefix}
      />
    );
  } else {
    profile = (
      <div className={styles.profileWrapper}>
        <p>Select a task on the left.</p>
      </div>
    );
  }

  return (
    <Grid className={styles.grid} data-testid="tasksScreen">
      <Columns
        className={`${styles.item} ${styles['pl-0']}`}
        widths={[COLUMN_WIDTH.THREE, COLUMN_WIDTH.NINE]}
      >
        <TaskList
          selectedTaskId={taskId}
          tasks={data.getTasks.all}
          onSelect={onSelect}
          onCheck={onCheck}
        />

        {profile}
      </Columns>
    </Grid>
  );
}

function TasksScreen(props: RouteComponentProps<{ taskId: string }>) {
  const { params } = props.match;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewTaskScreenAction());
  }, [dispatch]);

  return useQuery<getTasks>((data) => {
    return (
      <TasksDataState
        taskId={params.taskId}
        data={data}
        redirectTo={props.history.push}
      />
    );
  }, query);
}

export default withRouter(TasksScreen);
