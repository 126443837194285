import gql from 'graphql-tag';

export const SurveysTabFragment = gql`
  fragment SurveysTabFragment on Member {
    surveyResponses {
      phq9s {
        timestamp
        enjoyment
        mood
        sleep
        energy
        eating
        failure
        concentration
        restlessness
        selfHarm
        total
      }

      gad7s {
        timestamp
        anxiety
        control
        worry
        relaxation
        restlessness
        irritation
        fear
        total
      }
    }
  }
`;
