enum Titles {
  MEMBER_PROFILE_TITLE = 'Member Profile',
  CONTACT_INFO_TITLE = 'Contact Info',
  ACCESS_INFO_TITLE = 'Access',
  BENEFITS_TITLE = 'Benefits',
  MEMBER_BACKGROUND_TITLE = 'Member Background',
  BENEFITS_INSURANCE_SUBTITLE = 'Active Insurance',
  GINGER_PLAN_SUBTITLE = 'Ginger Plan',
  ORG_DETAILS_SUBTITLE = 'Org Details',
  PRESCRIPTIONS_TITLE = 'Pharmacy',
  DEFAULT_PHARMACY_SUBTITLE = 'Default Pharmacy',
  ACTIVE_PRESCRIPTIONS_SUBTITLE = 'Active Prescriptions',
  TASKS_TITLE = 'Tasks',
  SCHEDULER_TITLE = 'Scheduler',
  RECOMMENDATIONS_TITLE = 'Recommendations',
  CARE_TEAM_TITLE = 'Care Team',
  TEAM_COMMUNICATION_TITLE = 'Team Communication',
  RELATIONSHIPS_TITLE = 'Relationships',
  SELD_REPORTED_TITLE = 'Self Reported Symptoms',
}

export default Titles;
