import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { formatDateTime } from 'utils/dateTime';
import { ReasonForCreation, TaskStatus } from 'generated/globalTypes';
import React, { Fragment, ReactNode, useMemo, useState } from 'react';
import Checkbox from 'shared-components/checkbox/Checkbox';
import styles from 'styles/LeftRail.module.scss';

import { getTasks_getTasks_all as ClinicalTask } from './generated/getTasks';

type TaskListProps = {
  selectedTaskId: string;
  tasks: ClinicalTask[];
  onSelect: (taskId: string) => void;
  onCheck: (taskId: string) => void;
};

export function TaskList(props: TaskListProps) {
  const { selectedTaskId, onSelect, onCheck } = props;
  // do not display eRx alerts
  const tasks = props.tasks.filter(
    (task) => task.reasonForCreation !== ReasonForCreation.NEW_ERX_ALERT,
  );
  const selectedTask = tasks.find((t) => t.id === selectedTaskId);
  const [showCompleted, setShowCompleted] = useState(
    // If selected task is completed, initially set showCompleted
    // so we can show it highlighted in the list.
    selectedTask && selectedTask.status !== TaskStatus.ACTIVE,
  );
  const activeTasks = useMemo(
    () => tasks.filter((t) => t.status === TaskStatus.ACTIVE),
    [tasks],
  );
  const completedTasks = useMemo(
    () => tasks.filter((t) => t.status !== TaskStatus.ACTIVE),
    [tasks],
  );
  return (
    <div className={styles.patientList}>
      <div style={{ flexGrow: 1 }}>
        <TaskGroup
          tasks={activeTasks}
          onSelect={onSelect}
          onCheck={onCheck}
          selectedTaskId={selectedTaskId}
        />
      </div>
      <CompletedTasksPanel
        data-testid="completed-toggle"
        headerText={`Completed Items (${completedTasks.length})`}
        expanded={showCompleted}
        onChange={() => setShowCompleted(!showCompleted)}
      >
        <TaskGroup
          tasks={completedTasks}
          onSelect={onSelect}
          selectedTaskId={selectedTaskId}
        />
      </CompletedTasksPanel>
    </div>
  );
}

type CompletedTasksPanelProps = {
  headerText: string;
  expanded?: boolean;
  children: ReactNode;
  onChange: () => void;
};

export function CompletedTasksPanel(props: CompletedTasksPanelProps) {
  const { headerText, expanded = false, children, onChange } = props;
  return (
    <Accordion
      classes={{ root: `${styles.expando} ${styles.root}` }}
      expanded={expanded}
      onChange={onChange}
      TransitionProps={{ mountOnEnter: true }}
    >
      <AccordionSummary
        classes={{
          root: `${styles.expando} ${styles.header}`,
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="completed-content"
        id="completed-header"
      >
        {headerText}
      </AccordionSummary>
      <AccordionDetails
        classes={{ root: `${styles.expando} ${styles.content}` }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

function getTaskDescription(task: ClinicalTask): string {
  switch (task.reasonForCreation) {
    case ReasonForCreation.AT_RISK_PHQ9_Q9:
      return 'Endorsed question 9 on PHQ-9';
    case ReasonForCreation.SURVEY_SCORE_IMPROVED:
      return 'New survey score submitted: improved';
    case ReasonForCreation.SURVEY_SCORE_WORSENED:
      return 'New survey score submitted: worsened';
    case ReasonForCreation.STALE_APPOINTMENT_STATUS:
      return 'Update appt status';
    case ReasonForCreation.CLINICAL_NOTES_FOR_APPOINTMENT_PAST_DUE:
      return 'Clinical notes past due';
    case ReasonForCreation.NEW_COLLABORATION_CHAT_MESSAGE:
      return 'New collaboration chat message';
    case ReasonForCreation.NEW_COACH_NOTE_UPDATE:
      return 'New coach note update';
    case ReasonForCreation.NEW_CLINICAL_DOCUMENT_UPLOAD:
      return 'New clinical document upload';
    case ReasonForCreation.NEW_ENTITY_CREATED:
      if (task.survey && task.appointment) {
        return 'Intake form submitted';
      }
      if (task.survey) {
        return 'New survey score submitted';
      }
      if (task.appointment && task.clinicalNote) {
        return 'Collaborating clinician added note';
      }
      throw new Error(`Unknown task description for task ${task.id}`);
    case ReasonForCreation.NEW_ERX_ALERT:
      return 'New ERX alert';
    case ReasonForCreation.SCHEDULED_SESSION:
      return 'Scheduled Session coming up';
    case ReasonForCreation.RISKY_CHAT_DETECTED:
      return 'Risky chat detected';
    case ReasonForCreation.REENGAGEMENT_REMINDER:
      return 'Contact re: inactivity';
    case ReasonForCreation.TERMINATION_REMINDER:
      return 'Write termination note';
    case ReasonForCreation.PHQGAD_REMINDER:
      return 'Send PHQ/GAD reminder';
    case ReasonForCreation.INTAKE_RISK_ALERT:
      return 'Review risk (intake form)';
    case ReasonForCreation.NEW_ACTIONABLE_COLLAB_MESSAGE:
      return 'Review collab message';
    case ReasonForCreation.MISSED_SESSION:
      return 'Contact re: missed session';
  }
  return 'Unknown task';
}

export function TaskContent(props: {
  firstName: string;
  lastName: string;
  taskSummary: string;
  date: string;
}) {
  const { firstName, lastName, taskSummary, date } = props;
  return (
    <Fragment>
      <div>
        {firstName} {lastName}
        <small>{taskSummary}</small>
      </div>
      <small className={styles.time}>{formatDateTime(date)}</small>
    </Fragment>
  );
}

export function TaskGroup(props: {
  tasks: ClinicalTask[];
  selectedTaskId: string | null;
  onSelect: (taskId: string) => void;
  onCheck?: (taskId: string) => void;
}) {
  const { onCheck, onSelect } = props;
  const components = props.tasks.map((task) => {
    const { id, member, updatedAt, status } = task;
    const className = props.selectedTaskId === id ? styles.active : undefined;
    return (
      <button className={className} key={id} onClick={() => onSelect(id)}>
        <Checkbox
          data-testid="taskCheckbox"
          disabled={!onCheck}
          onChange={() => onCheck && onCheck(id)}
          checked={status === TaskStatus.COMPLETED}
        />
        <TaskContent
          firstName={member.firstName}
          lastName={member.lastName}
          date={updatedAt}
          taskSummary={getTaskDescription(task)}
        />
      </button>
    );
  });

  return <Fragment>{components}</Fragment>;
}
