import { Avatar, Stage, useEnvironment } from '@ginger.io/core-ui';
import { Divider } from '@mui/material';
import { WebURL } from 'app/constants';
import { useQuery } from 'hooks/useQuery';
import moment from 'moment';
import React from 'react';

import styles from './CareMetrics.module.scss';
import ClinicianFeedbackComponent from './ClinicianFeedbackComponent';
import {
  GetClinicianInfo,
  GetClinicianInfoVariables,
} from './generated/GetClinicianInfo';
import { getClinicianInfoQuery } from './queries';

export function getWebEndpoint(stage: Stage): string {
  if (stage === Stage.PROD) return WebURL.PROD;
  return WebURL.STAGING;
}

interface ClinicianProfileHeaderProps {
  fullName: string;
  avatar?: string;
  totalLicensedStates?: number;
  timeCommitment?: number | null;
  careLength?: string;
}

function ClinicianProfileHeader({
  fullName,
  avatar,
  totalLicensedStates,
  timeCommitment,
  careLength,
}: ClinicianProfileHeaderProps) {
  return (
    <div className={styles.header}>
      <Avatar
        className={styles.avatar}
        firstName={fullName}
        lastName=""
        src={avatar}
      />
      <div className={styles.name}>{fullName}</div>
      <div>
        Caring for
        {careLength}
      </div>
      <div className={styles.stats}>
        <div>
          <span className={styles.mute}>Hours/wk:</span>
          {timeCommitment || 'N/A'}
        </div>
        <div>
          <span className={styles.mute}>State licenses:</span>
          {totalLicensedStates}
        </div>
      </div>
    </div>
  );
}

function ClinicianSidePanel(props: { clinicianId: string }) {
  const stage = useEnvironment();
  const webEndpoint = getWebEndpoint(stage);

  return useQuery<GetClinicianInfo, GetClinicianInfoVariables>(
    (data) => {
      if (data.getClinician) {
        const {
          fullName,
          avatar,
          licensedStates,
          fullTimeCommitment,
          firstAppointmentDate,
        } = data.getClinician;
        const careLength: string = moment(firstAppointmentDate).fromNow(true);
        const therapistProfileUrl = `${webEndpoint}/mobile-api/v1.2/therapy/therapist/${props.clinicianId}/bio/`;
        return (
          <>
            <ClinicianProfileHeader
              fullName={fullName || ''}
              avatar={avatar || ''}
              totalLicensedStates={licensedStates?.length}
              timeCommitment={fullTimeCommitment}
              careLength={careLength}
            />
            <Divider className={styles.divider} />
            <div className={styles.profileLink}>
              <a href={therapistProfileUrl} target="_blank" rel="noreferrer">
                View full member-facing profile
              </a>
            </div>
            <ClinicianFeedbackComponent clinicianId={props.clinicianId} />
          </>
        );
      }
      return <></>;
    },
    getClinicianInfoQuery,
    { variables: { input: { id: props.clinicianId } } },
  );
}

export default ClinicianSidePanel;
