import { NoteAction } from 'app/appointments/types';
import { createContext, useContext } from 'react';

export type FeatureFlags = {
  vaultEnabled: boolean;
  allowedNoteActions: Set<NoteAction>;
  canViewPatients: boolean;
  canViewMSAppointmentsTable: boolean;
  transientFeatureFlags: Record<TransientFeatureFlag, boolean>;
};

/**
 * Transient Feature flags are used to toggle experimental and/or preliminary functionality and are used to
 * conditionally enable features to a sample portion of users (can also be used to ramp up launches). The flags are
 * stored and managed server-side (e.g. ginger-web via Waffle) and are typically transient flags that are retired after
 * experiments conclude or once a new functionality is fully launched.
 *
 * These flags should not be used to gate access to released features because they will not support any business
 * logic. See Features as the alternative for gating released features (see getFeatures.ts)
 */
export enum TransientFeatureFlag {
  // Enables the Clinical Hub:Schedule functionality
  SCHEDULE = 'enable_clinhub_schedule',
  CHAT_COLLABORATION = 'enable_clinician_chat_collaboration',
  PSYCH_NOTES = 'enable_psych_notes',
  OUT_OF_SESSION_AND_TERMINATION_NOTES = 'enable_out_of_session_and_termination_notes',
  ENABLE_SUPERVISOR_SIGN_AND_LOCK_NOTES_FOR_USER = 'enable_supervisor_sign_and_lock_notes_for_user',
  ENABLE_DRAFT_NOTE_DELETION = 'enable_draft_note_deletion',
  ENABLE_NEW_APPOINTMENTS_TAB_FOR_USER = 'enable_new_appointments_tab_for_user',
  ENABLE_DOCUMENT_UPLOAD = 'enable_document_upload',
  ENABLE_CARE_HUB_NOTES_EFFICIENCY = 'enable_care_hub_notes_efficiency',
  ENABLE_VAULT_LOGIN_UPFRONT = 'enable_vault_login_upfront',
  ENABLE_CARE_HUB_MEMBER_BACKGROUND_CARD_CLINICAL_VIEW = 'enable_care_hub_member_background_card_clinical_view',
  ENABLE_FORM_CCA_REFERRAL_REQUEST = 'enable_form_cca_referral_request',
  ENABLE_FORM_CARE_COORDINATION_REQUEST = 'enable_form_care_coordination_request',
  ENABLE_FORM_CLINICAL_RISK_REVIEW = 'enable_form_clinical_risk_review',
  ENABLE_SURVEY_SCORES_V3 = 'enable_survey_scores_v3',
  ENABLE_MEMBER_TASKS = 'enable_member_tasks',
  ENABLE_HIDE_APPOINTMENTS_TAB = 'enable_hide_appointments_tab',
  ENABLE_CHAT = 'enable_chat',
  ENABLE_TASKS_V2 = 'enable_tasks_v2',
  ENABLE_CARE_METRICS = 'enable_care_metrics',
  ENABLE_CARE_METRICS_COMPANY_TARGET = 'enable_care_metrics_company_target',
  ENABLE_ADAPTABILITY_SURVEYS_V3 = 'enable_adaptability_surveys_v3',
  ENABLE_SCHEDULER_V2 = 'enable_scheduler_v2',
  ENABLE_MEMBER_BACKGROUND_MEMBER_NOTES_SECTION = 'enable_member_background_member_notes_section',
  ENABLE_ADD_SI_COACH_BUTTON = 'enable_add_si_coach_button',
  ENABLE_ADD_MEMBER_SUPPORT_BUTTON = 'enable_add_member_support_button',
  ENABLE_DROP_IN_NOTES = 'enable_drop_in_notes',
  ENABLE_ESCALATE_TO_CLINICAL_BUTTON = 'enable_escalate_to_clinical_button',
  ENABLE_READ_ONLY_CHAT = 'enable_read_only_chat',
  ENABLE_CAREHUB_PUBNUB_SUBSCRIBE_WILDCARD = 'enable_carehub_pubnub_subscribe_wildcard',
  ENABLE_CAREHUB_DEBUG_LOGGER = 'enable_carehub_debug_logger',
  ENABLE_NOTES_EFFICIENCY_CREATION_DISABLED = 'enable_notes_efficiency_creation_disabled',
  ENABLE_CSC_AUTOMATION = 'enable_csc_automation',
  ENABLE_COACH_SHIFT_STATUS_CHANGES = 'enable_coach_shift_status_changes',
  ENABLE_COACHING_SCHEDULER = 'enable_coaching_scheduler',
  ENABLE_VAULT_OKTA_AUTH = 'enable_vault_okta_auth',
  ENABLE_SINGLE_REQUEST_ON_PROGRESS_NOTE_COPIED_SECTION = 'enable_single_request_on_progress_note_copied_section',
  ENABLE_D2C_COACHING_COVERAGE_INFO = 'enable_d2c_coaching_coverage_info',
  CAREHUB_ENABLE_INBOX_V2 = 'carehub_enable_inbox_v2',
  ENABLE_CSSRS_SCORE_TAB = 'enable_cssrs_score_tab',
  ENABLE_TOOLTIP_LABEL_UNDERLINE = 'enable_tooltip_label_underline',
  ENABLE_PROFILE_CARD_IMPROVEMENTS = 'enable_profile_card_improvements',
  ENABLE_THERAPY_INTAKE_NOTE_PREFILL_FROM_INTAKE_SURVEY = 'enable_therapy_intake_note_prefill_from_intake_survey',
  ENABLE_NOTE_PDF_PASSWORD_BYPASS = 'enable_note_pdf_password_bypass',
  ENABLE_SESSIONS_OFFERED_METRICS = 'enable_sessions_offered_metrics',
  ENABLE_PERIODIC_REFRESH_OF_CHAT_HISTORY = 'enable_periodic_refresh_of_chat_history',
  ENABLE_NEW_PDF_FONT = 'enable_new_pdf_font',
}

export const defaultFeatureFlags: FeatureFlags = {
  allowedNoteActions: new Set(),
  canViewMSAppointmentsTable: false,
  canViewPatients: false,
  transientFeatureFlags: {
    // The naming convention clash here is a bit wonky as
    // these feature flags are defined on the server (which uses Python),
    // so they come out as snake case
    carehub_enable_inbox_v2: false,
    enable_adaptability_surveys_v3: false,
    enable_add_member_support_button: false,
    enable_add_si_coach_button: false,
    enable_care_hub_member_background_card_clinical_view: false,
    enable_care_hub_notes_efficiency: false,
    enable_care_metrics: false,
    enable_care_metrics_company_target: false,
    enable_carehub_debug_logger: false,
    enable_carehub_pubnub_subscribe_wildcard: false,
    enable_chat: false,
    enable_clinhub_schedule: false,
    enable_clinician_chat_collaboration: false,
    enable_coach_shift_status_changes: false,
    enable_coaching_scheduler: false,
    enable_csc_automation: false,
    enable_cssrs_score_tab: false,
    enable_d2c_coaching_coverage_info: false,
    enable_document_upload: false,
    enable_draft_note_deletion: false,
    enable_drop_in_notes: false,
    enable_escalate_to_clinical_button: false,
    enable_form_care_coordination_request: false,
    enable_form_cca_referral_request: false,
    enable_form_clinical_risk_review: false,
    enable_hide_appointments_tab: false,
    enable_member_background_member_notes_section: false,
    enable_member_tasks: false,
    enable_new_appointments_tab_for_user: false,
    enable_new_pdf_font: false,
    enable_note_pdf_password_bypass: false,
    enable_notes_efficiency_creation_disabled: false,
    enable_out_of_session_and_termination_notes: false,
    enable_periodic_refresh_of_chat_history: false,
    enable_profile_card_improvements: false,
    enable_psych_notes: false,
    enable_read_only_chat: false,
    enable_scheduler_v2: false,
    enable_sessions_offered_metrics: false,
    enable_single_request_on_progress_note_copied_section: false,
    enable_supervisor_sign_and_lock_notes_for_user: false,
    enable_survey_scores_v3: false,
    enable_tasks_v2: false,
    enable_therapy_intake_note_prefill_from_intake_survey: false,
    enable_tooltip_label_underline: false,
    enable_vault_login_upfront: false,
    enable_vault_okta_auth: false,
  },

  vaultEnabled: true,
};

export const FeatureFlagsContext = createContext(defaultFeatureFlags);

export function useFeatureFlags(): FeatureFlags {
  return useContext(FeatureFlagsContext);
}
