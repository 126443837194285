import { GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions as CoachingSessions } from '@headspace/carehub-graphql/dist/scheduler/generated/GetMemberUpcomingCoachingSessions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Availability } from 'app/member-chart-cards/scheduler/types';
import {
  getAvailabilitiesForMe,
  getMemberUpcomingCoachingSessionsQuery,
} from 'app/scheduler/queries';
import { Services } from 'app/services';
import { AppDispatch, RootState } from 'app/state/hooks/baseTypedHooks';
import { RejectValue } from 'app/state/middlewares/types';
import { GetAvailableTimeSlotsForMeInput } from 'generated/globalTypes';

import {
  getD2CMemberSessionsUsedQuery,
  getServiceProvisionIntervalsForMemberQuery,
} from './queriesAndMutations';
import { SchedulerFormatter } from './SchedulerFormatter';
import { D2cSessionInfo } from './types';

export class SchedulerService {
  static readonly getMemberUpcomingCoachingSessions = createAsyncThunk<
    CoachingSessions[] | undefined,
    { memberId: string },
    {
      dispatch: AppDispatch;
      state: RootState;
      extra: {
        services: Services;
        formatters: {
          schedulerFormatter: SchedulerFormatter;
        };
      };
      rejectValue: RejectValue;
    }
  >(
    'scheduler/getMemberUpcomingCoachingSessions',
    async ({ memberId }, thunkAPI) => {
      const {
        extra: {
          services: { apollo },
        },
        rejectWithValue,
      } = thunkAPI;

      const logPrefix = `SchedulerService.getMemberUpcomingCoachingSessions`;

      try {
        const { data, errors } = await apollo.query({
          fetchPolicy: 'network-only',
          query: getMemberUpcomingCoachingSessionsQuery,
          variables: { input: { memberId } },
        });

        if (errors) {
          const errorMessage = errors.map((e) => e.message).join('; ');
          return rejectWithValue({
            error: new Error(
              `${logPrefix}: GraphQL errors occurred: ${errorMessage}`,
            ),
          });
        }

        if (!data?.getMemberUpcomingCoachingSessions) {
          return rejectWithValue({
            error: new Error(
              `${logPrefix}: No data returned from the coaching sessions query`,
            ),
          });
        }

        if (data.getMemberUpcomingCoachingSessions.error) {
          return rejectWithValue({
            error: new Error(data.getMemberUpcomingCoachingSessions.error),
          });
        }

        return data.getMemberUpcomingCoachingSessions.coachingSessions;
      } catch (error) {
        return rejectWithValue({
          error: new Error(
            `${logPrefix}: An error occurred fetching coaching sessions for member.`,
            {
              cause: error,
            },
          ),
        });
      }
    },
  );

  static readonly getAvailabilitiesForMe = createAsyncThunk<
    Availability[],
    { input: GetAvailableTimeSlotsForMeInput },
    {
      dispatch: AppDispatch;
      state: RootState;
      extra: {
        services: Services;
        formatters: {
          schedulerFormatter: SchedulerFormatter;
        };
      };
      rejectValue: RejectValue;
    }
  >('scheduler/getAvailabilitiesForMe', async ({ input }, thunkAPI) => {
    const {
      extra: {
        services: { apollo },
        formatters: { schedulerFormatter },
      },
      rejectWithValue,
    } = thunkAPI;

    const logPrefix = `SchedulerService.getAvailabilitiesForMe`;

    try {
      const { data, errors } = await apollo.query({
        fetchPolicy: 'network-only',
        query: getAvailabilitiesForMe,
        variables: { input },
      });

      if (errors) {
        const errorMessage = errors.map((e) => e.message).join('; ');
        return rejectWithValue({
          error: new Error(
            `${logPrefix}: GraphQL errors occurred: ${errorMessage}`,
          ),
        });
      }

      if (
        !data?.getAvailabilitiesForMe ||
        data?.getAvailabilitiesForMe?.error
      ) {
        return rejectWithValue({
          error: new Error(
            data.getAvailabilitiesForMe.error ||
              `${logPrefix}: No data returned from getAvailabilitiesForMe query`,
          ),
        });
      }

      const formattedSchedule = schedulerFormatter.formatAvailabilitySchedule(
        data.getAvailabilitiesForMe.timeSlotSeries,
      );
      if (!formattedSchedule) {
        return rejectWithValue({
          error: new Error(
            `${logPrefix}: Failed to format availability schedule`,
          ),
        });
      }
      return formattedSchedule;
    } catch (error) {
      return rejectWithValue({
        error: new Error(
          `${logPrefix}: An error occurred while fetching the coach availability schedule`,
          { cause: error },
        ),
      });
    }
  });

  static readonly getD2cSessionInfo = createAsyncThunk<
    D2cSessionInfo | undefined,
    { memberId: string },
    {
      dispatch: AppDispatch;
      state: RootState;
      extra: {
        services: Services;
        formatters: {
          schedulerFormatter: SchedulerFormatter;
        };
      };
      rejectValue: RejectValue;
    }
  >('scheduler/getD2cSessionInfo', async ({ memberId }, thunkAPI) => {
    const {
      extra: {
        services: { apollo },
      },
      rejectWithValue,
    } = thunkAPI;

    const logPrefix = `SchedulerService.getD2cSessionInfo`;

    try {
      const {
        data: provisionData,
        errors: provisionErrors,
      } = await apollo.query({
        fetchPolicy: 'network-only',
        query: getServiceProvisionIntervalsForMemberQuery,
        variables: { input: { memberId } },
      });

      if (
        !provisionData?.getServiceProvisionIntervalsForMember?.intervals?.length
      ) {
        return {
          isAutorenew: false,
          memberId,
          numSessions: 0,
          sessionsRemaining: 0,
          sessionsUsed: 0,
        };
      }

      if (provisionErrors) {
        const errorMessage = provisionErrors.map((e) => e.message).join('; ');
        return rejectWithValue({
          error: new Error(`${logPrefix}: ${errorMessage}`),
        });
      }

      const interval =
        provisionData.getServiceProvisionIntervalsForMember.intervals[0];
      const { numSessions, isAutorenew, endsAt, startsAt } = interval;

      const {
        data: sessionsUsedData,
        errors: sessionsUsedErrors,
      } = await apollo.query({
        fetchPolicy: 'network-only',
        query: getD2CMemberSessionsUsedQuery,
        variables: {
          input: { fromDate: startsAt, memberId, toDate: endsAt },
        },
      });

      if (sessionsUsedErrors) {
        const errorMessage = sessionsUsedErrors
          .map((e) => e.message)
          .join('; ');
        return rejectWithValue({
          error: new Error(`${logPrefix}: ${errorMessage}`),
        });
      }

      const sessionsUsed =
        sessionsUsedData?.getD2cMemberSessionsUsed?.sessionsUsed || 0;

      let sessionsRemaining = numSessions - sessionsUsed;

      if (sessionsRemaining < 0) {
        sessionsRemaining = 0;
      }

      return {
        isAutorenew,
        memberId,
        numSessions,
        sessionEndDate: endsAt,
        sessionsRemaining,
        sessionsUsed,
      };
    } catch (error) {
      return rejectWithValue({
        error: new Error(`${logPrefix}: An error occurred`, { cause: error }),
      });
    }
  });
}
