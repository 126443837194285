import React, { Fragment } from 'react';

import { Box } from '../Box';
import styles from './DeprecatedCard.module.scss';

type DeprecatedCardProps = {
  title: string;
  terms: { [key: string]: string | number | null };
  dataTestId?: string;
};

export function DeprecatedCard({
  title,
  terms,
  dataTestId,
}: DeprecatedCardProps) {
  const termsEl = Object.entries(terms).map(([termValue, definition]) => (
    <Fragment key={termValue}>
      <dt>{termValue}</dt>
      <dd>{definition ?? 'N/A'}</dd>
    </Fragment>
  ));

  return (
    <Box title={title} testId={dataTestId}>
      <dl className={styles.details}>{termsEl}</dl>
    </Box>
  );
}
