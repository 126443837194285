import { useLazyQuery, useQuery } from '@apollo/client';
import Badge from '@mui/material/Badge';
import { GQL_QUERY_POLLING_INTERVAL } from 'app/constants';
import Search from 'shared-components/search-input/Search';
import { GetDosespotIframeCredentials } from 'app/patients/tabs/prescriptions/generated/GetDosespotIframeCredentials';
import { getDosespotIframeCredentials as getDosespotIframeCredentialsQuery } from 'app/patients/tabs/prescriptions/queries';
import { useAppState, useDispatch } from 'app/state';
import { updateSessionState } from 'app/state/features/auth/authSlice';
import { SessionState } from 'app/state/features/auth/types';
import { useLogger } from 'app/state/log/useLogger';
import { Routes, searchRoute, TopLevelRoutes } from 'app/top-nav/Routes';
import { isClinicianOrSupervisor } from 'utils';
import { ReasonForCreation, TaskStatus, UserRole } from 'generated/globalTypes';
import gql from 'graphql-tag';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import React, { useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import AppBanner from 'shared-components/banners/AppBanner';
import {
  isAuthorizationError,
  isGraphQLAuthenticationError,
} from 'shared-components/error-state/utils';

import { getTasksAndFeatureFlags } from './generated/getTasksAndFeatureFlags';
import { ReactComponent as Logo } from './logo.svg';
import { SettingsMenu } from './SettingsMenu';
import styles from './TopNav.module.scss';

export const getTasksAndFeatureFlagsQuery = gql`
  query getTasksAndFeatureFlags {
    getTasks {
      all {
        id
        status
        reasonForCreation
      }
    }
  }
`;

type TopNavProps = {
  redirectTo: (path: string) => void;
  dataTestId?: string;
};

/** The top nav bar of the UI - contains the ginger logo + logout links */
export function TopNav({ redirectTo, dataTestId }: TopNavProps) {
  const role = useAppState((_) => _.user.role);
  const onSearchQuery = (q: string) =>
    redirectTo(searchRoute(Routes.MEMBERS, q));

  return (
    <div data-testid={dataTestId}>
      <AppBanner />
      <nav className={styles.topNav}>
        <ul className={styles.main}>
          <li>
            <Logo />
          </li>
          {isClinicianOrSupervisor(role) && <ClinicianNav />}
        </ul>
        {role === UserRole.MEMBER_SUPPORT && (
          <div className={styles.search}>
            <Search
              data-testid="search"
              onSearchQuery={onSearchQuery}
              className={styles.input}
            />
          </div>
        )}
        <ul className={styles.settings}>
          <li>
            <SettingsMenu
              data-testid="settingsMenu"
              redirectTo={redirectTo}
              role={role}
            />
          </li>
        </ul>
      </nav>
    </div>
  );
}

/**
 * Displays the top navigation tabs with additional choices for the Schedule UI feature preview.
 *
 * Routes.Appointments is still visible for now so that users can switch back-and-forth between the
 * Schedule and the current experience. Eventually, the Routes.Appointments route will be removed.
 *
 * @param props
 */
function ClinicianNav() {
  const { data, error, stopPolling } = useQuery<getTasksAndFeatureFlags>(
    getTasksAndFeatureFlagsQuery,
    {
      pollInterval: GQL_QUERY_POLLING_INTERVAL,
    },
  );
  const logger = useLogger();
  const dispatch = useDispatch();
  const userRole = useAppState(({ user }) => user.role);
  const {
    transientFeatureFlags: {
      enable_hide_appointments_tab: hideAppointmentsTab,
    },
  } = useFeatureFlags();

  const [showERxTab, setShowERxTab] = useState(false); // Declare state variable
  const [getDosespotIframeCredentials] = useLazyQuery<
    GetDosespotIframeCredentials
  >(getDosespotIframeCredentialsQuery);

  useEffect(() => {
    const fetchDataIfNeeded = async () => {
      if (
        userRole &&
        [UserRole.PSYCHIATRIST, UserRole.PSYCHIATRIST_SUPERVISOR].includes(
          userRole,
        )
      ) {
        const dosespotCredentials = await getDosespotIframeCredentials();
        if (dosespotCredentials.error) {
          logger.error(
            new Error(
              'TopNav::getDosespotIframeCredentials: Unable to retrieve credentials',
            ),
            {
              error: dosespotCredentials.error,
            },
          );
        }
        setShowERxTab(
          !!dosespotCredentials?.data?.getDosespotIframeCredentials,
        );
      }
    };
    fetchDataIfNeeded().catch((e) => {
      logger.error(e);
    });
  }, [userRole, getDosespotIframeCredentials, logger]);
  useEffect(() => {
    // for some reason the getTasks resolver throws a 403 error when we query with expired token, so we check for
    // authorization or authentication error, since this TopNav component and getTasks resolver will be deprecated soon
    // there is no need for updating the resolver to return an authentication error
    if (isGraphQLAuthenticationError(error) || isAuthorizationError(error)) {
      stopPolling();
      dispatch(updateSessionState(SessionState.EXPIRED));
    }
  }, [error, dispatch, stopPolling]);

  const allTasks = useMemo(() => data?.getTasks.all ?? [], [data]);
  const erxAlertTask = allTasks.filter(
    (t) => t.reasonForCreation === ReasonForCreation.NEW_ERX_ALERT,
  );

  const activeTasks = useMemo(
    () =>
      allTasks.filter(
        (t) =>
          t.reasonForCreation !== ReasonForCreation.NEW_ERX_ALERT &&
          t.status === TaskStatus.ACTIVE,
      ),
    [allTasks],
  );
  const pendingTaskCount = activeTasks?.length ?? 0;
  const pendingeRxAlertTaskCount = erxAlertTask?.length ?? 0;

  return (
    <>
      <li>
        <TopLevelLink to={TopLevelRoutes.TASKS}>
          <Badge
            overlap="rectangular"
            data-testid="badge"
            badgeContent={pendingTaskCount}
            color="primary"
            classes={{ badge: styles.badge }}
          >
            Tasks
          </Badge>
        </TopLevelLink>
      </li>
      <li>
        <TopLevelLink to={TopLevelRoutes.PATIENTS} dataTestid="MembersLink">
          Members
        </TopLevelLink>
      </li>
      {!hideAppointmentsTab && (
        <li>
          <TopLevelLink
            to={TopLevelRoutes.APPOINTMENTS}
            dataTestid="appointments-tab"
          >
            Appointments
          </TopLevelLink>
        </li>
      )}
      <li>
        <TopLevelLink dataTestid="schedule-tab" to={TopLevelRoutes.SCHEDULE}>
          Schedule
        </TopLevelLink>
      </li>
      {showERxTab && (
        <li>
          <TopLevelLink
            dataTestid="erx-tab"
            to={TopLevelRoutes.ERX_NOTIFICATIONS}
          >
            <Badge
              data-testid="erx-badge"
              badgeContent={pendingeRxAlertTaskCount}
              color="primary"
              classes={{ badge: styles.badge }}
            >
              Prescriptions
            </Badge>
          </TopLevelLink>
        </li>
      )}
    </>
  );
}

function TopLevelLink(props: {
  to: TopLevelRoutes;
  dataTestid?: string;
  children: string | JSX.Element; // eslint-disable-line
}): JSX.Element /* eslint-disable-line */ {
  const { to, children, dataTestid } = props;
  return (
    <NavLink to={to} activeClassName={styles.active} data-testid={dataTestid}>
      {children}
    </NavLink>
  );
}
