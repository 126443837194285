import { DEFAULT_TIMEZONE } from 'app/clinician/ClinicianSettingsComponent';
import { ApolloCachingStrategy } from 'app/constants';
import {
  DeprecatedMasonryLayout,
  MasonryItem,
  TABS_MASONRY_2_COLUMNS_CONFIG,
} from 'app/masonry-layout/DeprecatedMasonryLayout';
import { IntakeCard } from 'app/patients/tabs/intake/IntakeTabV2Card';
import {
  GetMemberIntake,
  GetMemberIntake_getMember_intakeChart as IntakeTabData,
  GetMemberIntake_getMember_referralNote as ReferralNote,
  GetMemberIntakeVariables,
} from 'app/queries/generated/GetMemberIntake';
import { getMemberIntake } from 'app/queries/GetMember';
import { useAppState } from 'app/state';
import { viewIntakeTabAction } from 'app/state/amplitude/actions/etc';
import { useLogger } from 'app/state/log/useLogger';
import { useMemberChartIndicators } from 'hooks/useMemberChartIndicators';
import { useMemberSurveys } from 'hooks/useMemberSurveys';
import { useQuery } from 'hooks/useQuery';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import styles from './IntakeTab.module.scss';
import { Relationships } from './RelationshipsV2';
import { RiskIndicators } from './RiskIndicatorsV2';
import { SelfReportedSymptoms } from './SelfReportedSymptomsV2';

interface Props {
  chart: IntakeTabData;
  referralNote: ReferralNote | null;
  intakeCompletedAt: string | null;
  memberId: string;
}

export function IntakeTabContent(props: Readonly<Props>) {
  const logger = useLogger();
  const { chart, referralNote } = props;
  const {
    clinicalServicesSeeking,
    reasonsForSeekingClinicalServices,
    mentalIllnessIndicators,
    additionalInformationForClinician,
    preExistingMedicalConditions,
    currentMedications,
    relationships,
    riskKeywordsExtractedFromIntake,
    substanceConsumption,
    frequencyOfSubstanceConsumption,
    thoughtsOfHarm,
    reasonsForThoughtsOfHarm,
    religion,
  } = chart;

  const { intakeCompletedAt, memberId } = props;

  const referralNoteExists = referralNote != null;
  const { note, source, serviceType } =
    referralNote !== null
      ? referralNote
      : { note: '', serviceType: null, source: '' };

  const {
    updateIntakeIndicator,
    showIntakeIndicator,
  } = useMemberChartIndicators(memberId);

  useEffect(() => {
    if (showIntakeIndicator) {
      updateIntakeIndicator?.().catch((reason) =>
        logger.warning('Failed to update intake indicator', { error: reason }),
      );
    }
  }, [showIntakeIndicator]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewIntakeTabAction());
  }, [dispatch]);

  const { data } = useMemberSurveys({ loadPHQ9s: true, memberId });

  const phq9s: { selfHarm: number }[] = (
    data?.getMember?.surveyResponses?.phq9s?.surveys ?? []
  )
    .filter((survey) => survey.selfHarm != null)
    .map((survey) => ({ selfHarm: survey.selfHarm as number }));

  const timezone = useAppState((_) => _.user.timezone ?? DEFAULT_TIMEZONE);
  const formattedTime = intakeCompletedAt
    ? moment(intakeCompletedAt).tz(timezone).format('MM/DD/YYYY')
    : null;
  const careTerms = {
    'Additional information for Clinician': additionalInformationForClinician,
    'Intake Form Submitted': formattedTime,
    'Reasons for Seeking Care': reasonsForSeekingClinicalServices,
    'Seeking Services': clinicalServicesSeeking,
  };

  return (
    <div className={styles.tabContent}>
      <DeprecatedMasonryLayout
        breakPointCols={TABS_MASONRY_2_COLUMNS_CONFIG.breakPointCols}
      >
        <MasonryItem>
          <IntakeCard testId="careCard" title="Care" terms={careTerms} />
        </MasonryItem>
        <MasonryItem>
          <IntakeCard
            testId="medHistoryCard"
            title="Medical History"
            terms={{
              'Current Medications': currentMedications,
              'Frequency of substance use': frequencyOfSubstanceConsumption,
              'Preexisting Medical Conditions': preExistingMedicalConditions,
              'Substance use': substanceConsumption
                ? substanceConsumption.join(', ')
                : 'N/A',
            }}
          />
        </MasonryItem>

        <MasonryItem>
          {mentalIllnessIndicators && (
            <SelfReportedSymptoms {...mentalIllnessIndicators} />
          )}
        </MasonryItem>
        <MasonryItem>
          <RiskIndicators
            thoughtsOfHarm={thoughtsOfHarm}
            reasonsForThoughtsOfHarm={reasonsForThoughtsOfHarm}
            phq9SelfHarmAnswers={phq9s}
            riskKeywords={riskKeywordsExtractedFromIntake}
          />
        </MasonryItem>

        {relationships && (
          <MasonryItem>
            <Relationships
              family={{
                additionalDetails: relationships.familyDetails,
                score: relationships.familyScore,
              }}
              spouse={{
                additionalDetails: relationships.spouseDetails,
                hasRelationship: relationships.hasSpouse,
                score: relationships.spouseScore,
              }}
              children={{
                additionalDetails: relationships.childrenDetails,
                hasRelationship: relationships.hasChildren,
                score: relationships.childrenScore,
              }}
            />
          </MasonryItem>
        )}
        {religion && (
          <MasonryItem>
            <IntakeCard
              testId="religionCard"
              title="Religion"
              terms={{
                'Additional Info:': religion.description,
                'Is a member of religious or spiritual group': religion.isMemberOfReligiousGroup.toString(),
              }}
            />
          </MasonryItem>
        )}

        {referralNoteExists && (
          <MasonryItem>
            <IntakeCard
              testId="referralInfoCard"
              title="Referral Information"
              terms={{
                'Referral Note': note,
                'Service Type': serviceType,
                Source: source,
              }}
            />
          </MasonryItem>
        )}
      </DeprecatedMasonryLayout>
    </div>
  );
}

function EmptyIntakeTab() {
  return (
    <div className={styles.emptyTabContent}>
      <h1>No intake form submitted</h1>
    </div>
  );
}

export function IntakeTabV2(props: { memberId: string }) {
  const logger = useLogger();
  return useQuery<GetMemberIntake, GetMemberIntakeVariables>(
    (data) => {
      const member = data?.getMember;
      if (member?.intakeChart == null) {
        logger.info('IntakeTabV2: Member or intake chart is null', {
          memberId: props.memberId,
        });
        return <EmptyIntakeTab />;
      }

      return (
        <IntakeTabContent
          data-testid="intakeTab"
          chart={member.intakeChart}
          referralNote={member?.referralNote}
          intakeCompletedAt={member?.intakeCompletedAt}
          memberId={props.memberId}
        />
      );
    },
    getMemberIntake,
    {
      fetchPolicy: ApolloCachingStrategy.CACHE_FIRST,
      variables: { input: { id: props.memberId } },
    },
  );
}
