import {
  GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions_recurrence as Recurrence,
  GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions_sessions as CoachingSession,
} from '@headspace/carehub-graphql/dist/scheduler/generated/GetMemberUpcomingCoachingSessions';
import { useAppState } from 'app/state';
import CalendarIcon from 'assets/CalendarBlank.svg';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import moment from 'moment-timezone';
import React from 'react';
import { SpecialtyTag } from 'shared-components/tags/SpecialtyTag';
import { formatName } from 'utils';
import { getTimezone } from 'utils/dateTime';

import { formatCoachSchedule } from '../../coach/Utils';
import SessionTime from './SessionTime';
import styles from './SingleScheduledSession.module.scss';
import { getSessionDuration } from './utils';

interface Props {
  memberTimezone: string | undefined;
  session: CoachingSession;
  recurrence?: Recurrence | null;
  showLastRecurringSessionBadge?: boolean;
  onClickCoachingSession: (
    coachingSession: CoachingSession,
    recurrence: Recurrence | null,
  ) => void;
}

const SingleScheduledSession = ({
  session,
  recurrence = null,
  showLastRecurringSessionBadge = false,
  onClickCoachingSession,
  memberTimezone = 'UTC',
}: Props) => {
  const { timezone: coachTimezone, listenerId } = useAppState(
    ({ user: { timezone, listenerId } }) => ({
      listenerId,
      timezone: getTimezone(timezone),
    }),
  );
  const {
    enable_coaching_scheduler: enableCoachingScheduler,
  } = useFeatureFlags().transientFeatureFlags;
  const userTime = moment(session.startTime).tz(memberTimezone);
  const coachTime = moment(session.startTime).tz(coachTimezone ?? 'UTC');
  const occurrence = recurrence?.everyNWeeks || 0;

  const handleOnEdit = () => {
    onClickCoachingSession(session, recurrence);
  };

  const coachName =
    listenerId === session.coach.id
      ? 'You'
      : `${session.coach.name} ${formatCoachSchedule(session.coach.shift)}`;

  const formattedMemberName = formatName(
    session.member.preferredFirstName ?? session.member.firstName,
  );

  return (
    <div
      role="button"
      tabIndex={0}
      data-testid="singleScheduledSession"
      data-session-id={session.id}
      className={styles.session}
      onClick={handleOnEdit}
      onKeyUp={handleOnEdit}
    >
      <div className={styles.names}>
        <img className={styles.icon} src={CalendarIcon} alt="Calendar Icon" />
        {enableCoachingScheduler ? (
          <span>{`${coachName} / ${formattedMemberName}`}</span>
        ) : (
          <span>
            {session.coach.name} ({formatCoachSchedule(session.coach.shift)}) /{' '}
            {formattedMemberName}
          </span>
        )}
      </div>
      <SessionTime userTime={userTime} coachTime={coachTime} />
      <div data-testid="durationId" className={styles.duration}>
        <span>
          {getSessionDuration(session.startTime!, session.endTime!, occurrence)}
        </span>
      </div>
      {showLastRecurringSessionBadge ? (
        <div className={styles.lastBadge}>
          <SpecialtyTag
            text="Last Recurring Session"
            className={styles.lastBadgeText}
          />
        </div>
      ) : null}
    </div>
  );
};

export default SingleScheduledSession;
