import React from 'react';
import { Button } from 'shared-components/button/Button';

import styles from '../SchedulerCard.module.scss';

export const ServiceNowButton = ({ onClick }: { onClick: () => void }) => (
  <div className={styles.serviceNowBtnContainer}>
    <strong className={styles.timeOffRequest}>
      To cancel an intake session, please submit a time off request in Service
      Now
    </strong>
    <Button
      className={styles.checkAvailability}
      size="small"
      onClick={onClick}
      testId="serviceNowBtn"
    >
      Submit time off in Service Now
    </Button>
  </div>
);
