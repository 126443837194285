import { Circle } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import styles from 'app/inbox/vertical-menu/styles/index.module.scss';
import { classNameCombiner } from 'utils';
import { CoachShiftStatusEnum } from 'generated/globalTypes';
import React from 'react';
import { snakeCaseToTitleCase } from 'utils/snakeCaseToTitleCase';

const OFFLINE_INDICATOR_STATUSES = [
  CoachShiftStatusEnum.LUNCH,
  CoachShiftStatusEnum.MEETING,
  CoachShiftStatusEnum.ONE_ON_ONE,
  CoachShiftStatusEnum.PROCESS_GROUP,
  CoachShiftStatusEnum.REST_BREAK,
  CoachShiftStatusEnum.SNAPS,
  CoachShiftStatusEnum.WRAP_UP,
];

const COACH_SHIFT_STATUS_INDICATOR_MAP = new Map<CoachShiftStatusEnum, string>([
  [CoachShiftStatusEnum.ONLINE, styles.green],
  [CoachShiftStatusEnum.REPLY_ONLY, styles.tumeric],
  [CoachShiftStatusEnum.OFFLINE, styles.red],
]);

type Props = {
  firstName: string;
  lastName: string;
  currentStatus: CoachShiftStatusEnum;
  onStatusChange: (
    status: CoachShiftStatusEnum,
  ) => (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

export function CoachMenuItems({
  firstName,
  lastName,
  currentStatus,
  onStatusChange,
}: Props) {
  const fullName = `${firstName} ${lastName}`;
  const indicatorStyle =
    COACH_SHIFT_STATUS_INDICATOR_MAP.get(currentStatus) ?? styles.red;

  return [
    <MenuItem
      className={classNameCombiner([styles.menuItem, styles.specialMenuItem])}
      key="special"
    >
      <ListItemIcon
        className={classNameCombiner([
          styles.listItemIcon,
          styles.specialItemIcon,
          indicatorStyle,
        ])}
      >
        <Circle />
      </ListItemIcon>
      <ListItemText>
        <div className={styles.username} data-testid="username">
          {fullName}
        </div>
        <div className={styles.userStatus} data-testid="user-status">
          {snakeCaseToTitleCase(currentStatus)}
        </div>
      </ListItemText>
    </MenuItem>,

    <MenuItem
      className={styles.menuItem}
      onClick={onStatusChange(CoachShiftStatusEnum.ONLINE)}
      key="online"
      data-testid={CoachShiftStatusEnum.ONLINE}
    >
      <ListItemIcon
        className={classNameCombiner([styles.listItemIcon, styles.green])}
      >
        <Circle />
      </ListItemIcon>
      <ListItemText>
        <div className={styles.topLevelStatus}>Online</div>
      </ListItemText>
    </MenuItem>,
    <MenuItem
      className={styles.menuItem}
      onClick={onStatusChange(CoachShiftStatusEnum.REPLY_ONLY)}
      key="reply-only"
      data-testid={CoachShiftStatusEnum.REPLY_ONLY}
    >
      <ListItemIcon
        className={classNameCombiner([styles.listItemIcon, styles.tumeric])}
      >
        <Circle />
      </ListItemIcon>
      <ListItemText>
        <div className={styles.topLevelStatus}>Reply-only</div>
      </ListItemText>
    </MenuItem>,
    <MenuItem
      className={styles.menuItem}
      onClick={onStatusChange(CoachShiftStatusEnum.OFFLINE)}
      key="offline"
      data-testid={CoachShiftStatusEnum.OFFLINE}
    >
      <ListItemIcon
        className={classNameCombiner([styles.listItemIcon, styles.red])}
      >
        <Circle />
      </ListItemIcon>
      <ListItemText>
        <div className={styles.topLevelStatus}>Offline</div>
      </ListItemText>
    </MenuItem>,
    ...OFFLINE_INDICATOR_STATUSES.map((status) => (
      <MenuItem
        className={styles.menuItem}
        onClick={onStatusChange(status)}
        key={status}
        data-testid={status}
      >
        <ListItemIcon
          className={classNameCombiner([styles.listItemIcon, styles.white])}
        >
          <Circle />
        </ListItemIcon>
        <ListItemText>{snakeCaseToTitleCase(status)}</ListItemText>
      </MenuItem>
    )),
  ];
}
