import gql from 'graphql-tag';

export const getDosespotIframeCredentials = gql`
  query GetDosespotIframeCredentials {
    getDosespotIframeCredentials {
      clinicId
      userId
      encryptedClinicianId
      encryptedUserId
      url
    }
  }
`;

export const markPrescriptionAlertsAsRead = gql`
  mutation ClearClinicalTasksForMe($input: ClearClinicalTasksMutationInput!) {
    clearClinicalTasksForMe(input: $input) {
      ok
      error
    }
  }
`;
