import styles from 'app/inbox/components/styles/InboxSectionItem.module.scss';
import { classNameCombiner } from 'utils';
import { ReactComponent as NotificationIndicator } from 'assets/icons/notification-indicator.svg';
import React from 'react';

export interface SegmentSubContentProps {
  showCalendarIcon?: boolean;
  text?: string;
  textColor?: 'critical' | 'primary' | 'default';
  showBlueDot?: boolean;
}

export const SegmentSubContent = (props: SegmentSubContentProps) => {
  const { showCalendarIcon, textColor = 'default', text, showBlueDot } = props;

  return (
    <div className={classNameCombiner([styles.tr, styles.summary])}>
      <div
        className={classNameCombiner([
          styles.td,
          styles.text,
          showCalendarIcon ? styles['calendar-blank-icon'] : '',
          styles[textColor],
        ])}
      >
        {text}
      </div>
      {showBlueDot && (
        <span className={classNameCombiner([styles.td])}>
          <NotificationIndicator data-testid="blueDot" />
        </span>
      )}
    </div>
  );
};
