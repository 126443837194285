import React, { useMemo } from 'react';
import { Box } from 'shared-components/Box';
import { RowType, Table } from 'shared-components/table/Table';
import { HeadCell } from 'shared-components/table/TableHead';

type Relationship = {
  score: number | null;
  additionalDetails: string | null;
  hasRelationship?: string | null;
};
type Props = {
  family: Relationship;
  children: Relationship;
  spouse: Relationship;
};

type RowData = {
  type: string;
  rating: string;
  comments: string;
  hasRelationShip: string;
};

const columns: HeadCell<RowData>[] = [
  { key: 'type', label: 'Type' },
  { key: 'hasRelationShip', label: 'Yes/No' },
  { key: 'rating', label: 'Rating' },
  { key: 'comments', label: 'Comments' },
];

export function Relationships(props: Props) {
  const rows: RowType<RowData> = useMemo(() => toRowData(props), [props]);
  return (
    <Box testId="relationshipsCard" title="Relationships">
      <Table
        classes={{ cell: 'vertical-align-top' }}
        showCheckboxes={false}
        data-testid="relationshipRatingTable"
        columns={columns}
        rows={rows}
      />
    </Box>
  );
}

function toRowData(props: Props) {
  const { family, children, spouse } = props;
  const row = (type: string, entry: Relationship): RowData => {
    const { score, additionalDetails, hasRelationship } = entry;
    return {
      comments: getOrNA(additionalDetails),
      hasRelationShip: getOrNA(hasRelationship),
      rating: score ? `${score} out of 10` : '-',
      type,
    };
  };
  return {
    Children: row('Children', children),
    Family: row('Family', family),
    Spouse: row('Spouse', spouse),
  };
}

function getOrNA(str?: string | null) {
  if (str === undefined || str === null) return '-';
  return str;
}
