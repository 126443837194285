import { GetMemberCoachingTeam_getMember_coachingCareTeam as CoachingCareTeam } from 'app/queries/generated/GetMemberCoachingTeam';
import { maybeUpdateReadOnlyState } from 'app/state/chat/actions';
import { setCurrentChannel } from 'app/state/inbox/actions';
import {
  selectChannelByMemberId,
  selectCurrentPubnubChannelId,
  selectIsChatRelatedDataLoaded,
} from 'app/state/inbox/selectors';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loader } from 'shared-components/loader/Loader';

import { Chat } from './Chat';

export interface MemberProfileData {
  baseProps: MemberProfileBaseProps;
  preferences: MemberProfilePreferences;
  memberId: string;
  callerId: string;
}

export interface MemberProfileBaseProps {
  firstName: string;
  avatarSrc?: string;
  lastName: string;
}

interface MemberProfilePreferences {
  timezone: string;
}

export interface ChatContainerProps {
  memberProfile: MemberProfileData;
  coachingCareTeam: CoachingCareTeam | null;
  toggleChatExpansion: () => void;
  isChatExpanded: boolean;
}

export const ChatContainer = (props: ChatContainerProps) => {
  const { memberId } = useParams<{ memberId: string }>();
  const currentChannelId = selectCurrentPubnubChannelId();
  const memberCoachChannelId = selectChannelByMemberId(memberId);
  const isChatRelatedDataLoaded = selectIsChatRelatedDataLoaded(
    currentChannelId,
  );
  const dispatch = useDispatch();

  const { coachingCareTeam } = props;

  // the useEffect observes a change of a member (or tab switches) and initializes the conversation and messages history if that hasn't been done before
  useEffect(() => {
    if (memberCoachChannelId) {
      dispatch(setCurrentChannel(memberCoachChannelId));
    }
  }, [memberId, memberCoachChannelId]);

  useEffect(() => {
    if (currentChannelId && coachingCareTeam)
      dispatch(
        maybeUpdateReadOnlyState({
          channelId: currentChannelId,
          coachingCareTeam,
        }),
      );
  }, [currentChannelId]);

  return isChatRelatedDataLoaded ? <Chat {...props} /> : <Loader />;
};
