import { DependentIntakeReport } from '@ginger.io/core-ui';
import { viewDependentReport } from 'app/state/amplitude/actions/etc';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

function DependentIntakeScreen(
  props: RouteComponentProps<{ patientId: string }>,
) {
  const { match } = props;
  const { params } = match;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewDependentReport());
  }, [dispatch]);

  return <DependentIntakeReport id={params.patientId} />;
}

export default withRouter(DependentIntakeScreen);
