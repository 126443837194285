import gql from 'graphql-tag';
import { CareTeamFragment } from 'app/care-team/CareTeam';
import { IntakeTabFragment } from 'app/patients/tabs/intake/queries';
import { SurveysTabFragment } from 'app/patients/tabs/surveys/queries';
import { QueryResult, useQuery } from '@apollo/client';
import { ApolloCachingStrategy } from 'app/constants';
import {
  StateSlice,
  Status as NotesStatus,
} from 'app/state/status/types/StateSlice';
import {
  NonAppointmentNotes,
  useOutOfSessionAndTerminationNotes,
} from 'app/vault/hooks/NonAppointments/useOutOfSessionAndTerminationNotes';
import {
  ClinicalAppointmentStatus,
  ClinicalAppointmentType,
  PaginationInput,
  UserRole,
} from 'generated/globalTypes';
import {
  GetClinicalAppointmentsForMember,
  GetClinicalAppointmentsForMemberVariables,
} from './generated/GetClinicalAppointmentsForMember';
import { isClinicianOrSupervisor } from '../../utils';

export const getPatientsQuery = gql`
  query GetPatient($patientId: ID!) {
    getPatient(id: $patientId) {
      id
      isMinorDependent
      isSpanish
      isFrench
      preferredPharmacy

      address {
        street
        city
        state
        zip
      }

      emergencyContact {
        name
        phone
        relationship
      }

      surveyResponses {
        phq9s {
          total
          timestamp
        }

        gad7s {
          total
          timestamp
        }
      }
      ...CareTeamFragment
      ...IntakeTabFragment
      ...SurveysTabFragment
    }
  }

  ${IntakeTabFragment}
  ${SurveysTabFragment}
  ${CareTeamFragment}
`;

export const queryApptsForMemberSchedule = gql`
  query GetClinicalAppointmentsForMember(
    $input: GetClinicalAppointmentsForMemberInput!
  ) {
    getClinicalAppointmentsForMember(input: $input) {
      appointments {
        id
        drchronoId
        clinician {
          id
          user {
            id
          }
          fullName
          role
        }
        member {
          id
          firstName
          lastName
        }
        start
        end
        appointmentStatus
        cancelledReason
        type
        zoomMeetingId
        clinicalNote {
          id
          status
          noteType
          location
          statusUpdatedAt
        }
      }
    }
  }
`;

/**
 * Loads the appointments
 * @param patientId a patient id - string
 * @param appointmentStatus a status of the appointment - ClinicalAppointmentStatus?
 * @returns returns a QueryResult<GetClinicalAppointmentsForMember, GetClinicalAppointmentsForMemberVariables> hook result
 */
export const loadAppointments = (props: {
  patientId: string;
  appointmentStatus?: ClinicalAppointmentStatus[];
  appointmentType?: ClinicalAppointmentType[];
  pagination?: PaginationInput;
  pollInterval?: number;
}): QueryResult<
  GetClinicalAppointmentsForMember,
  GetClinicalAppointmentsForMemberVariables
> => {
  const {
    patientId,
    appointmentStatus = null,
    appointmentType = null,
    pagination,
    pollInterval,
  } = props;
  const variables = {
    input: {
      memberId: patientId,
      filters: {
        appointmentStatus_In: appointmentStatus,
        appointmentType_In: appointmentType,
      },
      pagination,
    },
  };
  return useQuery<
    GetClinicalAppointmentsForMember,
    GetClinicalAppointmentsForMemberVariables
  >(queryApptsForMemberSchedule, {
    variables,
    fetchPolicy: ApolloCachingStrategy.NETWORK_ONLY,
    pollInterval,
  });
};

/**
 * Loads the patient notes
 * @param patientId patient id - string
 * @param role A user role - UserRole
 * @param localTimezone a timezone (default to UTC) - string
 * @returns A StateSlice<NonAppointmentNotes> hook result - StateSlice
 */
export const loadNotes = (params: {
  patientId: string;
  userId: string;
  vaultUserId: string;
  role: UserRole;
  localTimezone: string;
}): StateSlice<NonAppointmentNotes | null> => {
  const { patientId, role, userId, localTimezone, vaultUserId } = params;
  if (isClinicianOrSupervisor(role)) {
    return useOutOfSessionAndTerminationNotes({
      memberId: patientId,
      timezone: localTimezone,
      role,
      userId,
      vaultUserId,
    });
  }

  return {
    data: null,
    status: NotesStatus.COMPLETE,
    error: null,
  };
};
