import { InputBase } from '@mui/material';
import { SYSTEM_REMINDER_TASK_REASONS } from 'app/constants';
import { Accordion } from 'shared-components/Accordion';
import { Card } from 'shared-components/Card';
import {
  isUnChecked,
  ThreeStateCheckbox,
} from 'shared-components/ThreeStateCheckbox/ThreeStateCheckbox';
import { displayTaskLabel } from 'app/member-chart-cards/tasks/labels';
import styles from 'app/member-chart-cards/tasks/Tasks.module.scss';
import { TasksTooltip } from 'app/member-chart-cards/tasks/TasksTooltip';
import { useTasksState } from 'app/member-chart-cards/tasks/UseTasksState';
import { useAppState } from 'app/state';
import { isClinicianOrSupervisor } from 'utils';
import { filterTasksToDisplay, sortTasksToDisplay } from 'utils/tasksUtils';
import { Reason } from 'generated/globalTypes';
import { TransientFeatureFlag, useFeatureFlags } from 'hooks/useFeatureFlags';
import React, { FocusEvent, KeyboardEvent, useMemo, useState } from 'react';
import { UncheckedCheckboxIcon } from 'shared-components/icons/UncheckedCheckboxIcon';

import Titles from '../constants/cards-titles';
import { ActiveFollowupItem } from './ActiveFollowupItem';
import { TaskItemResponse, TodaysItem, UseTasksResult } from './types';

export type TasksProps = {
  followupItems: TaskItemResponse[];
  initialConsultItems: TaskItemResponse[];
  todaysTasks?: TodaysItem[];
  generateId?: () => string;
  createTask: UseTasksResult['createTask'];
  updateTask: UseTasksResult['updateTask'];
  deleteTask: UseTasksResult['deleteTask'];
  'data-testid'?: string;
  memberId?: string;
  onCheck: UseTasksResult['onCheckTask'];
};

export function Tasks(props: TasksProps): JSX.Element {
  const { 'data-testid': testId } = props;
  const {
    onCreateFollowup,
    onUpdateFollowup,
    onCheckStateUpdate,
    unifyItemStates,
    activeInitialConsultItems,
    activeFollowupItems,
    metadata,
    checkAllState,
    updatingState,
    updatingAllInitialConsultState,
    isSavingFollowup,
    isSavingInitialConsult,
    onUpdateTodaysTask,
    todaysTasks,
  } = useTasksState(props);
  const [showCompleted, setShowCompleted] = useState<boolean>(false);
  const [newLabel, setNewLabel] = useState<string>('');
  const enableTasksV2 = useFeatureFlags().transientFeatureFlags[
    TransientFeatureFlag.ENABLE_TASKS_V2
  ];
  const { role, timezone } = useAppState(({ user }) => ({
    role: user.role,
    timezone: user.timezone,
  }));
  const createFollowup = (label: string): void => {
    void onCreateFollowup(label);
    setNewLabel('');
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>): void => {
    const label = (e.target as HTMLInputElement).value.trim();
    if (e.shiftKey || e.key !== 'Enter' || label === '') return;
    e.preventDefault();
    createFollowup(label);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>): void => {
    const label = e.target.value.trim();
    if (label === '') return;
    createFollowup(label);
  };

  const handleCardToggle = (isToggleOn: boolean): void =>
    setShowCompleted(isToggleOn);

  const initialConsultItems = filterTasksToDisplay(
    activeInitialConsultItems,
    showCompleted,
  );
  const followupItems = useMemo(() => {
    return sortTasksToDisplay(
      filterTasksToDisplay(activeFollowupItems, showCompleted),
      metadata,
      timezone,
    );
  }, [activeFollowupItems, showCompleted, timezone, metadata]);

  const todaysTasksFiltered = filterTasksToDisplay(
    todaysTasks.filter(
      ({ label }) => !SYSTEM_REMINDER_TASK_REASONS.includes(label as Reason),
    ),
    showCompleted,
  );
  const systemReminders = filterTasksToDisplay(
    todaysTasks.filter(({ label }) =>
      SYSTEM_REMINDER_TASK_REASONS.includes(label as Reason),
    ),
    showCompleted,
  );

  return (
    <div className={styles.tasks} data-testid={testId}>
      <Card
        boxTitle={Titles.TASKS_TITLE}
        toggleLabel="Show Completed"
        onToggle={handleCardToggle}
      >
        {enableTasksV2 && (
          <Accordion
            classes={{ titleHeader: styles.accordionTitle }}
            accordionTitle="Today's Tasks"
            testId="todays-tasks"
          >
            {todaysTasksFiltered.map((item) => (
              <div key={`new_row_${item?.id}`} className={styles.row}>
                <div key={`new_col_${item?.id}`} className={styles.col}>
                  <div>
                    <ThreeStateCheckbox
                      id={`checkbox_${item?.id}`}
                      label={displayTaskLabel(
                        item?.label ?? 'Unknown',
                        item?.relatedCareProviderName ?? '',
                      )}
                      defaultState={item.state}
                      testId={`checkbox_${item?.id}`}
                      enableTextStrikeThrough={true}
                      onIconClick={(state) => onUpdateTodaysTask(state, item)}
                      labelSize="xs"
                      disabled={updatingState}
                      priority={item.priority}
                    />
                  </div>
                </div>
              </div>
            ))}
          </Accordion>
        )}
        <Accordion accordionTitle="Conversation Follow-ups">
          <div className={styles.row}>
            <div className={styles.col} style={{ padding: '0' }}>
              <UncheckedCheckboxIcon stroke="#9E9E9E" />
              <InputBase
                name="new_label"
                id="new_label"
                className={styles.textarea}
                multiline={true}
                value={newLabel}
                placeholder="Type new talking point..."
                onChange={(e) => setNewLabel(e.target.value)}
                onKeyDown={handleKeyPress}
                onBlur={handleBlur}
              />
            </div>
          </div>
          {followupItems.map((followupItem) => {
            const { id, state } = followupItem;

            if (!showCompleted && !isUnChecked(state)) return null;
            return (
              <ActiveFollowupItem
                disabled={isSavingFollowup(id)}
                key={`new_row_${id}`}
                onCheckStateUpdate={(state, item) =>
                  onCheckStateUpdate(state, item)
                }
                onUpdate={onUpdateFollowup}
                item={followupItem}
                metadata={metadata[id]}
                showCreationDetails={true}
              />
            );
          })}
        </Accordion>

        {enableTasksV2 && (
          <Accordion
            accordionTitle="System Reminders"
            testId="system-reminders"
          >
            {systemReminders.map((item) => (
              <div key={`new_row_${item?.id}`} className={styles.row}>
                <div key={`new_col_${item?.id}`} className={styles.col}>
                  <div>
                    <ThreeStateCheckbox
                      id={`checkbox_${item?.id}`}
                      label={displayTaskLabel(item?.label ?? 'Unknown')}
                      testId={`checkbox_${item?.id}`}
                      enableTextStrikeThrough={true}
                      onIconClick={(state) => onUpdateTodaysTask(state, item)}
                      labelSize="xs"
                      disabled={updatingState}
                      defaultState={item.state}
                    />
                  </div>
                </div>
              </div>
            ))}
          </Accordion>
        )}
        {/* collapse initial-consult accordion of clinicians */}
        <Accordion
          accordionTitle="Discovery Session"
          isExpandedByDefault={!isClinicianOrSupervisor(role)}
        >
          {initialConsultItems.length > 0 && (
            <div key="init_row_all" className={styles.row}>
              <div
                key="init_col_all"
                className={styles.col}
                style={{
                  borderBottom: 'solid 1px #e9e9e9',
                  paddingBottom: '10px',
                }}
              >
                <div data-testid="check_all">
                  <ThreeStateCheckbox
                    id="init_all"
                    label="Check All"
                    defaultState={checkAllState}
                    enableTextStrikeThrough={false}
                    onIconClick={(state) => {
                      unifyItemStates(state);
                    }}
                    labelSize="xs"
                    disabled={updatingState || updatingAllInitialConsultState}
                  />
                </div>
              </div>
            </div>
          )}
          {initialConsultItems.map((initConsultItem) => {
            const { id, label, state } = initConsultItem;
            return (
              <TasksTooltip
                key={`init_tooltip_${id}`}
                metadata={metadata[id]}
                labelForAnalytics="Member Tasks: Initial Consult Item"
              >
                <div
                  data-testid={`initial-consult-${id}`}
                  key={`init_row_${id}`}
                  className={styles.row}
                >
                  <div key={`init_col_${id}`} className={styles.col}>
                    <div>
                      <ThreeStateCheckbox
                        onIconClick={(state) =>
                          onCheckStateUpdate(state, initConsultItem)
                        }
                        id={`init_${id}`}
                        label={label}
                        defaultState={state}
                        disabled={
                          updatingAllInitialConsultState ||
                          isSavingInitialConsult(id)
                        }
                      />
                    </div>
                  </div>
                </div>
              </TasksTooltip>
            );
          })}
        </Accordion>
      </Card>
    </div>
  );
}
