/* eslint sort-keys-fix/sort-keys-fix: 0 */
import { useLazyQuery } from '@apollo/client';
import { decodeBase64VaultItems } from '@ginger.io/vault-core';
import { KeyGenerator } from '@ginger.io/vault-core/dist/crypto';
import { Base64 } from '@ginger.io/vault-core/dist/crypto/Base64';
import {
  VaultItem,
  VaultItem_SchemaType as SchemaType,
  VaultItem_SchemaType,
  vaultItem_SchemaTypeToJSON,
} from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { getCoachingTeamGroupId } from '@ginger.io/vault-core/dist/IdHelpers';
import {
  CoachMemberTasksInitialConsultItem,
  CoachMemberTasksInitialConsultItem_Version,
} from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/member-tasks/CoachMemberTasksInitialConsultItem';
import {
  ItemState,
  itemStateToJSON,
} from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/member-tasks/ItemState';
import {
  MemberTasksFollowupItem,
  MemberTasksFollowupItem_Version,
} from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/member-tasks/MemberTasksFollowupItem';
import {
  PsychiatristMemberTasksInitialConsultItem,
  PsychiatristMemberTasksInitialConsultItem_Version,
} from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/member-tasks/PsychiatristMemberTasksInitialConsultItem';
import {
  TherapistMemberTasksInitialConsultItem,
  TherapistMemberTasksInitialConsultItem_Version,
} from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/member-tasks/TherapistMemberTasksInitialConsultItem';
import {
  createVaultItemInput,
  CreateVaultItemInputParams,
  updateVaultItemInput,
  UpdateVaultItemInputParams,
  VaultItemPermissions,
} from '@ginger.io/vault-ui';
import { CustomError } from 'app/appointments/errorUtils';
import {
  CreateMemberChartVaultItemsInBatch,
  CreateMemberChartVaultItemsInBatchVariables,
} from 'app/coach/member-chart/generated/CreateMemberChartVaultItemsInBatch';
import {
  DeleteMemberChartVaultItems,
  DeleteMemberChartVaultItemsVariables,
} from 'app/coach/member-chart/generated/DeleteMemberChartVaultItems';
import {
  GetAllTaskItemsForMe,
  GetAllTaskItemsForMe_getTasksForMember_dismissed as CareProviderTask,
  GetAllTaskItemsForMeVariables,
} from 'app/coach/member-chart/generated/GetAllTaskItemsForMe';
import {
  UpdateMemberChartVaultItemsInBatch,
  UpdateMemberChartVaultItemsInBatchVariables,
} from 'app/coach/member-chart/generated/UpdateMemberChartVaultItemsInBatch';
import {
  createVaultItemsInBatch,
  deleteVaultItems,
  getGroupId,
  getTasks,
  removeItemsFromCache,
  updateCache,
  updateVaultItemsInBatch,
} from 'app/coach/member-chart/queries';
import { GQL_TASK_CARD_POLLING_INTERVAL } from 'app/constants';
import {
  BulkUpdateCareProviderTask,
  BulkUpdateCareProviderTask_bulkUpdateCareProviderTask_results as BulkUpdateCareProviderTaskResult,
  BulkUpdateCareProviderTaskVariables,
} from 'app/member-chart-cards/tasks/generated/BulkUpdateCareProviderTask';
import { useAppState, useDispatch } from 'app/state';
import { setTaskRelatedMessages } from 'app/state/chat/actions';
import { COACH_TODAYS_INBOX_SECTIONS } from 'app/state/inbox/actionHandlers/constants';
import {
  refreshClinicianTodaysMemberList,
  refreshCoachTodaysMemberList,
} from 'app/state/inbox/actions';
import { useLogger } from 'app/state/log/useLogger';
import { error as triggerError } from 'app/state/request/actions';
import { refreshTodaysTaskCard } from 'app/state/tasks/actions';
import {
  isClinicianOrSupervisor,
  isCoachOrSupervisor,
  removeNullEnums,
} from 'utils';
import { formatTimestampWithTz } from 'utils/dateTime';
import { useStateSlice } from 'app/vault/hooks/utils';
import {
  BulkUpdateCareProviderTaskInput,
  CreateVaultItemInput,
  Status,
  UpdateVaultItemInput,
  UserRole,
  VaultItemSortField,
  VaultItemSortOrder,
} from 'generated/globalTypes';
import gql from 'graphql-tag';
import { TransientFeatureFlag, useFeatureFlags } from 'hooks/useFeatureFlags';
import { useMutationWithGlobalState as useMutation } from 'hooks/useMutationWithGlobalState';
import Messages from 'i18n/en/memberChartCard.json';
import moment from 'moment-timezone';
import { useEffect, useRef } from 'react';
import { isGraphQLAuthenticationError } from 'shared-components/error-state/utils';

import {
  coachItemTypeToStringMap,
  initialConsultLabelsByRole,
  psychiatristItemTypeToStringMap,
  therapistItemTypeToStringMap,
} from './labels';
import {
  InitialConsultItem,
  MemberTaskAmplitudeMetadata,
  Tags,
  TaskItem,
  TaskItemResponse,
  TaskItems,
  TodaysItem,
  UseTasksResult,
} from './types';

export const bulkUpdateCareProviderTaskMutation = gql`
  mutation BulkUpdateCareProviderTask(
    $input: BulkUpdateCareProviderTaskInput!
  ) {
    bulkUpdateCareProviderTask(input: $input) {
      results {
        ok
        error
        task {
          id
        }
      }
    }
  }
`;

type UseTask = {
  memberId: string;
  keyGenerator?: KeyGenerator;
  generateId?: () => string;
};
export default function useTasks({
  memberId,
  keyGenerator = new KeyGenerator(),
}: UseTask): UseTasksResult {
  const logger = useLogger();
  const dispatch = useDispatch();

  const enableTasksV2 = useFeatureFlags().transientFeatureFlags[
    TransientFeatureFlag.ENABLE_TASKS_V2
  ];

  const { role, userId, timezone } = useAppState(({ user }) => ({
    role: user.role!,
    userId: user.userId!,
    vaultUserId: user.vaultUserId!,
    timezone: user.timezone ?? 'UTC',
  }));

  const tasks = useCareProviderTasks(memberId);

  const [createVaultItemsFn] = useMutation<
    CreateMemberChartVaultItemsInBatch,
    CreateMemberChartVaultItemsInBatchVariables
  >(createVaultItemsInBatch, {
    update(cache, { data }) {
      if (!data) return;
      void getVariables({
        memberId,
        userId,
        role,
        timezone,
        includeCareProviderTasks: enableTasksV2,
      })
        .then((value) => updateCache(cache, data, value))
        .catch();
    },
  });

  const [updateVaultItemsFn] = useMutation<
    UpdateMemberChartVaultItemsInBatch,
    UpdateMemberChartVaultItemsInBatchVariables
  >(updateVaultItemsInBatch);

  const [deleteVaultItemsFn] = useMutation<
    DeleteMemberChartVaultItems,
    DeleteMemberChartVaultItemsVariables
  >(deleteVaultItems, {
    update(cache, { data }) {
      if (!data) return;
      void getVariables({
        memberId,
        userId,
        role,
        timezone,
        includeCareProviderTasks: enableTasksV2,
      })
        .then((value) => removeItemsFromCache(cache, data, value))
        .catch();
    },
  });

  const createTask = async (
    items: TaskItem[],
    options: { amplitudeMetadata?: MemberTaskAmplitudeMetadata } = {},
  ) => {
    let vaultItemInputs: CreateVaultItemInput[];
    try {
      vaultItemInputs = await Promise.all(
        items.map(async (item) => {
          const vaultItem: VaultItem = toVaultItem(role, item);
          const param: CreateVaultItemInputParams = {
            itemId: item.id,
            tags: Object.values(
              getTaskTags(
                memberId,
                userId,
                role,
                (item as any).schema ===
                  VaultItem_SchemaType.vault_member_chart_member_tasks_followup_item,
              ),
            ),
            vaultItem: vaultItem as any,
            groupsToShareWith: isCoachOrSupervisor(role)
              ? [getCoachingTeamGroupId(memberId)]
              : [],
            permissions: isCoachOrSupervisor(role)
              ? VaultItemPermissions.WritableByAll
              : VaultItemPermissions.Writable,
          };
          const input = await createVaultItemInput(
            param,
            keyGenerator,
            () => '',
            { hashItemIds: true },
          );
          return (input as unknown) as CreateVaultItemInput;
        }),
      );
    } catch (error) {
      logger.error(
        new Error('useTasks.createTask: Unable encode vault item inputs'),
        {
          error,
          memberId,
          items: items.map(({ id, schema, state, sourceVersion }) => ({
            id,
            schema: vaultItem_SchemaTypeToJSON(schema),
            state: state ? itemStateToJSON(state) : undefined,
            sourceVersion,
          })),
        },
      );
      dispatch(
        triggerError({ queryName: 'MemberTasks::createVaultItemInput', error }),
      );
      return [];
    }

    // since we are using useGlobalStateMutation the trigger function only return errors, data.
    // So there's no need for a try catch here.
    const { data } = await createVaultItemsFn(
      { input: vaultItemInputs },
      { amplitudeMetadata: options?.amplitudeMetadata },
    );
    const results = data?.createVaultItemsInBatch ?? [];

    // report all errors
    results.forEach(({ error }) => {
      if (error) {
        const { message, code } = error;
        dispatch(
          triggerError({
            queryName: code,
            error: new CustomError(message, code),
          }),
        );
      }
    });

    return results.map(({ id, success }) => ({ id, success }));
  };

  const updateTask = async (
    items: TaskItem[],
    options: { amplitudeMetadata?: MemberTaskAmplitudeMetadata } = {},
  ) => {
    let updateVaultItems: UpdateVaultItemInput[];
    try {
      updateVaultItems = await Promise.all(
        items.map(async (item) => {
          const vaultItem: VaultItem = toVaultItem(role, item);
          const param: UpdateVaultItemInputParams = {
            itemId: item.id,
            sourceVersion: item.sourceVersion,
            tags: Object.values(
              getTaskTags(
                memberId,
                userId,
                role,
                item.schema ===
                  VaultItem_SchemaType.vault_member_chart_member_tasks_followup_item,
              ),
            ),
            vaultItem: vaultItem as any,
            groupId: isCoachOrSupervisor(role)
              ? await Base64.hash(getCoachingTeamGroupId(memberId))
              : undefined,
            permissions: isCoachOrSupervisor(role)
              ? VaultItemPermissions.WritableByAll
              : VaultItemPermissions.Writable,
          };
          const input = await updateVaultItemInput(param, keyGenerator, {
            hashItemIds: false,
          });
          return (input as unknown) as UpdateVaultItemInput;
        }),
      );
    } catch (error) {
      logger.error(
        new Error('useTasks.updateTask: Unable to encode VaultItem inputs'),
        {
          error,
          memberId,
          items: items.map(({ id, schema, state, sourceVersion }) => ({
            id,
            schema: vaultItem_SchemaTypeToJSON(schema),
            state: state ? itemStateToJSON(state) : undefined,
            sourceVersion,
          })),
        },
      );
      dispatch(
        triggerError({ queryName: 'MemberTasks::updateVaultItemInput', error }),
      );
      return [];
    }

    const { data } = await updateVaultItemsFn(
      { input: updateVaultItems },
      { amplitudeMetadata: options?.amplitudeMetadata },
    );

    // report all errors
    const results = data?.updateVaultItemsInBatch ?? [];
    results.forEach(({ error }) => {
      if (error) {
        const { message, code } = error;
        dispatch(
          triggerError({
            queryName: code,
            error: new CustomError(message, code),
          }),
        );
      }
    });
    return results?.map(({ id, success }) => ({ id, success }));
  };

  const deleteTask = async (
    item: TaskItem,
    options: { amplitudeMetadata?: MemberTaskAmplitudeMetadata } = {},
  ) => {
    const { errors } = await deleteVaultItemsFn(
      {
        input: {
          groupId: isCoachOrSupervisor(role)
            ? await Base64.hash(getCoachingTeamGroupId(memberId))
            : undefined,
          items: [item.id],
        },
      },
      { amplitudeMetadata: options?.amplitudeMetadata },
    );
    return errors === undefined;
  };

  const [updateCareProviderTaskStatus] = useMutation<
    BulkUpdateCareProviderTask,
    BulkUpdateCareProviderTaskVariables
  >(bulkUpdateCareProviderTaskMutation);

  const onCheckTask = async (
    tasks: { taskId: string; checkboxState: number }[],
    options: { amplitudeMetadata?: MemberTaskAmplitudeMetadata } = {},
  ): Promise<BulkUpdateCareProviderTaskResult[]> => {
    const clinicalTaskStatusMap: { [key: number]: Status } = {
      1: Status.Completed,
      2: Status.Active,
      3: Status.Dismissed,
    };
    logger.info('useTasks.onCheckTask: Updating tasks', { tasks });
    const input: BulkUpdateCareProviderTaskInput = {
      tasks: tasks.map(({ taskId, checkboxState }) => ({
        id: taskId,
        status: clinicalTaskStatusMap[checkboxState],
      })),
    };

    const { data } = await updateCareProviderTaskStatus(
      { input },
      { amplitudeMetadata: options?.amplitudeMetadata },
    );

    if (isCoachOrSupervisor(role))
      dispatch(
        refreshCoachTodaysMemberList({ sections: COACH_TODAYS_INBOX_SECTIONS }),
      );
    if (isClinicianOrSupervisor(role))
      dispatch(refreshClinicianTodaysMemberList({}));
    dispatch(refreshTodaysTaskCard({ memberId }));
    return data?.bulkUpdateCareProviderTask?.results ?? [];
  };

  return {
    tasks,
    updateTask,
    createTask,
    deleteTask,
    onCheckTask,
    enableTasksV2,
  };
}

export function useCareProviderTasks(memberId: string) {
  const enableTasksV2 = useFeatureFlags().transientFeatureFlags[
    TransientFeatureFlag.ENABLE_TASKS_V2
  ];
  const logger = useLogger();
  const memberRef = useRef<string | null>(null);

  const dispatch = useDispatch();
  const { role, userId, timezone, vaultUserId } = useAppState(({ user }) => ({
    role: user.role!,
    userId: user.userId!,
    vaultUserId: user.vaultUserId!,
    timezone: user.timezone ?? 'UTC',
  }));
  const { state, setLoading, setError, setData } = useStateSlice<TaskItems>();

  const canAccessTasks =
    isCoachOrSupervisor(role) || isClinicianOrSupervisor(role);
  if (!canAccessTasks) {
    return state.current;
  }

  const [
    runInitQuery,
    { data, error, loading, called, startPolling, stopPolling },
  ] = useLazyQuery<GetAllTaskItemsForMe, GetAllTaskItemsForMeVariables>(
    getTasks,
  );

  useEffect(() => {
    memberRef.current = memberId;
    const run = async () => {
      setLoading();
      const variables = await getVariables({
        memberId,
        userId,
        role,
        timezone,
        includeCareProviderTasks: enableTasksV2,
      });
      if (memberRef.current !== memberId) return;
      const { data } = await runInitQuery({ variables });
      if (data) startPolling(GQL_TASK_CARD_POLLING_INTERVAL);
    };
    void run().catch((error) => {
      logger.error(
        new Error('useCareProviderTasks::Failed to poll for new Tasks'),
        { error },
      );
    });
    return () => {
      memberRef.current = null;
      stopPolling();
    };
  }, [memberId]);

  // we are using this effect to watch for changes in the query, so we can update the UI properly
  useEffect(() => {
    if (!called || loading) return;
    const run = async () => {
      if (isGraphQLAuthenticationError(error)) stopPolling();
      if (error && !data && memberRef.current === memberId) {
        setError(error);
      } else if (data && memberRef.current === memberId) {
        dispatch(setTaskRelatedMessages({ tasks: data.getTasksForMember }));
        const result = await decodeVaultItemsFn(data, {
          role,
          vaultUserId,
          timezone,
          userId,
          memberId,
        });
        setData(result);
      }
    };
    void run().catch((error) => {
      logger.error(
        new Error(
          'useCareProviderTasks::Failed to update UI after Task data changed',
        ),
        { error },
      );
    });
  }, [data, error, loading, called]);

  return state.current;
}

const decodeVaultItemsFn = async (
  data: GetAllTaskItemsForMe,
  authData: {
    role: UserRole;
    timezone: string;
    vaultUserId: string;
    userId: string;
    memberId: string;
  },
): Promise<TaskItems> => {
  const { role, vaultUserId, timezone, userId, memberId } = authData;
  const followupItems: TaskItemResponse[] = [];

  const psychiatristSchemaType =
    VaultItem_SchemaType.vault_member_chart_psychiatrist_member_tasks_initial_consult_item;
  const therapistSchemaType =
    VaultItem_SchemaType.vault_member_chart_therapist_member_tasks_initial_consult_item;
  const coachSchemaType =
    VaultItem_SchemaType.vault_member_chart_coach_member_tasks_initial_consult_item;
  const followupSchemaType =
    VaultItem_SchemaType.vault_member_chart_member_tasks_followup_item;

  const promises = data.getPaginatedVaultItemsByTag.items.map(
    async ({
      encryptedItem,
    }): Promise<[VaultItem_SchemaType, TaskItemResponse]> => {
      const {
        encryptedData,
        id,
        sourceVersion,
        updatedAt,
        createdAt,
        creator,
        firstVersionCreator,
      } = encryptedItem;
      const decodedItems = await decodeBase64VaultItems(
        [encryptedData.cipherText],
        {
          [psychiatristSchemaType]: PsychiatristMemberTasksInitialConsultItem,
          [therapistSchemaType]: TherapistMemberTasksInitialConsultItem,
          [coachSchemaType]: CoachMemberTasksInitialConsultItem,
          [followupSchemaType]: MemberTasksFollowupItem,
        },
      );

      let item: TaskItem | undefined;
      let schema;
      if (decodedItems[followupSchemaType].length > 0) {
        schema = followupSchemaType;
        const { label, state } = decodedItems[followupSchemaType][0];
        item = { id, label, state, sourceVersion, schema: followupSchemaType };
      } else if (decodedItems[psychiatristSchemaType].length > 0) {
        schema = psychiatristSchemaType;
        const { itemType, state } = decodedItems[psychiatristSchemaType][0];
        const label = psychiatristItemTypeToStringMap[itemType];
        item = {
          id,
          label,
          itemType,
          state,
          sourceVersion,
          schema: psychiatristSchemaType,
        };
      } else if (decodedItems[therapistSchemaType].length > 0) {
        schema = therapistSchemaType;
        const { itemType, state } = decodedItems[therapistSchemaType][0];
        const label = therapistItemTypeToStringMap[itemType];
        item = {
          id,
          label,
          itemType,
          state,
          sourceVersion,
          schema: therapistSchemaType,
        };
      } else if (decodedItems[coachSchemaType].length > 0) {
        schema = coachSchemaType;
        const { itemType, state } = decodedItems[coachSchemaType][0];
        const label = coachItemTypeToStringMap[itemType];
        item = {
          id,
          label,
          itemType,
          state,
          sourceVersion,
          schema: coachSchemaType,
        };
      }

      if (!item || !schema) throw new Error('Unable to decode vault items');

      const hashedVaultId = await Base64.hash(vaultUserId ?? '');
      return [
        schema,
        {
          item,
          sourceVersion,
          createdAt: formatTimestampWithTz(createdAt, timezone, true),
          updatedAt: updatedAt
            ? formatTimestampWithTz(updatedAt, timezone, true)
            : undefined,
          updatedBy:
            creator.id === hashedVaultId
              ? `You`
              : `${creator.firstName ?? ''} ${creator.lastName ?? ''}`.trim(),
          createdBy:
            firstVersionCreator.id === hashedVaultId
              ? `You`
              : `${firstVersionCreator.firstName ?? ''} ${
                  firstVersionCreator.lastName ?? ''
                }`.trim(),
        },
      ];
    },
  );

  const results = await Promise.all(promises);
  const initialConsult: Record<number, string> =
    initialConsultLabelsByRole[role];

  const initialConsultItemsMap: Record<
    string,
    TaskItemResponse
  > = Object.fromEntries(
    Object.entries(initialConsult)
      .filter(([key]) => parseInt(key) > 0) // ignore UNRECOGNIZED: -1 & undefined_*: 0 from list. since they're of no use
      .map(([key, value]) => {
        const itemType = parseInt(key);
        return [
          value,
          {
            item: {
              id: getInitialConsultId({ role, itemType, userId, memberId }),
              label: value,
              state: undefined, // set state to undefined since initial consult task are pre-defined and aren't saved in vault
              itemType,
              schema: isCoachOrSupervisor(role)
                ? coachSchemaType
                : role === UserRole.THERAPIST
                ? therapistSchemaType
                : psychiatristSchemaType,
            } as InitialConsultItem,
            createdBy: 'You',
            createdAt: formatTimestampWithTz(
              moment.tz().toISOString(),
              timezone,
            ),
          },
        ];
      }),
  );
  results.forEach(([type, item]) => {
    if (
      ![
        VaultItem_SchemaType.vault_member_chart_therapist_member_tasks_initial_consult_item,
        VaultItem_SchemaType.vault_member_chart_coach_member_tasks_initial_consult_item,
        VaultItem_SchemaType.vault_member_chart_psychiatrist_member_tasks_initial_consult_item,
        VaultItem_SchemaType.vault_member_chart_member_tasks_followup_item,
      ].includes(type)
    ) {
      return;
    }
    if (
      item.item.schema !==
      VaultItem_SchemaType.vault_member_chart_member_tasks_followup_item
    ) {
      initialConsultItemsMap[item.item.label] = item;
    } else {
      followupItems.push(item);
    }
  });
  const initialConsultItems: TaskItemResponse[] = Object.values(
    initialConsultItemsMap,
  ).sort(
    (a, b) =>
      (a.item as InitialConsultItem).itemType -
      (b.item as InitialConsultItem).itemType,
  );

  let todaysItems: TodaysItem[] = [];
  if (data.getTasksForMember) {
    const { active, completed, dismissed } = data.getTasksForMember ?? {
      active: [],
      dismissed: [],
      completed: [],
    };
    todaysItems = [
      [active, ItemState.unchecked],
      [completed, ItemState.checked],
      [dismissed, ItemState.not_applicable],
    ]
      .flatMap((_) => {
        const [tasks, state] = _ as [CareProviderTask[], ItemState];
        return (tasks ?? []).map((task) => {
          const result: TodaysItem = {
            id: task!.id,
            label: removeNullEnums(task!.reasonForCreation),
            priority: task!.priority as number,
            relatedCareProviderName: task?.relatedCareProviderName ?? '',
            state,
          };
          return result;
        });
      })
      .sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0));
  }
  return { followupItems, initialConsultItems, todaysItems };
};

export function toVaultItem(role: UserRole, data: TaskItem): VaultItem {
  if (
    data.schema === SchemaType.vault_member_chart_member_tasks_followup_item
  ) {
    return {
      schemaType: SchemaType.vault_member_chart_member_tasks_followup_item,
      data: MemberTasksFollowupItem.encode({
        state: data.state ?? ItemState.UNRECOGNIZED,
        label: data.label,
        version: MemberTasksFollowupItem_Version.v0,
      }).finish(),
    };
  }

  if (
    data.schema ===
    SchemaType.vault_member_chart_therapist_member_tasks_initial_consult_item
  ) {
    return {
      schemaType:
        SchemaType.vault_member_chart_therapist_member_tasks_initial_consult_item,
      data: TherapistMemberTasksInitialConsultItem.encode({
        state: data.state ?? ItemState.UNRECOGNIZED,
        itemType: data.itemType,
        version: TherapistMemberTasksInitialConsultItem_Version.v0,
      }).finish(),
    };
  }
  if (
    data.schema ===
    SchemaType.vault_member_chart_psychiatrist_member_tasks_initial_consult_item
  ) {
    return {
      schemaType:
        SchemaType.vault_member_chart_psychiatrist_member_tasks_initial_consult_item,
      data: PsychiatristMemberTasksInitialConsultItem.encode({
        state: data.state ?? ItemState.UNRECOGNIZED,
        itemType: data.itemType,
        version: PsychiatristMemberTasksInitialConsultItem_Version.v0,
      }).finish(),
    };
  }
  if (
    data.schema ===
    SchemaType.vault_member_chart_coach_member_tasks_initial_consult_item
  ) {
    return {
      schemaType:
        SchemaType.vault_member_chart_coach_member_tasks_initial_consult_item,
      data: CoachMemberTasksInitialConsultItem.encode({
        state: data.state ?? ItemState.UNRECOGNIZED,
        itemType: data.itemType,
        version: CoachMemberTasksInitialConsultItem_Version.v0,
      }).finish(),
    };
  }
  throw new Error();
}

/**
 * return object containing vault tags that enable us retrieve vault items in different scenario.
 *    byMember: This will retrieve all vault items for the given member
 *    byMemberAndRole: This will retrieve all vault items for the given member that was created by users of similar role
 *                     Since all coaches on a member’s care team will see the same Member Tasks card and can edit it
 *                     collaboratively for any other coach on the member’s care team to see.
 *    byMemberAndUser: This will retrieve vault items created by a user for a given member.
 *    byAuthor: This will retrieve vault items created by a user.
 *    byType: This enables us sort member tasks by type: initial consult or follow-up tasks
 * @param memberId {string}
 * @param userId {string}
 * @param role {UserRole}
 * @param followup {boolean}
 */
function getTaskTags(
  memberId: string,
  userId: string,
  role: UserRole,
  followup: boolean = false,
): Tags {
  return {
    byMemberAndRole: isClinicianOrSupervisor(role)
      ? `clinical-member-tasks-${memberId}`
      : `coach-member-tasks-${memberId}`,
    byMemberAndUser: `member-tasks-${memberId}-author-${userId}`,
    byMember: `member-tasks-${memberId}`,
    byAuthor: `member-tasks-author-${userId}`,
    byType: followup
      ? `member-tasks-follow-up-${memberId}-author-${userId}`
      : `member-tasks-initial-consult-member-${memberId}-author-${userId}`,
  };
}

export async function getVariables(props: {
  memberId: string;
  userId: string;
  role: UserRole;
  includeCareProviderTasks: boolean;
  timezone: string;
}): Promise<GetAllTaskItemsForMeVariables> {
  const { memberId, userId, role, includeCareProviderTasks, timezone } = props;
  const startDatetime = moment
    .tz(timezone ?? 'UTC')
    .startOf('day')
    .toISOString();
  const endDatetime = moment
    .tz(timezone ?? 'UTC')
    .endOf('day')
    .toISOString();
  const tags = getTaskTags(memberId, userId, role);
  let tag: string;
  if (isClinicianOrSupervisor(role)) {
    tag = await Base64.hash(tags.byMemberAndUser);
  } else if (isCoachOrSupervisor(role)) {
    tag = await Base64.hash(tags.byMemberAndRole);
  } else {
    throw new Error(Messages.unauthorizedToPerformTheOperation);
  }

  return {
    tag,
    groupId: await getGroupId(memberId, role),
    pagination: {
      cursor: null,
      maxItemsPerPage: 200,
      sortOrder: VaultItemSortOrder.DESC,
      sortField: VaultItemSortField.CREATED_AT,
    },
    clinicalTaskInput: { id: memberId },
    includeCareProviderTasks,
    dateRange: {
      endDatetime,
      startDatetime,
    },
  };
}

function getInitialConsultId({
  role,
  itemType,
  userId,
  memberId,
}: {
  role: UserRole;
  itemType: number;
  memberId: string;
  userId: string;
}) {
  if (isCoachOrSupervisor(role)) {
    return `member-chart-${memberId}-coach-member-tasks-initial-consult-${itemType}`;
  }
  return `member-chart-${memberId}-clinician-initial-consult-reminder-${itemType}-${userId}`;
}
