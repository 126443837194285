/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import { initConversationsInInbox } from 'app/state/inbox/actions';

export interface InboxV2State {
  newInboxState: string[];
  showInbox: boolean;
}

export const initialState: InboxV2State = {
  newInboxState: [],
  showInbox: false,
};

const inboxSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(initConversationsInInbox, (state, action) => {
      // TODO: temp example, replace
      state.newInboxState = action.payload.inboxItems.map((x) => x.id);
    });
  },
  initialState,
  name: 'inboxV2',
  reducers: {
    init(state) {
      state.newInboxState = [];
    },
    toggleInbox(state) {
      state.showInbox = !state.showInbox;
    },
  },
});

export const { init, toggleInbox } = inboxSlice.actions;
export default inboxSlice.reducer;
