import VideocamIcon from '@mui/icons-material/Videocam';
import { Button } from 'shared-components/button/Button';
import { useLogger } from 'app/state/log/useLogger';
import { formatZoomMeetingUrl } from 'utils';
import Messages from 'i18n/en/appointment.json';
import React, { useCallback } from 'react';

import styles from './ZoomButton.module.scss';

export type ZoomButtonProps = {
  meetingId: string;
  label: string;
  className?: string;
  appointmentId?: string;
  noteId?: string;
};

export const ZoomButton = (props: ZoomButtonProps) => {
  const { label, meetingId, className, appointmentId, noteId } = props;
  const logger = useLogger();

  const onClick = useCallback(() => {
    if (!meetingId) {
      logger.error(new Error(Messages.noZoomId), {
        appointmentId,
        noteId,
      });
      return;
    }

    logger.info(Messages.openZoomId, {
      appointmentId,
      meetingId,
      noteId,
    });

    window.open(
      formatZoomMeetingUrl(meetingId),
      '_blank',
      'noopener, noreferrer',
    );
  }, [meetingId, logger, appointmentId, noteId]);

  return (
    <Button
      testId={`zoombutton-${meetingId}`}
      className={className || styles.zoomLink}
      endIcon={<VideocamIcon />}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};
