import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from 'shared-components/Box';
import React, { Fragment } from 'react';
import { HeaderWithDot } from './HeaderWithDot';
import { Label } from './Label';

const Indicator = (props: { question: string; answer: boolean }) => {
  const icon = props.answer ? <Label active>Yes</Label> : <Label>No</Label>;
  return (
    <tr>
      <td>{props.question}</td>
      <td>{icon}</td>
    </tr>
  );
};

const Indicators = (props: {
  name: string;
  indicators: SelfReportedSymptom[];
}) => {
  const { name, indicators } = props;

  const isIndicatorActive = indicators.some((_) => _.answer === true);

  const indicatorComponents = indicators.map((indicator) => {
    const { question, answer } = indicator;
    return <Indicator key={question} question={question} answer={answer} />;
  });

  return (
    <Fragment>
      <Accordion elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <HeaderWithDot name={name} active={isIndicatorActive} />
        </AccordionSummary>
        <AccordionDetails>
          <table>
            <tbody>{indicatorComponents}</tbody>
          </table>
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
};

type SelfReportedSymptom = {
  question: string;
  answer: boolean;
};

export function SelfReportedSymptoms(props: {
  mania: SelfReportedSymptom[];
  psychosis: SelfReportedSymptom[];
  panicDisorder: SelfReportedSymptom[];
  socialOrPerformanceAnxiety: SelfReportedSymptom[];
  ocd: SelfReportedSymptom[];
  ptsd: SelfReportedSymptom[];
  somaticSymptomDisorder: SelfReportedSymptom[];
}) {
  return (
    <Box testId="selfReportedSymptom" title="Self Reported Symptoms">
      <Indicators name="Mania" indicators={props.mania} />
      <Indicators name="Psychosis" indicators={props.psychosis} />
      <Indicators name="Panic Disorder" indicators={props.panicDisorder} />
      <Indicators
        name="Social Or Performance Anxiety"
        indicators={props.socialOrPerformanceAnxiety}
      />
      <Indicators name="OCD" indicators={props.ocd} />
      <Indicators name="PTSD" indicators={props.ptsd} />
      <Indicators
        name="Somatic Symptom Disorder"
        indicators={props.somaticSymptomDisorder}
      />
    </Box>
  );
}
