import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from './baseQueryWithReauth';
import { ServerType } from './types';

// eslint-disable-next-line @typescript-eslint/ban-types -- temp, to be removed once we populate the endpoints
export const webApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getSections: builder.query({
      extraOptions: { server: ServerType.Web },
      query: (name: string) => `getSections/${name}`,
    }),
  }),
  reducerPath: 'webApi',
});
