import * as Sentry from '@sentry/react';
import { NoteAction } from 'app/appointments/types';
import { ApolloCachingStrategy } from 'app/constants';
import { TransientFeatureFlag, useFeatureFlags } from 'hooks/useFeatureFlags';
import { useOnMount } from 'hooks/useOnMount';
import { Routes } from 'app/top-nav/Routes';
import { SupervisorProfile } from 'app/member-support/SupervisorProfile';
import { SurveyTab } from 'app/patients/tabs/surveys/SurveysV3/utils';
import {
  GetMemberQueryWithCareTeam,
  GetMemberQueryWithCareTeam_getMember as Member,
  GetMemberQueryWithCareTeamVariables,
} from 'app/queries/generated/GetMemberQueryWithCareTeam';
import { SecureRoute } from 'app/routes/SecureRoute';
import { useAppState } from 'app/state';
import { useLogger } from 'app/state/log/useLogger';
import {
  CLINICIAN_OR_SUPERVISOR_USER_ROLE,
  isClinicalSupervisor,
  isClinicianOrSupervisor,
  SUPERVISOR_USER_ROLE,
} from 'utils';
import { UserRole } from 'generated/globalTypes';
import { useMemberChartIndicators } from 'hooks/useMemberChartIndicators';
import { useQuery } from 'hooks/useQuery';
import React, { Fragment } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { NavigationTabs, TabLink } from 'shared-components/tabs/NavigationTabs';

import { getMemberQueryWithCareTeam } from '../queries/GetMember';
import {
  GetPatient,
  GetPatient_getPatient,
  GetPatientVariables,
} from './generated/GetPatient';
import { MemberHeaderBar } from './header/MemberHeaderBar';
import styles from './PatientProfile.module.scss';
import { PatientProfile as PatientProfileV2 } from './PatientProfileV2';
import { getPatientsQuery } from './queries';
import {
  appointmentsRoute,
  appointmentsScheduleRoute,
  chatCollaborationRoute,
  demographicsRoute,
  documentsRoute,
  intakeRoute,
  notesRoute,
  prescriptionsRoute,
  summaryRoute,
  surveysRoute,
  surveysV3Route,
} from './Routes';
import { SurveyResponseRoutes } from './SurveyResponseRoutes';
import {
  AppointmentsTab,
  UILocation,
} from './tabs/appointments/AppointmentsTab';
import { AppointmentsTabSchedule } from './tabs/appointments/AppointmentsTabSchedule';
import ChatCollaboration from './tabs/chat-collaboration/ChatCollaboration';
import DocumentUpload from './tabs/document-upload/DocumentUpload';
import { DependentIntakeTab } from './tabs/intake/DependentIntakeTab';
import { IntakeTab } from './tabs/intake/IntakeTab';
import { NotesTabContainer } from './tabs/notes/NotesTabContainer';
import Prescriptions from './tabs/prescriptions/Prescriptions';
import { SummaryTab } from './tabs/summary/SummaryTab';
import { SurveysTab } from './tabs/surveys/SurveysTab';

export type Props = {
  selectedPatientId: string;
  pathPrefix: string;
  member: Member | null;
};

const Profile = (props: {
  patient: GetPatient_getPatient;
  member: Member;
  pathPrefix: string;
}) => {
  const {
    id,
    chart,
    surveyResponses: surveyResponsesDeprecated,
    referralNote,
    isMinorDependent,
    intakeCompletedAt,
    preferredPharmacy,
    careTeam,
    address: addressDeprecated,
    emergencyContact: emergencyContactDeprecated,
  } = props.patient;
  const {
    id: memberId,
    preferredFirstName,
    preferredLastName,
    preferences,
    timezone,
    gender: memberGender,
    genderIdentification,
    ethnicities,
    pronouns,
    dateOfBirth,
    firstName,
    lastName,
    email,
    guardianRelationship,
    phone,
    insurance,
    coverageDetails,
    emergencyContact,
    dosespotId,
  } = props.member;
  const { pathPrefix } = props;
  const logger = useLogger();

  const history = useHistory();
  const SentryRoute = Sentry.withSentryRouting(Route);
  const enableSurveyScoresV3 = useFeatureFlags().transientFeatureFlags[
    TransientFeatureFlag.ENABLE_SURVEY_SCORES_V3
  ];

  const { userId, role } = useAppState((_) => _.user);
  const navigationTabLinks = useMemberChartNavigationLinks(
    id,
    props.pathPrefix,
  );
  const isSupervisorAndMSUI = pathPrefix.startsWith(Routes.MEMBERS);

  // TODO: remove after resolving https://app.asana.com/0/1200397707104446/1203270091955987.
  useOnMount(() => {
    // We log this only for clinicians because _any_ member they view should have emergency contact info due to having
    // filled out the clinical intake, whereas MS (who also can view this page) can also view members who are engaged
    // only in coaching and have not filled out a clinical intake.
    if (isClinicianOrSupervisor(role) && !emergencyContactDeprecated?.phone) {
      let message = `[PatientProfile.tsx] Member ${memberId}, viewed by user ${userId}, is missing emergencyContactDeprecated`;

      if (emergencyContact && emergencyContact.phone) {
        message = `${message} but has emergencyContact (non-deprecated).`;
      }
      logger.info(`Profile: ${message}`);
    }
  });

  return (
    <Fragment>
      <nav className={styles.nav}>
        <NavigationTabs className={styles.navTab} links={navigationTabLinks} />
      </nav>

      <SentryRoute
        exact={true}
        path={pathPrefix}
        render={() => {
          if (role === UserRole.MEMBER_SUPPORT) {
            return <Redirect to={demographicsRoute(pathPrefix, id)} />;
          }
          return <Redirect to={summaryRoute(pathPrefix, id)} />;
        }}
      />

      <SentryRoute
        path={summaryRoute(pathPrefix, id)}
        render={() => (
          <SummaryTab
            data-testid="summaryTab"
            id={memberId}
            insurance={insurance}
            coverageDetails={coverageDetails}
            preferredPharmacy={preferredPharmacy}
            careTeam={careTeam}
            preferredFirstName={preferredFirstName}
            preferredLastName={preferredLastName}
            preferences={preferences}
            timezone={timezone}
            gender={memberGender}
            genderIdentification={genderIdentification}
            ethnicities={ethnicities}
            pronouns={pronouns}
            dateOfBirth={dateOfBirth}
            firstName={firstName}
            lastName={lastName}
            email={email}
            phone={phone}
            // TODO: we will get the address and emergencyContact from ContactInfoCardFragment once the following
            //  ticket is completed: https://app.asana.com/0/1201082158239874/1202779579454961.
            address={addressDeprecated}
            emergencyContact={emergencyContactDeprecated}
            isSupervisorAndMSUI={isSupervisorAndMSUI}
            guardianRelationship={guardianRelationship}
            dosespotId={dosespotId}
            intakeCompletedAt={intakeCompletedAt}
          />
        )}
      />

      <SentryRoute
        path={intakeRoute(pathPrefix, id)}
        render={() => {
          if (isMinorDependent) {
            return <DependentIntakeTab patientId={id} />;
          }
          return (
            <IntakeTab
              data-testid="intakeTab"
              chart={chart}
              surveyResponses={surveyResponsesDeprecated}
              referralNote={referralNote}
              intakeCompletedAt={intakeCompletedAt}
              memberId={memberId}
            />
          );
        }}
      />

      {/*  Making this a SentryRoute causes test failures with the old renderer */}
      <Route
        exact={true}
        path={appointmentsRoute(pathPrefix, id)}
        render={() => (
          <AppointmentsTab
            data-testid="appointments"
            uiLocation={
              isSupervisorAndMSUI ? UILocation.MS : UILocation.CLINICIAN
            }
            navigateTo={(url) => history.push(url)}
            patientId={id}
            signedLockedAction={
              isSupervisorAndMSUI ? NoteAction.DOWNLOAD : NoteAction.VIEW
            }
          />
        )}
      />

      {/*  Making this a SentryRoute causes test failures with the old renderer */}
      <Route
        exact={true}
        path={appointmentsScheduleRoute(pathPrefix, id)}
        render={() => (
          <AppointmentsTabSchedule
            data-testid="appointmentsSchedule"
            navigateTo={(url) => history.push(url)}
            patientId={id}
            signedLockedAction={
              isSupervisorAndMSUI ? NoteAction.DOWNLOAD : NoteAction.VIEW
            }
          />
        )}
      />

      <SecureRoute
        exact={true}
        path={notesRoute(pathPrefix, id)}
        isVaultRequired={true}
        permittedRoles={CLINICIAN_OR_SUPERVISOR_USER_ROLE}
        component={() => (
          <NotesTabContainer
            signedLockedAction={
              isSupervisorAndMSUI ? NoteAction.DOWNLOAD : NoteAction.VIEW
            }
            navigateTo={(url) => history.push(url)}
            patientId={id}
          />
        )}
      />

      <SecureRoute
        exact={true}
        path={chatCollaborationRoute(pathPrefix, id)}
        isVaultRequired={true}
        requiresFeatureFlag={TransientFeatureFlag.CHAT_COLLABORATION}
        permittedRoles={CLINICIAN_OR_SUPERVISOR_USER_ROLE}
        component={() => <ChatCollaboration memberId={id} />}
      />

      <SecureRoute
        exact={true}
        path={demographicsRoute(pathPrefix, id)}
        isVaultRequired={true}
        permittedRoles={[...SUPERVISOR_USER_ROLE, UserRole.MEMBER_SUPPORT]}
        component={() => <SupervisorProfile memberId={id} />}
      />

      <SecureRoute
        exact={true}
        path={documentsRoute(pathPrefix, id)}
        requiresFeatureFlag={TransientFeatureFlag.ENABLE_DOCUMENT_UPLOAD}
        isVaultRequired={true}
        permittedRoles={[
          ...CLINICIAN_OR_SUPERVISOR_USER_ROLE,
          UserRole.MEMBER_SUPPORT,
        ]}
        component={() => <DocumentUpload memberId={id} />}
      />

      <SecureRoute
        exact={true}
        path={prescriptionsRoute(pathPrefix, id)}
        isVaultRequired={true}
        permittedRoles={CLINICIAN_OR_SUPERVISOR_USER_ROLE}
        component={() => <Prescriptions memberId={id} />}
      />

      <SentryRoute
        exact={true}
        path={surveysRoute(pathPrefix, id)}
        render={() => {
          if (enableSurveyScoresV3) {
            return (
              <Redirect to={surveysV3Route(pathPrefix, id, SurveyTab.ALL)} />
            );
          }
          return <SurveysTab surveyResponses={surveyResponsesDeprecated} />;
        }}
      />

      <SurveyResponseRoutes
        id={id}
        pathPrefix={pathPrefix}
        surveyResponsesDeprecated={surveyResponsesDeprecated}
      />
    </Fragment>
  );
};

export function PatientProfile(props: Props) {
  return useQuery<GetPatient, GetPatientVariables>(
    (data) => {
      if (!data.getPatient || !props.member) return <></>;
      return (
        <Profile
          data-testid="patientProfile"
          patient={data.getPatient}
          member={props.member}
          pathPrefix={props.pathPrefix}
        />
      );
    },
    getPatientsQuery,
    {
      errorPolicy: 'all',
      variables: { patientId: props.selectedPatientId }, // allows rendering partial data if some graphQL fields error out
    },
  );
}

export function PatientProfileWrapper(props: {
  patientId: string;
  pathPrefix?: string;
  className?: string;
  enableV2?: boolean;
}) {
  const {
    patientId,
    className,
    pathPrefix = Routes.PATIENTS,
    enableV2 = false,
  } = props;

  return useQuery<
    GetMemberQueryWithCareTeam,
    GetMemberQueryWithCareTeamVariables
  >(
    (data) => (
      <>
        <MemberHeaderBar memberId={patientId} />
        <div className={className}>
          {enableV2 ? (
            <PatientProfileV2
              member={data.getMember}
              selectedPatientId={patientId}
              pathPrefix={pathPrefix}
            />
          ) : (
            <PatientProfile
              member={data.getMember}
              selectedPatientId={patientId}
              pathPrefix={pathPrefix}
            />
          )}
        </div>
      </>
    ),
    getMemberQueryWithCareTeam,
    {
      fetchPolicy: ApolloCachingStrategy.CACHE_FIRST,
      variables: { input: { id: patientId } },
    },
  );
}

function useMemberChartNavigationLinks(id: string, pathPrefix: string) {
  const {
    enable_out_of_session_and_termination_notes: newApptTabUIEnabled,
  } = useFeatureFlags().transientFeatureFlags;

  const showChatCollaboration = useFeatureFlags().transientFeatureFlags[
    TransientFeatureFlag.CHAT_COLLABORATION
  ];
  const enableNewAppointmentsTabForUser = useFeatureFlags()
    .transientFeatureFlags[
    TransientFeatureFlag.ENABLE_NEW_APPOINTMENTS_TAB_FOR_USER
  ];
  const enableDocumentUpload = useFeatureFlags().transientFeatureFlags[
    TransientFeatureFlag.ENABLE_DOCUMENT_UPLOAD
  ];
  const { role } = useAppState((_) => _.user);
  const {
    showIntakeIndicator,
    showSurveyScoreIndicator,
  } = useMemberChartIndicators(id);

  const navigationTabLinks: TabLink[] = [
    { label: 'Summary', path: summaryRoute(pathPrefix, id) },
    {
      isNotificationVisible: showIntakeIndicator,
      label: 'Intake',
      path: intakeRoute(pathPrefix, id),
    },
    {
      isNotificationVisible: showSurveyScoreIndicator,
      label: 'Survey Scores',
      path: surveysRoute(pathPrefix, id),
    },
  ];

  // hide old appointments tab if fs is active
  if (!enableNewAppointmentsTabForUser) {
    navigationTabLinks.push({
      isNewFeature: false,
      label: 'Appointments',
      path: appointmentsRoute(pathPrefix, id),
    });
  }

  navigationTabLinks.push({
    isNewFeature: false,
    label: 'Appointments',
    path: appointmentsScheduleRoute(pathPrefix, id),
  });

  if (showChatCollaboration) {
    navigationTabLinks.push({
      isNewFeature: false,
      label: 'Collaboration',
      path: chatCollaborationRoute(pathPrefix, id),
    });
  }

  if (newApptTabUIEnabled) {
    navigationTabLinks.push({
      label: 'Notes',
      path: notesRoute(pathPrefix, id),
    });
  }

  const isSupervisorAndMSUI = pathPrefix.startsWith(Routes.MEMBERS);
  const _isSupervisor =
    (isClinicalSupervisor(role) || role === UserRole.MEMBER_SUPPORT) &&
    isSupervisorAndMSUI;
  if (_isSupervisor) {
    navigationTabLinks.push({
      label: 'Demographics',
      path: demographicsRoute(pathPrefix, id),
    });
  }

  if (enableDocumentUpload) {
    navigationTabLinks.push({
      isNewFeature: false,
      label: 'Documents',
      path: documentsRoute(pathPrefix, id),
    });
  }

  navigationTabLinks.push({
    isNewFeature: true,
    label: 'Prescriptions',
    path: prescriptionsRoute(pathPrefix, id),
  });

  return navigationTabLinks;
}
