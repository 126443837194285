import { HomeworkType } from 'app/patients/tabs/content/types';
import {
  FetchMessagesResponse,
  PublishParameters,
  PublishResponse,
  SubscribeParameters,
} from 'pubnub';

import { TimetokensMap, UpdateUnreadMessagesPayload } from '../chat/types';

export interface AbstractPubNubMessagEnvelope {
  channel: string;
  messageType?: any;
  uuid?: string;
  timetoken: string | number;
  [key: string]: any;
}

export interface TextMessageEnvelope extends AbstractPubNubMessagEnvelope {
  message: TextMessage;
  out_of_session?: boolean;
}

export interface RPCEnvelope extends AbstractPubNubMessagEnvelope {
  message: RPC;
}

export type Envelope = RPCEnvelope | TextMessageEnvelope;

export interface AbstractMessage {
  senderType?: any;
  senderId?: string | number;
  id: string;
  username?: string;
  oncall_listener_id?: null;
  sender_tz?: string;
  [key: string]: any;
}

export interface RPC extends AbstractMessage {
  extra_params?: any;
  // we'll replace this w/ enum once we know all possible types of rpcs, for now it's any to avoid a TypeError
  rpc: any;
  server_originated?: boolean;
  volatile?: boolean;
}

export interface TextMessage extends AbstractMessage {
  message: string;
}

interface OutgoingMessage {
  senderType: 'listener';
  senderId: string;
  id: string;
  oncall_listener_id: null | string;
  username: string;
}
export interface TextMessageBeforePublishing extends OutgoingMessage {
  message: string;
}

export interface UpdateReadStateRPC extends OutgoingMessage {
  message_ids: string[];
  rpc: MemberActionRPC.UPDATE_READ_STATE;
}

export interface MessageRPC extends OutgoingMessage {
  rpc: AutoMessageRPC | ContentRPC;
  extra_params?: {
    [param: string]: string;
  };
  sender_tz?: string;
}

export interface TimeoutsMap {
  [messageId: string]: NodeJS.Timeout | null;
}

export type BuildMessage = ({
  input,
  senderId,
  username,
  id,
}: {
  input: string;
  senderId: string;
  username: string;
  id: string;
}) => TextMessageBeforePublishing;

export type PunNubUserCredentials = {
  uuid: string;
  id: number;
  username: string;
};

export interface UnreadByListenerMessageInfo {
  channelId: string;
  messageId: string;
}

export interface MessageToDisplay {
  message: string;
  timetoken: string;
  status: MessageStatus | null;
  type: MessageType;
  channel: string;
  id: string;
  contentData?: HomeworkType;
  containsRisk?: boolean;
}

export enum MessageStatus {
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  SEEN = 'SEEN',
  ERROR = 'ERROR',
}

// Pubnub PublishParameters interface says that message is of any type, so we apply a stricter rule
type PublishMessageParams = PublishParameters & { message: OutgoingMessage };

export type PublishMessage = (
  params: PublishMessageParams,
) => Promise<PublishResponse>;
export type SubscribeToChannels = (params: SubscribeParameters) => void;
export type GetHistory = (params: {
  channelIds: string[];
  start?: string;
}) => Promise<FetchMessagesResponse>;

export type PubNubAPI = {
  publishMessage: PublishMessage;
  subscribeToChannels: SubscribeToChannels;
  getHistory: GetHistory;
  unsubscribeAll: () => void;
};

export enum PUBNUB_STATUS {
  INSTANTIATED,
  ERROR,
  NOT_INSTANTIATED,
}

export interface PubnubInitConfig {
  subscribeKey: string;
  publishKey: string;
  pubnubAuthKey: string;
  listenerUuid: string;
  // by enabling this, we can receive additional information about errors coming from PubNub
  // allowing us to better communicate with PubNub support if needed
  logVerbosity: boolean;
}

// if pubnub has been properly instantiated it provides the API
export interface PubNubContextAPI {
  status: PUBNUB_STATUS.INSTANTIATED;
  api: PubNubAPI;
}

// if pubnub hasn't been properly instantiated, we don't need to hide the whole chat or inbox,
// we only need to tell the PubNubAPI consumers that they can't use it and we do it w/ the status prop
export interface PubNubContextError {
  status: PUBNUB_STATUS.ERROR;
  api: null;
}

export enum AutoMessageRPC {
  BOOKMARK_CREATED = 'bookmark_created',
  LINK_CLICKED = 'link_clicked',
  CLINICAL_INTEREST = 'clinical_interest',
  PROCESS_INTAKE_LINK = 'processing_intake_link',
}

export enum ContentRPC {
  GIO_DEEPLINK_BUTTON = 'gio_deeplink_button',
  GIO_DEEPLINK_CLICKED = 'gio_deeplink_clicked',
  GIO_DEEPLINK_UNASSIGNED = 'gio_deeplink_unassigned',
}

export const DisplayableRPCs = new Set(Object.values(AutoMessageRPC));

export enum MemberActionRPC {
  UPDATE_READ_STATE = 'update_read_state',
}

export enum ServerRPC {
  DIRTY_CONVERSATION = 'dirty_conversation',
  NEW_MESSAGE = 'new_message',
  NEW_RISK_ALERT_TASK = 'new_risk_alert_task',
  // RPC used to broadcast a shift_status change message from a coach (online/offline status)
  SHIFT_CHANGE = 'shift_change',
  CARE_TEAM_CHANGE = 'care_team_change',
  NEW_CONVERSATION = 'new_conversation',
  SCHEDULING_CHANGE = 'scheduling_change',
}

export enum IgnoredRPCs {
  INBOX_STATE_CHANGED = 'inbox_state_changed',
  FORCE_REFRESH = 'force_refresh',
  FORCE_CONFIG = 'force_config',
  CLIENT_RELOAD = 'client_reload',
}

export type ChatRPC = AutoMessageRPC | MemberActionRPC | ServerRPC;

export enum MessageType {
  TEXT_FROM_MEMBER,
  TEXT_FROM_LISTENER,
  RPC,
  AUTO_MESSAGE,
  CONTENT_MESSAGE,
  OUT_OF_SESSION,
}

export enum SenderType {
  LISTENER = 'listener',
  CALLER = 'caller',
}

export type MessagesBeforeCleanup = ValidMessageObject | InvalidMessageObject;

export type ValidMessageObject = { message: MessageToDisplay; error: null };

export type InvalidMessageObject =
  | { error: Error; message: null }
  | { error: null; message: null };

// a key represents pubnub channel id
export interface RefetchHistoryActionPayload {
  convosMapForRefetching: Record<string, DataForRefetchingHistory>;
  channelsToUpdateUnreadMessagesCount: UpdateUnreadMessagesPayload;
}

export interface DataForRefetchingHistory {
  hasLastMemberReadChanged: boolean;
  lastMemberReadTimeToken: string | null | undefined;
}

export type InsertNewMessagePayload = Array<{
  newMessagesFromRefetch: MessageToDisplay[];
  lastMemberReadTimeToken: string | null | undefined;
  channel: string;
}>;

export interface ChannelsToUpdateState {
  channelsToUpdateStatuses: Array<{
    lastMemberReadTimeToken: string | null | undefined;
    channel: string;
  }>;
  channelsToInsertNewMessages: InsertNewMessagePayload;
}

export interface UpdateConvosAfterRefetchPayload {
  RPC: ServerRPC;
  timetokensMap: TimetokensMap;
  memberIds: string[];
}

export interface MessagesMap {
  [channelId: string]: MessageToDisplay[];
}
