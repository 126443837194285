import { ConversationStateMap } from 'app/coach/chat/types';
import {
  RPCEnvelope,
  ServerRPC,
  TextMessageEnvelope,
} from 'app/coach/pubnub/types';
import { isGraphQLAuthenticationError } from 'shared-components/error-state/utils';
import { getCoachTodaysMembers } from 'app/inbox/queries';
import { InboxItem, InboxItemState, InboxSections } from 'app/inbox/types';
import {
  COACH_INBOX_MAX_ITEMS_PER_PAGE,
  getCoachTodaysInboxVariables,
} from 'app/inbox/utils';
import { setShouldPlayNotificationSound } from 'app/state/chat/actions';
import { updateSessionState } from 'app/state/features/auth/authSlice';
import { SessionState } from 'app/state/features/auth/types';
import { State as InboxState } from 'app/state/inbox/schema';
import { ILogger } from 'app/state/log/Logger';
import { State } from 'app/state/schema';
import { batch } from 'react-redux';
import { Dispatch, MiddlewareAPI } from 'redux';

import {
  initConversationsInInbox,
  maybeUpdateConversationsState,
  refreshCoachTodaysMemberList,
  refreshTodaysInboxTotalCount,
  setInboxRefreshInProgress,
  triggerPubnubChannelSubscription,
  updateConversationsBasedOnRefetch,
  updateInboxItems,
  updateTabSection,
} from '../actions';
import {
  reduceConversationStatsToConversationStateMap,
  uniqueArrayList,
} from '../utils';
import { COACH_TODAYS_INBOX_SECTIONS } from './constants';
import { ActionHandler } from './types';

const REFRESH_ATTEMPT_BASE = 2;
const REFRESH_DELAY_MULTIPLIER = 600;
const MAX_REFRESH_ATTEMPTS = 3;

export const onRefreshCoachTodaysMemberList = async ({
  action,
  redux,
  context,
}: ActionHandler<{
  RPC?: ServerRPC;
  envelope?: TextMessageEnvelope | RPCEnvelope;
  sections: InboxSections[];
  memberIds?: string[];
  rpcSource?: string;
  loadMessageHistory?: boolean;
  refreshCount?: number;
  initialRetryDelay?: number;
  shouldRefreshAgain?: (
    tabs: InboxState['tabSections'],
    inboxItem: InboxItem[],
    refreshCount: number,
  ) => boolean;
}>) => {
  const { logger } = context.services;

  const getPaginationInput = (ids: Set<string>) => ({
    cursor: null,
    maxItemsPerPage:
      ids.size < COACH_INBOX_MAX_ITEMS_PER_PAGE
        ? COACH_INBOX_MAX_ITEMS_PER_PAGE
        : ids.size,
  });
  const { sections, RPC, memberIds, rpcSource } = action.payload;
  const {
    shouldRefreshAgain = (_: any) => false,
    loadMessageHistory = false,
    initialRetryDelay = REFRESH_DELAY_MULTIPLIER,
  } = action.payload;
  const {
    user: { userId: authUserId, timezone, role },
    inbox: { tabSections, isInboxRefreshing },
  } = redux.getState();

  // Check if the inbox is already refreshing to avoid duplicate calls
  if (isInboxRefreshing) {
    logger.debug('refreshCoachTodaysMemberList: preventing concurrent calls');
    return;
  }

  logger.debug(
    "refreshCoachTodaysMemberList: refreshing today's member list section: ",
    {
      RPC,
      memberIds,
      rpcSource,
      sections,
    },
  );

  try {
    redux.dispatch(setInboxRefreshInProgress({ inProgress: true }));
    const sectionSet = new Set(sections);
    const variables = getCoachTodaysInboxVariables(timezone ?? 'UTC', {
      includeActiveTasks: false,
      includeCompletedTasks: sectionSet.has(InboxSections.COMPLETED),
      includeConvo: sectionSet.has(InboxSections.CONVERSATIONS_AND_TASKS),
      includeRiskTasks: sectionSet.has(InboxSections.RISKS),
      includeScheduledCheckin: sectionSet.has(InboxSections.SCHEDULED_CHECKINS),
      openConvoPagination: getPaginationInput(
        tabSections[InboxSections.CONVERSATIONS_AND_TASKS].ids,
      ),
      riskTasksPagination: getPaginationInput(
        tabSections[InboxSections.RISKS].ids,
      ),
      scheduledCheckinPagination: getPaginationInput(
        tabSections[InboxSections.SCHEDULED_CHECKINS].ids,
      ),
    });
    const {
      response,
      riskAlertSection,
      scheduledSessionSection,
      completedSection,
      activeTasksSection,
      convosSection,
    } = await getCoachTodaysMembers({
      apollo: context.services.apollo,
      logger,
      timezone: timezone ?? 'UTC',
      variables,
    });

    const { errors } = response;

    if (errors) {
      logger.error(
        new Error(
          "refreshCoachTodaysMemberList: Received partial response while refreshing today's member list",
        ),
        {
          RPC,
          authUserId,
          errors,
          memberIds,
          role,
          rpcSource,
          sections,
        },
      );
    }

    let updatedInboxItems: InboxItem[] = [];

    const updatedTabSections = { ...tabSections };

    if (variables.includeConvo) {
      const items = [
        ...convosSection.items,
        ...(activeTasksSection?.items ?? []),
      ];
      const hasMore =
        convosSection.hasMore || (activeTasksSection?.hasMore ?? false);
      const cursor =
        (convosSection.hasMore
          ? convosSection.cursor
          : activeTasksSection?.cursor) ?? null;
      updatedTabSections[InboxSections.CONVERSATIONS_AND_TASKS] = {
        cursor,
        hasMore,
        ids: new Set(items.map((_) => _.id)),
      };
      updatedInboxItems = updatedInboxItems.concat(items);
    }
    if (variables.includeScheduledCheckin) {
      const {
        items: scheduledSessionsItem,
        cursor,
        hasMore,
      } = scheduledSessionSection;
      updatedTabSections[InboxSections.SCHEDULED_CHECKINS] = {
        cursor,
        hasMore,
        ids: new Set(scheduledSessionsItem.map((_) => _.id)),
      };
      updatedInboxItems = updatedInboxItems.concat(scheduledSessionsItem);
    }
    if (variables.includeRiskTasks) {
      const { items: riskTasksItem, cursor, hasMore } = riskAlertSection;
      updatedTabSections[InboxSections.RISKS] = {
        cursor,
        hasMore,
        ids: new Set(riskTasksItem.map((_) => _.id)),
      };
      updatedInboxItems = updatedInboxItems.concat(riskTasksItem);
    }
    if (variables.includeCompletedTasks) {
      const { items } = completedSection;
      updatedTabSections[InboxSections.COMPLETED] = {
        cursor: null,
        hasMore: false,
        ids: new Set(items.map((_) => _.id)),
      };
      updatedInboxItems = updatedInboxItems.concat(items);
    }
    const refreshCount = (action.payload.refreshCount ?? 0) + 1;

    // Determine if additional refreshes are needed, based on whether the member who sent the new message is
    // already displayed in the "Conversations and Tasks" section, and if the refresh count is within limits.
    if (
      shouldRefreshAgain(updatedTabSections, updatedInboxItems, refreshCount)
    ) {
      return setTimeout(() => {
        redux.dispatch(
          refreshCoachTodaysMemberList({ ...action.payload, refreshCount }),
        );
      }, REFRESH_ATTEMPT_BASE ** refreshCount * initialRetryDelay);
    }

    const newlyLoadedMemberIds: string[] = [];
    const itemsWithoutConvoStats: InboxItemState[] = [];
    const channelIds: string[] = [];
    const memberIdsWithMissingConversationStats: string[] = [];
    updatedInboxItems.forEach((item) => {
      const itemCopy = { ...item };
      const { memberCoachChannelId, id, conversationStats } = item;
      delete itemCopy.conversationStats;
      itemsWithoutConvoStats.push(itemCopy);

      if (!conversationStats || !memberCoachChannelId) {
        memberIdsWithMissingConversationStats.push(id);
      }

      if (memberCoachChannelId) channelIds.push(memberCoachChannelId);

      const isNew = COACH_TODAYS_INBOX_SECTIONS.every(
        (_) => !tabSections[_].ids.has(id),
      );
      if (isNew) newlyLoadedMemberIds.push(id);
    });

    // Missing conversationStats or memberCoachChannelId will result in PubNub not subscribing correctly.
    // Unread notifications and conversation movement in the inbox will occur, but new messages won't display.
    if (memberIdsWithMissingConversationStats.length > 0) {
      logger.warning(
        `refreshCoachTodaysMemberList: The following member ids have missing conversationStats
          or memberCoachChannelId: ${memberIdsWithMissingConversationStats.join(
            ', ',
          )}`,
      );
    }

    logger.debug('refreshCoachTodaysMemberList: refresh results', {
      RPC,
      memberIds,
      rpcSource,
      sections,
      tabSections,
      updatedTabSections,
    });

    batch(() => {
      redux.dispatch(updateTabSection({ tabSections: updatedTabSections }));
      redux.dispatch(updateInboxItems(itemsWithoutConvoStats));
      redux.dispatch(triggerPubnubChannelSubscription({ channelIds }));
      redux.dispatch(
        initConversationsInInbox({ inboxItems: updatedInboxItems }),
      );
      if (loadMessageHistory) {
        const ids = uniqueArrayList([
          ...newlyLoadedMemberIds,
          ...(memberIds ?? []),
        ]);
        const handle = maybeRefreshMessageHistory(
          redux,
          context.services.logger,
        );
        handle({
          RPC: RPC ?? ServerRPC.NEW_MESSAGE,
          inboxItems: updatedInboxItems,
          memberIds: ids,
          rpcSource,
          sections,
        });
      }
    });
    redux.dispatch(refreshTodaysInboxTotalCount({}));
  } catch (e) {
    if (isGraphQLAuthenticationError(e)) {
      redux.dispatch(updateSessionState(SessionState.EXPIRED));
    } else {
      logger.error(
        new Error(
          `refreshCoachTodaysMemberList: Unable to refresh today's member list`,
        ),
        {
          RPC,
          error: e,
          memberIds,
          rpcSource,
          sections,
        },
      );
    }
  } finally {
    redux.dispatch(setInboxRefreshInProgress({ inProgress: false }));
  }
};

export function maybeRefreshMessageHistory(
  redux: MiddlewareAPI<Dispatch, State>,
  logger: ILogger,
) {
  return (props: {
    memberIds: string[];
    inboxItems: InboxItem[];
    RPC: ServerRPC;
    sections?: InboxSections[];
    rpcSource?: string;
  }) => {
    const { timetokensMap } = redux.getState().conversationsTimetokens;
    const { sections, RPC, rpcSource, memberIds, inboxItems } = props;
    const notLoadedMembers: string[] = [];

    // The purpose of the "dirty conversation" RPC is to reload member info for a conversation that is currently
    // shown to the coach. If that member isn't loaded, we can safely ignore the "dirty conversation" RPC.
    if (notLoadedMembers.length > 0 && RPC !== ServerRPC.DIRTY_CONVERSATION) {
      logger.error(
        new Error(
          `Received the serverRPC with the member ids, but the updatedInboxItems do not have the conversation stats for some members`,
        ),
        {
          RPC,
          memberIds,
          missingIdsInboxState: notLoadedMembers,
          rpcSource,
          sections,
        },
      );
    }
    redux.dispatch(
      updateConversationsBasedOnRefetch({
        RPC,
        memberIds,
        timetokensMap,
      }),
    );
    // unconditionally call the maybeUpdateConversationsState: it compares the value inside and updates if necessary
    const fetchedConversationStateMap: ConversationStateMap = reduceConversationStatsToConversationStateMap(
      inboxItems.map((i) => i.conversationStats),
    );
    redux.dispatch(
      maybeUpdateConversationsState({
        updatesOfStateMap: fetchedConversationStateMap,
      }),
    );
  };
}

export function shouldRefreshAgain(params: {
  redux: MiddlewareAPI<Dispatch, State>;
  tabs: Record<
    InboxSections,
    {
      ids: Set<string>;
      hasMore?: boolean;
      cursor: string | null;
    }
  >;
  inboxItems: InboxItem[];
  channelId: string;
  refreshCount: number;
}) {
  const { redux, tabs, inboxItems, channelId, refreshCount } = params;
  // since we received an RPC from pubnub that a member sent the coach a new message,
  // we need ensure that the today's member query picks up the member id that sent the message.
  const inboxItem = inboxItems.find(
    (_) => _.memberCoachChannelId === channelId,
  );
  const isInConversationAndTasks =
    inboxItem && tabs.CONVERSATIONS_AND_TASKS.ids.has(inboxItem.id);
  if (isInConversationAndTasks) {
    redux.dispatch(setShouldPlayNotificationSound({ shouldPlaySound: true }));
  }
  return refreshCount < MAX_REFRESH_ATTEMPTS && !isInConversationAndTasks;
}
