import { ApolloClient, FetchPolicy } from '@apollo/client';
import { AuthState } from '@okta/okta-auth-js';
import { defaultNoteActions } from 'app/appointments/ClinicalNoteActionBar';
import { NoteAction } from 'app/appointments/types';
import { isGraphQLAuthenticationError } from 'shared-components/error-state/utils';
import {
  defaultFeatureFlags,
  FeatureFlags,
  TransientFeatureFlag,
} from 'hooks/useFeatureFlags';
import { Services } from 'app/services';
import { updateSessionState } from 'app/state/features/auth/authSlice';
import { SessionState } from 'app/state/features/auth/types';
import { getAuthenticatedUser } from 'app/state/generated/getAuthenticatedUser';
import { ILogger } from 'app/state/log/Logger';
import { hideOrShowSnackNotification } from 'app/state/notifications/actions';
import {
  isClinicalSupervisor,
  isClinicianOrSupervisor,
  isCoachOrSupervisor,
  oktaTokenExpirationDate,
} from 'utils';
import vaultTokenStorage from 'app/vault/VaultTokenStorage';
import { UserRole } from 'generated/globalTypes';
import gql from 'graphql-tag';
import Messages from 'i18n/en/state.json';
import { AnyAction, Dispatch, Store } from 'redux';

import { initializePubnub } from './chat/actions';
import { RootState } from './hooks/baseTypedHooks';
import { AuthenticatedUser, login, PubnubConfig } from './user/actions';

export const COACH_STATUS = gql`
  fragment CoachStatus on ListenerAuthenticatedUser {
    coach {
      id
      state {
        shiftStatus
      }
    }
  }
`;

/*
We check with Listener to get the timezone in case that the authenticated user is a Support Team user because their
timezone will be stored on the Listener model, whereas it will be available in Web for clinicians and supervisors.

We then choose the timezone from either Web or Listener—whichever is non-null.
 */
export const query = gql`
  ${COACH_STATUS}
  query getAuthenticatedUser {
    getWebAuthenticatedUser {
      userId
      role
      timezone
      clinicianId
      waffleFlagValues {
        name
        enabled
      }
    }
    getListenerAuthenticatedUser {
      listenerUserId
      timezone
      pubnubAuthKey
      listenerId
      coachinghubUsername
      pubnubConfig {
        authKey
        coachBroadcastChannelId
        rpcToCoachChannelId
        publishKey
        subscribeKey
      }
      ...CoachStatus
    }
  }
`;

export async function getAuthenticatedUserData(
  apollo: ApolloClient<any>,
  fetchPolicy: FetchPolicy = 'network-only',
): Promise<getAuthenticatedUser> {
  // it means: do not write anything to cache and do not look at it when querying, we need it to get up-to-data data which is especially important when having two clients
  const { data, error } = await apollo.query<getAuthenticatedUser>({
    fetchPolicy,
    query,
  });

  if (error || !data) {
    throw error ?? new Error(Messages.authenticationDataUnavailable);
  }

  return data;
}

// required to keep Okta state and Redux state in sync
export function syncAuthenticatedUserToRedux(
  store: Store<RootState, AnyAction>,
  services: Services,
  onComplete?: (featureFlags: FeatureFlags, appUser: AuthenticatedUser) => void,
) {
  const { okta, apollo, logger } = services;
  okta.authStateManager.subscribe(async (authState: AuthState) => {
    const { dispatch } = store;
    try {
      const { isAuthenticated, error } = authState;
      const oktaExpiration = oktaTokenExpirationDate(
        okta.getIdToken(),
      )?.toISOString();

      logger.info(
        'syncAuthenticatedUserToRedux::authStateChange: okta state change',
        {
          error,
          isAuthenticated,
          oktaExpiration,
        },
      );

      if (error) {
        handleError(error, dispatch, logger);
        return;
      }
      if (!isAuthenticated) return;

      // We can return early since we already sync user to redux. This is to prevent
      // rendering the clinical notes screen causing care-provider to lose note data.
      // https://github.com/HeadspaceMeditation/care-platform-web/pull/1340#discussion_r1089425350
      if (store.getState().user.userId) {
        const { user } = store.getState();
        logger.info(
          'syncAuthenticatedUserToRedux::authStateChange: user already synced to redux',
          {
            listenerId: user.listenerId,
            oktaExpiration,
            role: user.role,
            userId: user.userId,
          },
        );
        return;
      }

      const [user, data] = await Promise.all([
        okta.getUser(),
        getAuthenticatedUserData(apollo, 'network-only'),
      ]);
      const { listenerId } = data.getListenerAuthenticatedUser;

      const appUser = anAuthenticatedUser(data);
      const featureFlags = getFeatureFlags(data);
      dispatch(
        login({
          appUser,
          oktaUser: user,
          useVaultUserHeader:
            featureFlags.transientFeatureFlags.enable_vault_okta_auth,
        }),
      );

      const {
        enable_chat: enableChat,
        enable_carehub_pubnub_subscribe_wildcard: useWildCard,
        enable_carehub_debug_logger: enableCarehubDebugLogger,
      } = featureFlags.transientFeatureFlags;
      if (enableChat && isCoachOrSupervisor(appUser.role)) {
        const { pubnubConfig } = data.getListenerAuthenticatedUser;
        dispatch(
          initializePubnub({
            ...appUser.pubnubConfig,
            listenerId,
            logVerbosity: enableCarehubDebugLogger,
            publishKey: pubnubConfig?.publishKey ?? null,
            subscribeKey: pubnubConfig?.subscribeKey ?? null,
            useWildCard,
          }),
        );
      }
      logger.info(
        'syncAuthenticatedUserToRedux::authStateChange: successfully synced user to redux',
        {
          listenerId: appUser.listenerId,
          oktaExpiration,
          role: appUser.role,
          userId: appUser.userId,
        },
      );
      if (onComplete) onComplete(featureFlags, appUser);
    } catch (error) {
      handleError(error, dispatch, logger);
    }
  });
}

function getFeatureFlags(data: getAuthenticatedUser): FeatureFlags {
  const featureFlags = { ...defaultFeatureFlags };
  const { role, waffleFlagValues } = data.getWebAuthenticatedUser;

  if (isClinicalSupervisor(role) || role === UserRole.MEMBER_SUPPORT) {
    featureFlags.canViewMSAppointmentsTable = true;
  }

  if (isClinicianOrSupervisor(role)) {
    featureFlags.allowedNoteActions = defaultNoteActions;
    featureFlags.canViewPatients = true;
  } else if (role === UserRole.MEMBER_SUPPORT) {
    featureFlags.allowedNoteActions = new Set([NoteAction.DOWNLOAD]);
  }

  waffleFlagValues.forEach(({ name, enabled }) => {
    featureFlags.transientFeatureFlags[name as TransientFeatureFlag] = enabled;
  });

  if (
    isClinicalSupervisor(role) &&
    featureFlags.transientFeatureFlags[
      TransientFeatureFlag.ENABLE_SUPERVISOR_SIGN_AND_LOCK_NOTES_FOR_USER
    ]
  ) {
    featureFlags.allowedNoteActions.add(NoteAction.REVIEW_DRAFT);
  }

  return featureFlags;
}

function handleError(
  error: Error,
  dispatch: Dispatch<AnyAction>,
  logger: ILogger,
) {
  if (isGraphQLAuthenticationError(error)) {
    dispatch(updateSessionState(SessionState.EXPIRED));
  } else {
    logger.error(
      new Error(
        'syncAuthenticatedUserToRedux: error handling authenticated event',
        { cause: error },
      ),
      { error },
    );
    dispatch(
      hideOrShowSnackNotification({
        autoHide: false,
        dismissible: true,
        message:
          'An error occurred while loading your user data. Please try refreshing the page.',
        type: 'error',
      }),
    );
  }
}

function anAuthenticatedUser(data: getAuthenticatedUser): AuthenticatedUser {
  const { listenerId, pubnubConfig } = data.getListenerAuthenticatedUser;

  const config: PubnubConfig = {
    authKey: pubnubConfig?.authKey ?? null,
    coachBroadcastChannelId: pubnubConfig?.coachBroadcastChannelId ?? null,
    rpcToCoachChannelId: pubnubConfig?.rpcToCoachChannelId ?? null,
  };

  // Implementation note for future reference:

  // The role of a user starts first from the Okta user's "groups" claim. This is done by GingerGraphQL's
  // OktaJwt.ts's getUserRole(...). This "role" is then used by Web's (not Listener's) GingerJWTAuthentication
  // (which subclasses CustomBaseAuthentication). CustomBaseAuthentication's authenticate_user(role, ...) takes
  // that role from the Okta JWT and does some further mapping (mainly for the supervisor roles) to return the
  // FINAL role that is then stored into the Redux's "user" slice by this syncAuthenticatedUserToRedux().
  // Somewhere in there is an opportunity for some refactoring (or at least some documentation somewhere). Until
  // then, this will do for now.
  return {
    ...data.getWebAuthenticatedUser,
    coachinghubUsername: data.getListenerAuthenticatedUser.coachinghubUsername,
    listenerId,
    listenerUserId: data.getListenerAuthenticatedUser.listenerUserId,
    pubnubAuthKey: data.getListenerAuthenticatedUser.pubnubAuthKey,
    pubnubConfig: config,
    shiftStatus:
      data.getListenerAuthenticatedUser.coach?.state?.shiftStatus || null,
    timezone:
      data.getWebAuthenticatedUser.timezone ||
      data.getListenerAuthenticatedUser.timezone,
  };
}
