import { BenefitsCardFragment_coverageDetails as CoverageDetails } from 'app/member-chart-cards/benefits-card/generated/BenefitsCardFragment';
import {
  ClinicalCoverageExtent,
  CoverageType,
  ServiceCoveredType,
} from 'generated/globalTypes';
import React, { ReactElement, ReactNode } from 'react';
import { MarkdownContent } from 'shared-components/markdown-content/MarkdownContent';

import styles from './BenefitsCard.module.scss';

interface Props {
  children?: ReactNode;
}

export function formatOrgDetails(
  orgBenefits: string | null,
): ReactElement | null {
  if (!orgBenefits) {
    return null;
  }

  const H1 = ({ children, ...props }: Props) => <h1 {...props}>{children}</h1>;
  const H2 = ({ children, ...props }: Props) => <h2 {...props}>{children}</h2>;
  const Paragraph = ({ children, ...props }: Props) => (
    <p {...props}>{children}</p>
  );
  const CodeBlock = ({ children, ...props }: Props) => (
    <code {...props}>{children}</code>
  );
  const Link = ({ children, ...props }: Props) => (
    <a target="_blank" {...props}>
      {children}
    </a>
  );

  return (
    <MarkdownContent
      className={styles.orgDetails}
      overrides={{
        a: {
          component: Link,
          props: { className: styles.markdownLink },
        },
        code: {
          component: CodeBlock,
          props: { className: styles.markdownCodeBlock },
        },
        h1: {
          component: H1,
          props: { className: styles.markdownH1 },
        },
        h2: {
          component: H2,
          props: { className: styles.markdownH2 },
        },
        p: {
          component: Paragraph,
          props: { className: styles.markdownBodyText },
        },
      }}
    >
      {orgBenefits}
    </MarkdownContent>
  );
}

export function formatClinicalCoverage(
  servicesCovered: CoverageDetails['servicesCovered'] | undefined,
  totalSessionsCovered: number | null | undefined,
  sessionsRemaining: number | null | undefined,
): string {
  const servicesCoveredEnumVal = servicesCoveredToEnumVal(servicesCovered);

  if (servicesCoveredEnumVal === null) {
    return 'None';
  }

  const sessionCoverageLabel =
    servicesCoveredEnumVal === ServiceCoveredType.BOTH
      ? 'Total'
      : SERVICES_COVERED_LABELS[servicesCoveredEnumVal];
  const covered = `${
    totalSessionsCovered || 0
  } ${sessionCoverageLabel} Covered`;
  const remaining = `${sessionsRemaining || 0} Covered Remaining`;

  return `${covered}\n${remaining}`;
}

export function formatClinicalUsage(
  therapySessionsUsed: number | null | undefined,
  psychiatrySessionsUsed: number | null | undefined,
): string {
  const therapyUsage = `${therapySessionsUsed || 0} Therapy Used`;
  const psychUsage = `${psychiatrySessionsUsed || 0} Psychiatry Used`;
  return `${therapyUsage}\n${psychUsage}`;
}

export function formatServicesCovered(
  servicesCovered: (ServiceCoveredType | null)[],
): string | null {
  const services = servicesCovered.map(
    (service) => service && service.charAt(0) + service.slice(1).toLowerCase(),
  );
  const servicesString = services.filter(Boolean).join(' and ');

  if (!servicesString.length) {
    return null;
  }

  return servicesString;
}

const COVERAGE_TYPE_LABELS: Record<CoverageType, string> = {
  CLINICAL_PRE_PURCHASE: 'Clinical Pre-Purchase',
  EAP: 'EAP',
  SINGLE_FEE: 'Single Fee',
};

const CLINICAL_COVERAGE_EXTENT_LABELS: Record<
  ClinicalCoverageExtent,
  string
> = {
  CLINICAL_COVERED: 'Clinical Covered',
  CLINICAL_CUSTOM_NETWORK: 'Clinical Custom Network',
  CLINICAL_IN_NETWORK: 'Clinical In Network',
  CLINICAL_MIXED_NETWORK: 'Clinical Mixed Network',
  CLINICAL_OUT_OF_POCKET: 'Clinical Out of Pocket',
  CLINICAL_UNAVAILABLE: 'Clinical Unavailable',
};

const SERVICES_COVERED_LABELS: Record<ServiceCoveredType, string> = {
  BOTH: 'Both',
  PSYCHIATRY: 'Psychiatry',
  THERAPY: 'Therapy',
};

function servicesCoveredToEnumVal(
  servicesCovered?: CoverageDetails['servicesCovered'],
): ServiceCoveredType | null {
  const servicesCoveredSet = new Set((servicesCovered ?? []).filter(Boolean));

  if (
    servicesCoveredSet.has(ServiceCoveredType.THERAPY) &&
    servicesCoveredSet.has(ServiceCoveredType.PSYCHIATRY)
  ) {
    return ServiceCoveredType.BOTH;
  }
  if (servicesCoveredSet.has(ServiceCoveredType.THERAPY)) {
    return ServiceCoveredType.THERAPY;
  }
  if (servicesCoveredSet.has(ServiceCoveredType.PSYCHIATRY)) {
    return ServiceCoveredType.PSYCHIATRY;
  }

  return null;
}

export function formatCoverageType(
  coverageType?: CoverageType | null,
  clinicalCoverageExtent?: ClinicalCoverageExtent | null,
): string | null {
  if (coverageType) {
    return COVERAGE_TYPE_LABELS[coverageType];
  }

  if (clinicalCoverageExtent) {
    return CLINICAL_COVERAGE_EXTENT_LABELS[clinicalCoverageExtent];
  }

  return null;
}
