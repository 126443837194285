import gql from 'graphql-tag';

export const IntakeTabFragment = gql`
  fragment IntakeTabFragment on Member {
    chart {
      additionalInformationForClinician
      clinicalServicesSeeking
      currentMedications
      preExistingMedicalConditions
      reasonsForSeekingClinicalServices

      substanceConsumption
      frequencyOfSubstanceConsumption

      relationships {
        familyScore
        familyDetails
        spouseScore
        spouseDetails
        childrenScore
        childrenDetails
        hasChildren
        hasSpouse
      }

      riskKeywordsExtractedFromIntake

      thoughtsOfHarm
      reasonsForThoughtsOfHarm

      mentalIllnessIndicators {
        mania {
          ...indicatorFields
        }
        psychosis {
          ...indicatorFields
        }
        panicDisorder {
          ...indicatorFields
        }
        socialOrPerformanceAnxiety {
          ...indicatorFields
        }
        ocd {
          ...indicatorFields
        }
        ptsd {
          ...indicatorFields
        }
        somaticSymptomDisorder {
          ...indicatorFields
        }
      }

      religion {
        isMemberOfReligiousGroup
        description
      }
    }

    surveyResponses {
      phq9s {
        selfHarm
      }
    }

    referralNote {
      source
      serviceType
      note
    }
    intakeCompletedAt
  }

  fragment indicatorFields on MentalIllnessIndicator {
    question
    answer
  }
`;
