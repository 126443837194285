import { configureStore } from '@reduxjs/toolkit';
import inboxV2Reducer from 'app/inboxV2/state/inboxSlice';
import { Formatters, formatters, Services, services } from 'app/services';
import { listenerApi } from 'app/services/server/listenerApi';
import { webApi } from 'app/services/server/webApi';
import authReducer from 'app/state/features/auth/authSlice';
import {
  shallowEqual,
  useDispatch as useReduxDispatch,
  useSelector,
} from 'react-redux';
import { combineReducers } from 'redux';
import logger from 'redux-logger';
import { Action, createActionHandlerMiddleware } from 'redux-reloaded';

import { handlers as amplitudeActionHandlers } from './amplitude/actionHandlers';
import { handlers as careTeamActionHandlers } from './care-team/actionHandlers';
import { reducer as careTeamReducer } from './care-team/reducer';
import { handlers as chatActionHandlers } from './chat/actionHandlers';
import { reducer as chatReducer } from './chat/reducer';
import { reducer as coachNotesReducer } from './coach-notes/coachNotesSlice';
import { handlers as contentActionHandlers } from './content/actionHandlers';
import { reducer as contentReducer } from './content/reducer';
import timetokensReducer from './features/conversationTimetokens/conversationTimetokensSlice';
import persistedLayoutReducer from './features/persistedLayout/persistedLayoutSlice';
import { reducer as schedulerReducer } from './features/scheduler/schedulerSlice';
import { listenerLocalStorageMiddleware } from './hooks/baseTypedHooks';
import { handlers as inboxHandlers } from './inbox/actionHandlers/index';
import { reducer as inboxReducer } from './inbox/reducer';
import { handlers as logActionHandlers } from './log/actionHandlers';
import { handlers as memberTabsHandlers } from './member-tabs/actionHandlers';
import { reducer as multiTabReducer } from './member-tabs/reducer';
import { createRtkLoggerMiddleware } from './middlewares/loggerMiddleware';
import { cutOutError } from './middlewares/utils';
import { handlers as notificationActionHandlers } from './notifications/actionHandlers';
import { reducer as notificationReducer } from './notifications/reducer';
import { reducer as patientsReducer } from './patients/reducer';
import { requestStateReducer } from './request/reducer';
import { getInitialState, State } from './schema';
import { handlers as taskActionHandlers } from './tasks/actionHandlers';
import { handlers as timedSendsHandlers } from './timed-sends/actionHandlers';
import { reducer as timedSendsReducer } from './timed-sends/reducer';
import { reducer as userReducer } from './user/reducer';

function _shouldApplyDevTools() {
  if (process.env.REACT_APP_STAGE === 'PROD') {
    return false;
  }

  return true;
}

export const rootReducer = combineReducers({
  auth: authReducer,
  careTeam: careTeamReducer.handle,
  chat: chatReducer.handle,
  coachNotes: coachNotesReducer,
  content: contentReducer.handle,
  conversationsTimetokens: timetokensReducer,
  inbox: inboxReducer.handle,
  [listenerApi.reducerPath]: listenerApi.reducer,
  inboxV2: inboxV2Reducer,
  multiTab: multiTabReducer.handle,
  notification: notificationReducer.handle,
  patients: patientsReducer.handle,
  persistedLayout: persistedLayoutReducer,
  request: requestStateReducer.handle,
  scheduler: schedulerReducer,
  timedSends: timedSendsReducer.handle,
  user: userReducer.handle,
  [webApi.reducerPath]: webApi.reducer,
});

export const store = createReduxStore({
  enableReduxLogging: false,
  formatters,
  services,
});

export function createReduxStore(props: {
  services: Services;
  formatters: Formatters;
  enableReduxLogging?: boolean;
  initialState?: State;
}) {
  const {
    services,
    initialState = getInitialState(),
    enableReduxLogging = false,
  } = props;

  const store = configureStore({
    devTools: _shouldApplyDevTools(),
    middleware: (getDefaultMiddleware) => {
      const defaultMiddleware = getDefaultMiddleware({
        serializableCheck: false,
        thunk: {
          extraArgument: { formatters, services },
        },
      }).prepend(
        createRtkLoggerMiddleware(services.logger, cutOutError),
        listenerApi.middleware,
        webApi.middleware,
        listenerLocalStorageMiddleware.middleware,
      );
      const actionHandlers = [
        notificationActionHandlers,
        amplitudeActionHandlers,
        logActionHandlers,
        inboxHandlers,
        chatActionHandlers,
        taskActionHandlers,
        careTeamActionHandlers,
        memberTabsHandlers,
        timedSendsHandlers,
        contentActionHandlers,
      ];
      const actionHandlersMiddleware = createActionHandlerMiddleware(
        { services },
        actionHandlers,
      );

      if (enableReduxLogging) {
        return defaultMiddleware.concat(actionHandlersMiddleware, logger);
      }

      return defaultMiddleware.concat(actionHandlersMiddleware);
    },
    preloadedState: initialState,
    reducer: rootReducer,
  });

  return store;
}

/** Custom Hook that lets React components consume app state */
export function useAppState<T>(selector: (state: State) => T): T {
  // passing shallowEqual ensures we don't force a re-render if `selector`
  // returns a new object (i.e. breaks reference equality) with equal top-level properties
  return useSelector(selector, shallowEqual);
}

/** Custom Hook that lets React components dispatch actions to update the state.
 *  Note we wrap the Redux dispatch() function to provide type checking
 */
export function useDispatch<P>(): (action: Action<P>) => void {
  return useReduxDispatch();
}
