import { Gad7LineChart } from 'app/charts/Gad7LineChart';
import { Phq9LineChart } from 'app/charts/Phq9LineChart';
import { Box } from 'shared-components/Box';
import {
  DeprecatedMasonryLayout,
  MasonryItem,
  TABS_MASONRY_1_COLUMN_CONFIG,
} from 'app/masonry-layout/DeprecatedMasonryLayout';
import { viewSurveyTabAction } from 'app/state/amplitude/actions/surveys';
import { GraphQLProps } from 'app/typeUtils';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { SurveysTabFragment as SurveysTabData } from './generated/SurveysTabFragment';
import styles from './SurveysTab.module.scss';

export function SurveysTabV2(props: GraphQLProps<SurveysTabData>) {
  const { surveyResponses } = props;
  const phq9s = surveyResponses !== null ? surveyResponses.phq9s : [];
  const gad7s = surveyResponses !== null ? surveyResponses.gad7s : [];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewSurveyTabAction());
  }, [dispatch]);

  return (
    <div className={styles.tabContent}>
      <DeprecatedMasonryLayout
        breakPointCols={TABS_MASONRY_1_COLUMN_CONFIG.breakPointCols}
      >
        <MasonryItem>
          <Box>
            <h2>PHQ-9s</h2>
            <Phq9LineChart responses={phq9s} />
          </Box>
        </MasonryItem>

        <MasonryItem>
          <Box>
            <h2>GAD-7s</h2>
            <Gad7LineChart responses={gad7s} />
          </Box>
        </MasonryItem>
      </DeprecatedMasonryLayout>
    </div>
  );
}
