import {
  MessageStatus,
  MessageToDisplay,
  MessageType,
  TextMessageEnvelope,
} from 'app/coach/pubnub/types';
import { tryToHandleAsTextMessage } from 'app/coach/pubnub/utils';
import { InboxItemState, InboxSections } from 'app/inbox/types';
import {
  clearMessagePublishLatencyTimer,
  setShouldPlayNotificationSound,
} from 'app/state/chat/actions';
import { updateTimetokens } from 'app/state/features/conversationTimetokens/conversationTimetokensSlice';
import { ILogger } from 'app/state/log/Logger';
import { State } from 'app/state/schema';
import {
  ChatConversationState,
  ScheduledSessionType,
} from 'generated/globalTypes';
import { batch } from 'react-redux';
import { Dispatch, MiddlewareAPI } from 'redux';

import {
  addMessage,
  addUnreadByListenerMessage,
  makeSentMessageDelivered,
  refreshCoachTodaysMemberList,
  sortInboxSections,
  updateConversationState,
} from '../actions';
import {
  pubnubTimeTokenToMoment,
  redactSensitiveInfoFromPubhubData,
} from '../utils';
import { COACH_TODAYS_INBOX_SECTIONS } from './constants';
import { shouldRefreshAgain } from './onRefreshCoachTodaysMemberList';
import { ActionHandler } from './types';

export const onHandleTextMessage = async ({
  action,
  redux,
  context: { services },
}: ActionHandler<{ envelope: TextMessageEnvelope }>) => {
  const {
    payload: { envelope },
  } = action;
  const {
    chat: { publishTimeoutsMap },
    inbox: { channelIdMemberIdMap },
  } = redux.getState();

  services.logger.debug(`RPC::handleTextMessage`, {
    envelope: redactSensitiveInfoFromPubhubData(envelope),
  });
  const { message, error } = tryToHandleAsTextMessage(envelope);
  if (error) {
    const { channel, message } = envelope;
    services.logger.error(error, { channel, id: message?.id });
  } else if (message) {
    const channelId = message.channel;
    if (message.type === MessageType.TEXT_FROM_LISTENER) {
      /*
        If it has been sent via the listener chat we need to add the message w/ the delivered
        status (since it's appeared in a channel.
        If it has been sent via the new Care Hub chat - we need to clear the message timer
        and update its status instead of adding a new message ) */

      const memberId = channelIdMemberIdMap[channelId];

      const isMessageSentViaListenerChat =
        !publishTimeoutsMap || !(message.id in publishTimeoutsMap);

      services.logger.info('handleTextMessage:: processing text from coach', {
        channelId,
        memberId,
        messageId: message.id,
        messageTimestamp: pubnubTimeTokenToMoment(
          message.timetoken,
        ).toISOString(),
      });
      if (isMessageSentViaListenerChat) {
        redux.dispatch(
          addMessage({ ...message, status: MessageStatus.DELIVERED }),
        );
      } else {
        const {
          message: { id },
          timetoken,
        } = envelope;
        redux.dispatch(
          makeSentMessageDelivered({
            channelId,
            messageId: message.id,
            timetoken: timetoken.toString(),
          }),
        );
        redux.dispatch(clearMessagePublishLatencyTimer(id));
      }
    } else if (message.type === MessageType.TEXT_FROM_MEMBER) {
      handleTextMessageFromMember(redux, services.logger, message);
    } else {
      // at this point we know that this is a text message not from the listener/member,
      // it's either an AUTO_MESSAGE, CONTENT_MESSAGE, OUT_OF_SESSION. So we just add the message to the message history
      services.logger.info('handleTextMessage:: unknown message type', {
        channelId: message.channel,
        messageId: message.id,
        messageType: message.type,
      });
      redux.dispatch(addMessage(message));
    }
  }
};

export function shouldRefreshSessionType(
  inboxItem: InboxItemState,
  latestWriteTimestamp: string,
) {
  const {
    scheduledSessionType,
    todayScheduledSessionStartTime,
    todayScheduledSessionEndTime,
  } = inboxItem;

  if (!todayScheduledSessionStartTime || !todayScheduledSessionEndTime) {
    return false;
  }

  return scheduledSessionType === ScheduledSessionType.DROP_IN
    ? latestWriteTimestamp >= todayScheduledSessionStartTime &&
        latestWriteTimestamp <= todayScheduledSessionEndTime
    : latestWriteTimestamp < todayScheduledSessionStartTime ||
        latestWriteTimestamp > todayScheduledSessionEndTime;
}

export function handleInboxRefresh(
  redux: MiddlewareAPI<Dispatch, State>,
  message: MessageToDisplay,
) {
  const channelId = message.channel;
  return batch(() => {
    redux.dispatch(addMessage(message));
    redux.dispatch(
      refreshCoachTodaysMemberList({
        loadMessageHistory: true,
        refreshCount: 0,
        sections: COACH_TODAYS_INBOX_SECTIONS,
        shouldRefreshAgain(tabs, inboxItems, refreshCount) {
          return shouldRefreshAgain({
            channelId,
            inboxItems,
            redux,
            refreshCount,
            tabs,
          });
        },
      }),
    );
  });
}

export function handleTextMessageFromMember(
  redux: MiddlewareAPI<Dispatch, State>,
  logger: ILogger,
  message: MessageToDisplay,
) {
  const { inbox, conversationsTimetokens } = redux.getState();
  const { tabSections, inboxItems, channelIdMemberIdMap, messagesMap } = inbox;
  const { timetokensMap } = conversationsTimetokens;
  const channelId = message.channel;
  const memberId = channelIdMemberIdMap[channelId];
  const inboxItem = inboxItems[memberId];
  const isMemberInConversationsAndTasks =
    inboxItem && tabSections.CONVERSATIONS_AND_TASKS.ids.has(inboxItem.id);
  const isMemberInCompletedSection =
    inboxItem && tabSections.COMPLETED.ids.has(inboxItem.id);
  const isMemberInScheduledCheckinSection =
    inboxItem && tabSections.COMPLETED.ids.has(inboxItem.id);
  const additionalData = {
    channelId,
    inCompletedSection: isMemberInCompletedSection,
    inConversationsAndTasks: isMemberInConversationsAndTasks,
    inScheduledCheckSection: isMemberInScheduledCheckinSection,
    memberId,
    messageId: message.id,
    messageTimestamp: pubnubTimeTokenToMoment(message.timetoken).toISOString(),
  };
  logger.info(
    'handleTextMessageFromMember:: processing text from member',
    additionalData,
  );

  // The member is not in the 'Conversations and Tasks'. This usually means the conversation has either been closed,
  // or we received a message from a new member conversation
  if (!isMemberInConversationsAndTasks) {
    logger.info('handleTextMessageFromMember:: channel refreshing inbox', {
      channelId,
    });
    return handleInboxRefresh(redux, message);
  }

  batch(() => {
    const latestWriteTimestamp = pubnubTimeTokenToMoment(
      message.timetoken,
    ).toISOString();
    redux.dispatch(
      updateConversationState({
        channelId,
        updatedState: { state: ChatConversationState.OPEN },
      }),
    );
    redux.dispatch(
      updateTimetokens([
        {
          channelId,
          timetokens: {
            lastMemberWriteTimeToken: message.timetoken,
            latestWriteTimestamp,
          },
        },
      ]),
    );

    /*
      We've encountered situations where care-hub plays notification sound for messages that have already been read by
       the coach. This can happen in two scenarios (that I can think of):
      1. A message has already been pulled from history and displayed to the coach before it was received locally. 
        This occurs when there's a delay from pubnub, and the `usePollMessageHistory` logic detects it as a missed
        message, causing it to be displayed to the coach.  
      2. PubNub re-sends a text message from a member after the coach has already read it. This typically happens
        when the page is reloaded a few seconds after the message was initially received.
      
      Ideally in such cases, the message should not be counted as unread, nor should the notification sound be played.
      For now, we'll just log a warning message.
    */

    const lastListenerReadTimeToken =
      timetokensMap[channelId]?.lastListenerReadTimeToken ?? '0';
    if (lastListenerReadTimeToken > message.timetoken) {
      const messages = new Set((messagesMap[channelId] ?? []).map((m) => m.id));
      logger.warning(
        'handleTextMessageFromMember:: coach already read this message',
        { ...additionalData, alreadyInMessageList: messages.has(message.id) },
      );
    }
    redux.dispatch(
      addUnreadByListenerMessage({
        channelId,
        unreadMessage: message,
      }),
    );
    redux.dispatch(addMessage(message));
    redux.dispatch(setShouldPlayNotificationSound({ shouldPlaySound: true }));

    const { CONVERSATIONS_AND_TASKS } = InboxSections;
    redux.dispatch(sortInboxSections({ sections: [CONVERSATIONS_AND_TASKS] }));

    if (shouldRefreshSessionType(inboxItem, latestWriteTimestamp)) {
      redux.dispatch(
        refreshCoachTodaysMemberList({ sections: COACH_TODAYS_INBOX_SECTIONS }),
      );
    }
  });
}
