import { useQuery } from '@apollo/client';
import { MemberChartDataState } from 'app/inbox/multi-tabs/MemberChartDataState';
import { MultiTabMemberScreen } from 'app/inbox/multi-tabs/MultiTabMemberScreen';
import { useCoachStatus } from 'app/inbox/vertical-menu/useCoachStatus';
import { MemberHeaderBar } from 'app/patients/header/MemberHeaderBar';
import {
  GetMemberCoachingTeam,
  GetMemberCoachingTeam_getMember_coachingCareTeam as CoachingCareTeam,
  GetMemberCoachingTeamVariables,
} from 'app/queries/generated/GetMemberCoachingTeam';
import { GetMemberQueryWithCareTeam_getMember as Member } from 'app/queries/generated/GetMemberQueryWithCareTeam';
import { getMemberCoachingTeam } from 'app/queries/GetMember';
import { memberScreenViewed } from 'app/state/amplitude/actions/etc';
import { selectShowDrawer } from 'app/state/inbox/selectors';
import { useLogger } from 'app/state/log/useLogger';
import { selectUserRole } from 'app/state/user/selectors';
import { Routes, TopLevelRoutes } from 'app/top-nav/Routes';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useIdleTimeout } from 'hooks/useIdleTimeout';
import { useOnMount } from 'hooks/useOnMount';
import { useUpdateRiskAlertIndicator } from 'hooks/useUpdateRiskAlertIndicator';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import ErrorBoundary from 'shared-components/error-state/ErrorBoundary';
import { classNameCombiner } from 'utils';

import { ChatContainer } from './chat/ChatContainer';
import { CoachNotes } from './coach-notes/CoachNotes';
import styles from './CoachScreen.module.scss';
import { CoachNotesErrorState } from './error-state/CoachNotesErrorState';
import { MemberChart } from './member-chart/MemberChart';
import { summaryRoute } from './member-chart/Routes';

export const MemoizedMemberChartDataState = React.memo(
  ({ selectedMemberId }: { selectedMemberId: string }) => {
    const logger = useLogger();
    const { data, error } = useQuery<
      GetMemberCoachingTeam,
      GetMemberCoachingTeamVariables
    >(getMemberCoachingTeam, {
      variables: { input: { id: selectedMemberId } },
    });

    if (error) {
      logger.warning(
        'MemberChartDataState: Error fetching coaching care team',
        { error, memberId: selectedMemberId },
      );
    }

    return (
      <MemberChartDataState
        Component={({ member }) => (
          <MemberChartTabContent
            coachingCareTeam={data?.getMember?.coachingCareTeam ?? null}
            member={member}
          />
        )}
        selectedMemberId={selectedMemberId}
      />
    );
  },
);

const MemoizedMemberChart = React.memo(MemberChart);
const MemoizedCoachNotes = React.memo(CoachNotes);
const MemoizedMemberHeaderBar = React.memo(MemberHeaderBar);

function CoachScreen() {
  const history = useHistory();
  useCoachStatus({ enablePolling: true, fetchPolicy: 'network-only' });

  const reload = () => window.location.reload();

  // reload the page if there is a period of inactivity in order to re-connect to PubNub
  useIdleTimeout(reload);

  const navigateTo = (key: string | null) => {
    if (key !== null) {
      history.replace(summaryRoute(Routes.COACHING_MEMBERS, key));
    } else {
      history.replace(TopLevelRoutes.COACHING);
    }
  };
  return (
    <MultiTabMemberScreen
      paramKey="memberId"
      Component={MemoizedMemberChartDataState}
      navigateTo={navigateTo}
    />
  );
}
export function MemberChartTabContent(
  props: Readonly<{
    member: Member;
    coachingCareTeam: CoachingCareTeam | null;
  }>,
) {
  const { member, coachingCareTeam } = props;
  const { id: memberId } = member;

  const showDrawer = selectShowDrawer();
  const role = selectUserRole();

  const dispatch = useDispatch();

  useOnMount(() =>
    dispatch(memberScreenViewed({ pathname: window.location.pathname, role })),
  );
  useUpdateRiskAlertIndicator(memberId);

  const { enable_chat } = useFeatureFlags().transientFeatureFlags;
  const [isChatExpanded, setIsChatExpanded] = useState(false);
  const toggleChatExpansion = useCallback(() => {
    setIsChatExpanded((currentState) => !currentState);
  }, []);

  const firstName = member.preferredFirstName || member.firstName || '';
  const timezone = member.timezone ?? 'unavailable';
  const avatarSrc = member.profilePicture?.original || undefined;
  const lastName = member.preferredLastName || member.lastName || '';
  const callerId = member.callerId || '';

  /* each time any state changes in this component - there's a new ref to the memberProfile props since its an obj
  to avoid the chat re-renders each time the inbox is closed and opened, we memoize the memberProfileProps */
  const memoizedMemberProfile = useMemo(() => {
    return {
      baseProps: { avatarSrc, firstName, lastName },
      callerId,
      memberId,
      preferences: { timezone },
    };
  }, [firstName, avatarSrc, lastName, timezone, memberId, callerId]);

  return (
    <>
      <MemoizedMemberHeaderBar memberId={memberId} />
      <div className={styles.contentContainer}>
        <div
          className={classNameCombiner([
            styles.contentGrid,
            showDrawer ? styles.withOpenDrawer : '',
            isChatExpanded ? styles.expandedChatLayout : '',
          ])}
        >
          {enable_chat && (
            <div className={styles.contentGridElement}>
              <ChatContainer
                isChatExpanded={isChatExpanded}
                toggleChatExpansion={toggleChatExpansion}
                memberProfile={memoizedMemberProfile}
                coachingCareTeam={coachingCareTeam}
              />
            </div>
          )}

          <div className={styles.contentGridElement}>
            <ErrorBoundary FallbackComponent={CoachNotesErrorState}>
              <MemoizedCoachNotes
                memberId={memberId}
                coachingCareTeam={coachingCareTeam}
              />
            </ErrorBoundary>
          </div>
          <div
            className={classNameCombiner([
              styles.contentGridElement,
              styles.chartContainer,
            ])}
          >
            <MemoizedMemberChart
              data-testid="memberChart"
              selectedMemberId={memberId}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(CoachScreen);
