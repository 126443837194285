import gql from 'graphql-tag';

export const getServiceProvisionIntervalsForMemberQuery = gql`
  query GetServiceProvisionIntervalsForMember(
    $input: GetMemberServiceProvisionsInput!
  ) {
    getServiceProvisionIntervalsForMember(input: $input) {
      intervals {
        numSessions
        startsAt
        endsAt
        isAutorenew
      }
    }
  }
`;

export const getD2CMemberSessionsUsedQuery = gql`
  query GetD2CMemberSessionsUsed($input: GetD2CMemberSessionsUsedInput!) {
    getD2cMemberSessionsUsed(input: $input) {
      sessionsUsed
      error
    }
  }
`;
