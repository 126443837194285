import {
  CancelMemberCoachingSessionInput,
  CreateMemberCoachingSessionInput,
  UpdateMemberCoachingSessionInput,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import {
  GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions_recurrence as Recurrence,
  GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions_sessions as CoachingSession,
} from '@headspace/carehub-graphql/dist/scheduler/generated/GetMemberUpcomingCoachingSessions';
import {
  getRecurrenceSpec,
  getSessionStartAndEndDates,
  mapSessionFormatToAvailabilityType,
} from 'app/member-chart-cards/scheduler/utils';
import useScheduler from 'app/scheduler/hooks/useScheduler';
import { useAppState } from 'app/state';
import { SchedulerService } from 'app/state/features/scheduler/SchedulerService';
import {
  chooseCoachingSession,
  createCoachingSession,
  resetSchedulerView,
  setSchedulerView,
} from 'app/state/features/scheduler/schedulerSlice';
import { SchedulerView } from 'app/state/features/scheduler/schema';
import { useLogger } from 'app/state/log/useLogger';
import { useSnackNotification } from 'hooks/useSnackNotification';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export interface Props {
  memberId: string;
  timezone: string | null;
  isD2c?: boolean;
}

export function useSessionScheduler({
  memberId,
  timezone,
  isD2c,
}: Props): {
  selectedCoachingSession?: {
    coachingSession: CoachingSession;
    recurrence: Recurrence | null;
  };
  currentView: SchedulerView;
  onClickCreateCoachingSession: () => void;
  onClickCoachingSession: (
    coachingSession: CoachingSession,
    recurrence: Recurrence | null,
  ) => void;
  onCancelCoachingSession: (
    input: CancelMemberCoachingSessionInput,
  ) => Promise<void>;
  onCreateCoachingSession: (
    input: CreateMemberCoachingSessionInput,
  ) => Promise<void>;
  onUpdateCoachingSession: (
    input: UpdateMemberCoachingSessionInput,
  ) => Promise<void>;
  onCloseForm: () => void;
  onCheckAvailability: () => void;
  onFindEarliestAvailability: () => void;
  onBackToSessionForm: () => void;
} {
  const logger = useLogger();
  const dispatch = useDispatch();
  const {
    showErrorNotification,
    showSuccessNotification,
  } = useSnackNotification();
  const { coachingSession, selectedCoachingSession, currentView } = useAppState(
    ({
      scheduler: { coachingSession, selectedCoachingSession, currentView },
    }) => ({
      coachingSession,
      currentView,
      selectedCoachingSession,
    }),
  );
  const {
    createMemberCoachingSession,
    updateMemberCoachingSession,
    cancelMemberCoachingSession,
  } = useScheduler(memberId, isD2c);

  useEffect(() => {
    dispatch(resetSchedulerView());
  }, [memberId, dispatch]);

  const onClickCoachingSession = (
    coachingSession: CoachingSession,
    recurrence: Recurrence | null,
  ) => {
    dispatch(
      chooseCoachingSession({
        coachTimeZone: timezone || 'UTC',
        coachingSession,
        recurrence,
      }),
    );
  };

  const onClickCreateCoachingSession = () => {
    dispatch(createCoachingSession({ coachTimeZone: timezone ?? 'UTC' }));
  };

  const onCreateCoachingSession = async (
    input: CreateMemberCoachingSessionInput,
  ) => {
    try {
      dispatch(resetSchedulerView());
      await createMemberCoachingSession(input);
      showSuccessNotification('Session successfully created');
    } catch (error) {
      showErrorNotification(error.message, true);
    }
  };

  const onUpdateCoachingSession = async (
    input: UpdateMemberCoachingSessionInput,
  ) => {
    try {
      dispatch(resetSchedulerView());
      await updateMemberCoachingSession(input);
      showSuccessNotification('Session successfully updated');
    } catch (error) {
      showErrorNotification(error.message, true);
    }
  };

  const onCancelCoachingSession = async (
    input: CancelMemberCoachingSessionInput,
  ) => {
    try {
      dispatch(resetSchedulerView());
      await cancelMemberCoachingSession(input);
      showSuccessNotification('Session successfully cancelled');
    } catch (error) {
      showErrorNotification(error.message, true);
    }
  };

  const _onCloseForm = () => dispatch(resetSchedulerView());

  const _setSchedulerView = (currentView: SchedulerView) =>
    dispatch(setSchedulerView({ currentView }));

  const onBackToSessionForm = () =>
    _setSchedulerView(SchedulerView.SESSION_FORM);

  const onCheckAvailability = () => {
    if (!coachingSession) {
      // unexpected state slice value, log the error but proceed to the availability screen
      logger.warning(
        'useCoachingSessionForm.onCheckAvailability: empty coachingSession',
      );
      _setSchedulerView(SchedulerView.AVAILABILITY);
      return;
    }

    const { duration, sessionFormat } = coachingSession;
    const { startDateTime, endDateTime } = getSessionStartAndEndDates(
      coachingSession,
      timezone ?? 'UTC',
    );
    const availabilityTypes = [
      mapSessionFormatToAvailabilityType(sessionFormat),
    ];
    const input = {
      availabilityTypes,
      duration,
      endDateTime,
      recurrenceSpec: getRecurrenceSpec(coachingSession),
      startDateTime,
    };

    dispatch(SchedulerService.getAvailabilitiesForMe({ input }));
    _setSchedulerView(SchedulerView.AVAILABILITY);
  };

  const onFindEarliestAvailability = () => {
    // todo: call GetEarliestCoachAvailability endpoint once MARKET-2781 is complete
  };

  return {
    currentView,
    onBackToSessionForm,
    onCancelCoachingSession,
    onCheckAvailability,
    onClickCoachingSession,
    onClickCreateCoachingSession,
    onCloseForm: _onCloseForm,
    onCreateCoachingSession,
    onFindEarliestAvailability,
    onUpdateCoachingSession,
    selectedCoachingSession,
  };
}
