import {
  CalendarToday,
  NotificationsActiveOutlined,
} from '@mui/icons-material';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useAppState } from 'app/state';
import { classNameCombiner } from 'utils';
import moment from 'moment';
import React from 'react';

import styles from './ReminderInfoBox.module.scss';

const REMINDER_INFO =
  'The member will be reminded 15 minutes before their session.';
const REMINDER_INFO_VARIANT =
  'Members will be reminded 48 hrs, 3 hrs, and 15 mins before their session(s).';

interface ReminderInfoBoxProps {
  className?: string;
  memberId: string;
  showReminderInfo?: boolean;
  showSessionInfo?: boolean;
}

export const ReminderInfoBox = ({
  className = '',
  memberId = '',
  showReminderInfo = true,
  showSessionInfo = false,
}: ReminderInfoBoxProps) => {
  const {
    enable_coaching_scheduler: enableCoachingScheduler,
  } = useFeatureFlags().transientFeatureFlags;
  const { d2cSessionInfo } = useAppState(({ scheduler }) => ({
    d2cSessionInfo: scheduler.d2cSessionInfo?.[memberId],
  }));
  const { sessionsRemaining = 0, sessionEndDate } = d2cSessionInfo || {};

  const endDate = sessionEndDate
    ? moment(sessionEndDate).format('MMMM D, YYYY')
    : undefined;
  const sessionText = sessionsRemaining === 1 ? 'session' : 'sessions';

  return (
    <>
      {showSessionInfo && (
        <div className={classNameCombiner([styles.reminderBox, className])}>
          <CalendarToday className={styles.calendarIcon} />
          <span>
            <strong>{sessionsRemaining}</strong>
            {` ${sessionText}${
              endDate ? ` left until ${endDate}` : ' remaining'
            }`}
          </span>
        </div>
      )}
      {showReminderInfo && (
        <div className={classNameCombiner([styles.reminderBox, className])}>
          <NotificationsActiveOutlined />
          <span>
            {enableCoachingScheduler ? REMINDER_INFO_VARIANT : REMINDER_INFO}
          </span>
        </div>
      )}
    </>
  );
};
