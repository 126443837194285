import * as Sentry from '@sentry/react';
import { SurveyResponseRoutesV3 } from 'app/coach/member-chart/SurveyResponseRoutesV3';
import { TransientFeatureFlag, useFeatureFlags } from 'hooks/useFeatureFlags';
import { surveysRoute } from 'app/patients/Routes';
import React from 'react';
import { Route } from 'react-router-dom';

import { GetPatient_getPatient_surveyResponses } from './generated/GetPatient';
import { SurveysTabV2 } from './tabs/surveys/SurveysTabV2';

export const SurveyResponseRoutes = (props: {
  id: string;
  pathPrefix: string;
  surveyResponsesDeprecated: GetPatient_getPatient_surveyResponses | null;
}) => {
  const { id, pathPrefix, surveyResponsesDeprecated } = props;
  const SentryRoute = Sentry.withSentryRouting(Route);
  const enableSurveyScoresV3 = useFeatureFlags().transientFeatureFlags[
    TransientFeatureFlag.ENABLE_SURVEY_SCORES_V3
  ];

  if (enableSurveyScoresV3) {
    return <SurveyResponseRoutesV3 memberId={id} pathPrefix={pathPrefix} />;
  }

  return (
    <SentryRoute exact={true} path={surveysRoute(pathPrefix, id)}>
      <SurveysTabV2 surveyResponses={surveyResponsesDeprecated} />
    </SentryRoute>
  );
};
