import * as Sentry from '@sentry/react';
import { Team } from 'app/care-team/Team';
import { ContentTab } from 'app/coach/member-chart/tabs/ContentTab';
import { useTeamCommunication } from 'app/collaboration/hooks/useTeamCommunication';
import { AccessCardFragment } from 'app/member-chart-cards/access-card/AccessCard';
import { BenefitsCardFragment } from 'app/member-chart-cards/benefits-card/BenefitsCard';
import { ContactInfoCardFragment } from 'app/member-chart-cards/contact-info/ContactInfoCard';
import { MemberProfileFragment } from 'app/member-chart-cards/member-profile/MemberProfile';
import { careTeamRoute } from 'app/patients/Routes';
import { SecureRoute } from 'app/routes/SecureRoute';
import { Routes } from 'app/top-nav/Routes';
import gql from 'graphql-tag';
import { TransientFeatureFlag, useFeatureFlags } from 'hooks/useFeatureFlags';
import { useMemberChartIndicators } from 'hooks/useMemberChartIndicators';
import { useQuery } from 'hooks/useQuery';
import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { NavigationTabs, TabLink } from 'shared-components/tabs/NavigationTabs';
import { CARE_TEAM_USER_ROLE } from 'utils';

import {
  MemberChartQuery,
  MemberChartQuery_getMember,
  MemberChartQueryVariables,
} from './generated/MemberChartQuery';
import styles from './MemberChart.module.scss';
import {
  contentRoute,
  defaultRoute,
  detailsRoute,
  summaryRoute,
  surveysV3Route,
  teamRoute,
} from './Routes';
import { SurveyResponseRoutesV3 } from './SurveyResponseRoutesV3';
import { DetailsTab } from './tabs/DetailsTab';
import { SummaryTab } from './tabs/SummaryTab';

export type MemberChartProp = {
  selectedMemberId: string;
};

export const memberChartQuery = gql`
  query MemberChartQuery($input: GetMemberInput!) {
    getMember(input: $input) {
      id
      isD2c
      intakeCompleted
      ...MemberProfileFragment
      ...ContactInfoCardFragment
      ...AccessCardFragment
      ...BenefitsCardFragment
    }
  }

  ${MemberProfileFragment}
  ${ContactInfoCardFragment}
  ${AccessCardFragment}
  ${BenefitsCardFragment}
`;

function MemberChartTabs({
  memberId,
  pathPrefix,
}: Readonly<{ memberId: string; pathPrefix: string }>) {
  const { showTeamNotification } = useTeamCommunication(memberId);
  const { showSurveyScoreIndicator } = useMemberChartIndicators(memberId);
  const navigationTabLinks: TabLink[] = [
    { label: 'Summary', path: summaryRoute(pathPrefix, memberId) },
    { label: 'Content', path: contentRoute(pathPrefix, memberId) },
    {
      isNewFeature: false,
      isNotificationVisible: showTeamNotification,
      label: 'Team',
      path: teamRoute(pathPrefix, memberId),
    },
    {
      isNotificationVisible: showSurveyScoreIndicator,
      label: 'Scores',
      path: surveysV3Route(pathPrefix, memberId),
    },
    { label: 'Details', path: detailsRoute(pathPrefix, memberId) },
  ];

  return (
    <NavigationTabs
      className={styles.navTab}
      links={navigationTabLinks}
      preserveQueries={true}
    />
  );
}

function DataState({
  member,
}: Readonly<{ member: MemberChartQuery_getMember }>) {
  const memberId = member.id;
  const pathPrefix = Routes.COACHING_MEMBERS;
  const SentryRoute = Sentry.withSentryRouting(Route);

  return (
    <div data-testid="memberChart" className={styles.memberChart}>
      <div className={styles.navContainer}>
        <MemberChartTabs memberId={memberId} pathPrefix={pathPrefix} />
      </div>
      <div className={styles.memberChartTabContent}>
        <SentryRoute exact={true} path={summaryRoute(pathPrefix, memberId)}>
          <SummaryTab data-testid="summaryTab" memberProfileInfo={member} />
        </SentryRoute>
        <SecureRoute
          exact={true}
          path={contentRoute(pathPrefix, memberId)}
          isVaultRequired={true}
          permittedRoles={[...CARE_TEAM_USER_ROLE]}
          component={() => (
            <ContentTab data-testid="contentTab" memberId={memberId} />
          )}
        />
        <SentryRoute exact={true} path={detailsRoute(pathPrefix, memberId)}>
          <DetailsTab data-testid="detailsTab" memberInfo={member} />
        </SentryRoute>
        <SentryRoute exact={true} path={careTeamRoute(pathPrefix, memberId)}>
          <Team data-testid="memberChartCareTeam" memberId={memberId} />
        </SentryRoute>
        <SurveyResponseRoutesV3 memberId={memberId} pathPrefix={pathPrefix} />

        <SentryRoute exact={true} path={defaultRoute(pathPrefix, memberId)}>
          <DefaultRouteComponent memberId={memberId} />
        </SentryRoute>
      </div>
    </div>
  );
}

export const MemberChart = (props: Readonly<MemberChartProp>) => {
  const { selectedMemberId } = props;

  return useQuery<MemberChartQuery, MemberChartQueryVariables>(
    ({ getMember }) => {
      if (!getMember) return <></>;
      return <DataState member={getMember} />;
    },
    memberChartQuery,
    { variables: { input: { id: selectedMemberId } } },
  );
};

function DefaultRouteComponent({ memberId }: { memberId: string }) {
  const { search } = useLocation();
  return (
    <Redirect
      to={{ pathname: summaryRoute(Routes.COACHING_MEMBERS, memberId), search }}
    />
  );
}
