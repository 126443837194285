import { TimeDivider } from 'app/coach/chat/messages-section/TimeDivider';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';

import { AuthorWithName, ChatMessage } from '../type';
import styles from './CommunicationList.module.scss';
import { CommunicationScreen } from './communications/CommunicationScreen';

interface CommunicationListProps {
  scrollToTheBottom: () => void;
  chats: ChatMessage[];
  author: AuthorWithName;
}
export const CommunicationList = ({
  scrollToTheBottom,
  chats,
  author,
}: CommunicationListProps) => {
  const dateRef = useRef<Date | null>(null);
  useEffect(() => {
    scrollToTheBottom();
  }, [chats]);

  const renderCommunicationMessage = (message: ChatMessage) => {
    const dateObj = new Date(message.createdAt);
    const isTimeDividerNeeded = dateObj.getDay() !== dateRef.current?.getDay();
    dateRef.current = dateObj;
    const formattedString = moment(message.createdAt).format(
      'ddd, MMM DD, YYYY',
    );

    return (
      <div className={styles.messagesContainer} key={message.createdAt}>
        {isTimeDividerNeeded && <TimeDivider dateAndTime={formattedString} />}
        <CommunicationScreen
          key={message.subject}
          chat={message}
          author={author}
        />
      </div>
    );
  };
  return <>{chats.map(renderCommunicationMessage)}</>;
};
