import AddIcon from '@mui/icons-material/Add';
import { ErrorMessage } from 'app/collaboration/ErrorMessage';
import { useAppState } from 'app/state';
import { SchedulerView } from 'app/state/features/scheduler/schema';
import calendar from 'assets/Spot_Coaching.png';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import React from 'react';
import ChipWithTooltip from 'shared-components/buttons/ChipWithTooltip';
import ChipCard from 'shared-components/Card/ChipCard';
import ErrorBoundary from 'shared-components/error-state/ErrorBoundary';
import { Loader } from 'shared-components/loader/Loader';
import { getTimezone } from 'utils/dateTime';

import Titles from '../constants/cards-titles';
import { MemberChartErrorState } from '../error-state/MemberChartErrorState';
import AvailabilitySearch from './AvailabilitySearch';
import { useSessionScheduler } from './hooks/useSessionScheduler';
import { ReminderInfoBox } from './ReminderInfoBox';
import ScheduledSession from './ScheduledSession';
import styles from './SchedulerCard.module.scss';
import SessionForm from './SessionForm';
import SessionFormV2 from './SessionFormV2';

interface Props {
  memberId: string;
  memberTimezone: string | undefined;
  isD2c?: boolean;
}

export function SchedulerCard({
  memberId,
  memberTimezone = 'UTC',
  isD2c = false,
}: Props) {
  const { timezone, sessions, schedulerError, schedulerLoading } = useAppState(
    ({ user, scheduler }) => ({
      schedulerError: scheduler.schedulerError,
      schedulerLoading: scheduler.schedulerLoading,
      sessions: scheduler.sessions,
      timezone: getTimezone(user.timezone),
    }),
  );
  const {
    enable_coaching_scheduler: enableCoachingScheduler,
  } = useFeatureFlags().transientFeatureFlags;
  const {
    selectedCoachingSession,
    onClickCoachingSession,
    onClickCreateCoachingSession,
    onCreateCoachingSession,
    onUpdateCoachingSession,
    onCancelCoachingSession,
    onCloseForm,
    onCheckAvailability,
    onBackToSessionForm,
    currentView,
  } = useSessionScheduler({ isD2c, memberId, timezone });

  const NewNoteChip = (
    <ChipWithTooltip
      chipIcon={<AddIcon />}
      tooltipText="New session"
      tooltipEventLabel="Coach Scheduler > Add new session"
      onClick={onClickCreateCoachingSession}
    />
  );
  let dataComponent: MaybeUndefined<JSX.Element | JSX.Element[]>;

  const emptySessions = (
    <div className={styles.emptyState}>
      <img className={styles.calendarImage} src={calendar} alt="Calendar" />
      <p className={styles.noScheduled}>No upcoming sessions</p>
      {enableCoachingScheduler && (
        <ReminderInfoBox
          className={styles.reminderInfoBox}
          memberId={memberId}
          showSessionInfo={isD2c}
          showReminderInfo={!isD2c}
        />
      )}
    </div>
  );

  if (schedulerError) {
    dataComponent = <ErrorMessage error={schedulerError} />;
  } else if (schedulerLoading) {
    dataComponent = <Loader />;
  } else if (sessions.length > 0) {
    dataComponent = (
      <>
        {enableCoachingScheduler && (
          <ReminderInfoBox
            className={styles.sessionReminderBox}
            memberId={memberId}
            showSessionInfo={isD2c}
          />
        )}
        {sessions.map((session, index) => (
          <ScheduledSession
            key={index}
            session={session}
            onClickCoachingSession={onClickCoachingSession}
            memberTimezone={memberTimezone}
          />
        ))}
      </>
    );
  } else {
    dataComponent = emptySessions;
  }

  const SessionFormComponent = enableCoachingScheduler
    ? SessionFormV2
    : SessionForm;

  switch (currentView) {
    case SchedulerView.AVAILABILITY:
      return (
        <AvailabilitySearch
          memberId={memberId}
          onClose={onCloseForm}
          onBack={onBackToSessionForm}
          onCreate={onCreateCoachingSession}
          coachTimezone={timezone || 'UTC'}
          memberTimezone={memberTimezone}
        />
      );
    case SchedulerView.SESSION_FORM:
      return (
        <SessionFormComponent
          memberId={memberId}
          isD2c={isD2c}
          onClose={onCloseForm}
          onCreate={onCreateCoachingSession}
          onUpdate={onUpdateCoachingSession}
          onCancelSession={onCancelCoachingSession}
          onCheckAvailability={onCheckAvailability}
          coachTimezone={timezone || 'UTC'}
          memberTimezone={memberTimezone}
          existingCoachingSession={selectedCoachingSession}
        />
      );
    default:
      return (
        <ChipCard boxTitle={Titles.SCHEDULER_TITLE} chip={NewNoteChip}>
          <ErrorBoundary FallbackComponent={MemberChartErrorState}>
            {dataComponent}
          </ErrorBoundary>
        </ChipCard>
      );
  }
}
