import { useMutation, useQuery } from '@apollo/client';
import { Loader } from 'shared-components/loader/Loader';
import { ReasonForCreation } from 'generated/globalTypes';
import React, { useEffect, useState } from 'react';

import { ClearClinicalTasksForMeVariables } from './generated/ClearClinicalTasksForMe';
import {
  GetDosespotIframeCredentials,
  GetDosespotIframeCredentials_getDosespotIframeCredentials,
} from './generated/GetDosespotIframeCredentials';
import styles from './Prescriptions.module.scss';
import {
  getDosespotIframeCredentials,
  markPrescriptionAlertsAsRead,
} from './queries';

const DosespotNotifications = () => {
  const [loading, setLoading] = useState(true);
  const [markPrescriptionAlertsAsReadMutation] = useMutation(
    markPrescriptionAlertsAsRead,
  );
  const dosespotCredentialsQuery = useQuery<GetDosespotIframeCredentials>(
    getDosespotIframeCredentials,
    {},
  );

  const markAlertsAsRead = async () => {
    const variables: ClearClinicalTasksForMeVariables = {
      input: {
        reason: ReasonForCreation.NEW_ERX_ALERT,
      },
    };
    await markPrescriptionAlertsAsReadMutation({ variables });
  };

  useEffect(() => {
    async function loadData() {
      await markAlertsAsRead();
      setLoading(false);
    }
    void loadData();
  }, [dosespotCredentialsQuery]);

  const buildDosespotIframeUrl = (
    credentials: GetDosespotIframeCredentials_getDosespotIframeCredentials | null,
  ): string => {
    if (!credentials) {
      return '';
    }
    return `${credentials.url}&RefillsErrors=1`;
  };

  if (loading) {
    return <Loader topMargin={false} />;
  }

  if (!dosespotCredentialsQuery?.data?.getDosespotIframeCredentials) {
    return <div>No Dosespot credentials</div>;
  }

  return (
    <div className={styles.prescriptions}>
      <iframe
        title="DosespotIframe"
        className={styles.iframe}
        src={buildDosespotIframeUrl(
          dosespotCredentialsQuery.data.getDosespotIframeCredentials,
        )}
      />
    </div>
  );
};

export default DosespotNotifications;
