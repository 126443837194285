import { useQuery } from '@apollo/client';
import { DependentIntakeReport } from '@ginger.io/core-ui';
import {
  GetMemberCareTeam,
  GetMemberCareTeamVariables,
} from 'app/care-team/generated/GetMemberCareTeam';
import { getMemberCareTeam } from 'app/care-team/queries';
import { ErrorState } from 'shared-components/error-state/ErrorState';
import { Loader } from 'shared-components/loader/Loader';
import { useAppState } from 'app/state';
import { viewDependentReport } from 'app/state/amplitude/actions/etc';
import Messages from 'i18n/en/patients.json';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

export type CoachDependentIntakeScreenParams = {
  patientId: string;
};

export function CoachDependentIntakeScreen(
  props: RouteComponentProps<CoachDependentIntakeScreenParams>,
) {
  const { match } = props;
  const { params } = match;
  const {
    userId,
    timezone,
  } = useAppState(({ user: { userId, timezone } }) => ({ userId, timezone }));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewDependentReport());
  }, [dispatch]);

  const { loading, error, data } = useQuery<
    GetMemberCareTeam,
    GetMemberCareTeamVariables
  >(getMemberCareTeam, {
    variables: { id: params.patientId, timezone },
  });

  if (loading) return <Loader />;

  if (error) return <ErrorState error={error} />;

  // just current coaches can view the report
  // checks if the current user is not an active coach for this member
  if (
    data?.getMember?.coachingCareTeam?.current?.coaches?.every(
      (coach) => coach?.gingerId !== userId,
    )
  ) {
    return <ErrorState error={new Error(Messages.notActiveCoachForMember)} />;
  }

  return (
    <div data-testid="dependentIntakeReport">
      <DependentIntakeReport id={params.patientId} />
    </div>
  );
}

export default withRouter(CoachDependentIntakeScreen);
