import { CareTeam } from 'app/care-team/CareTeam';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import {
  DeprecatedMasonryLayout,
  MasonryItem,
} from 'app/masonry-layout/DeprecatedMasonryLayout';
import { BenefitsCard } from 'app/member-chart-cards/benefits-card/BenefitsCard';
import { BenefitsCardFragment } from 'app/member-chart-cards/benefits-card/generated/BenefitsCardFragment';
import { ContactInfoCard } from 'app/member-chart-cards/contact-info/ContactInfoCard';
import { ContactInfoCardFragment } from 'app/member-chart-cards/contact-info/generated/ContactInfoCardFragment';
import { BackgroundCard } from 'app/member-chart-cards/member-background/BackgroundCard';
import { MemberProfileFragment } from 'app/member-chart-cards/member-profile/generated/MemberProfileFragment';
import { MemberProfile } from 'app/member-chart-cards/member-profile/MemberProfile';
import { PrescriptionsCard } from 'app/member-chart-cards/prescriptions/PrescriptionsCard';
import { TasksCard } from 'app/member-chart-cards/tasks/TasksCard';
import { GetPatient_getPatient as DeprecatedPatient } from 'app/patients/generated/GetPatient';
import { IntakeTabFragment } from 'app/patients/tabs/intake/generated/IntakeTabFragment';
import { shouldShowPrescriptionsCard } from 'app/patients/tabs/summary/utils';
import { useAppState } from 'app/state';
import { viewSummaryTabAction } from 'app/state/amplitude/actions/etc';
import { GraphQLProps } from 'app/typeUtils';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import styles from './SummaryTab.module.scss';

export interface SummaryTabData
  extends GraphQLProps<
      Pick<
        DeprecatedPatient,
        'address' | 'emergencyContact' | 'preferredPharmacy' | 'careTeam'
      >
    >,
    GraphQLProps<MemberProfileFragment>,
    // TODO: we will get the address and emergencyContact from ContactInfoCardFragment once the following ticket
    //  is completed: https://app.asana.com/0/1201082158239874/1202779579454961.
    Omit<GraphQLProps<ContactInfoCardFragment>, 'address' | 'emergencyContact'>,
    GraphQLProps<BenefitsCardFragment>,
    GraphQLProps<Pick<IntakeTabFragment, 'intakeCompletedAt'>> {
  isSupervisorAndMSUI?: boolean;
  dosespotId?: string | null;
}

export function SummaryTab(props: SummaryTabData) {
  const {
    id,
    careTeam,
    preferredFirstName,
    preferredLastName,
    preferences,
    timezone,
    gender,
    genderIdentification,
    ethnicities,
    pronouns,
    dateOfBirth,
    firstName,
    lastName,
    email,
    guardianRelationship,
    address,
    phone,
    emergencyContact,
    insurance,
    coverageDetails,
    dosespotId,
    intakeCompletedAt,
    isSupervisorAndMSUI = false,
  } = props;

  const {
    enable_care_hub_member_background_card_clinical_view: enableMemberBackgroundCard,
    enable_member_tasks: enableMemberTasks,
  } = useFeatureFlags().transientFeatureFlags;

  const role = useAppState(({ user }) => user.role);

  const showPrescriptionsCard = shouldShowPrescriptionsCard(role, dosespotId);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewSummaryTabAction());
  }, [dispatch]);

  return (
    <div className={styles.tabContent}>
      <DeprecatedMasonryLayout
        className={styles.layout}
        breakPointCols={{
          1100: 2,
          750: 1,
          default: 3,
        }}
      >
        <MasonryItem>
          <ContactInfoCard
            member={{
              address: {
                ...address,
                // TODO: we will get the address and emergencyContact from ContactInfoCardFragment once the following
                //  ticket is completed: https://app.asana.com/0/1201082158239874/1202779579454961.
                __typename: 'AddressType',
              },
              email,
              // TODO: we will get the address and emergencyContact from ContactInfoCardFragment once the following ticket
              //  is completed: https://app.asana.com/0/1201082158239874/1202779579454961.
              emergencyContact: {
                __typename: 'EmergencyContactType',
                name: emergencyContact?.name || null,
                phone: emergencyContact?.phone || null,
                relationship: emergencyContact?.relationship || null,
              },

              firstName,

              lastName,

              phone,
            }}
          />
        </MasonryItem>
        {!isSupervisorAndMSUI && enableMemberTasks && (
          <MasonryItem>
            <TasksCard memberId={id} />
          </MasonryItem>
        )}
        <MasonryItem>
          <CareTeam careTeam={careTeam} />
        </MasonryItem>
        <MasonryItem>
          <MemberProfile
            member={{
              dateOfBirth,
              ethnicities,
              gender,
              genderIdentification,
              guardianRelationship,
              id,
              preferences,
              preferredFirstName,
              preferredLastName,
              pronouns,
              timezone,
            }}
          />
        </MasonryItem>
        <MasonryItem>
          <BenefitsCard member={{ coverageDetails, insurance }} />
        </MasonryItem>
        {showPrescriptionsCard && (
          <MasonryItem>
            <PrescriptionsCard memberId={id} />
          </MasonryItem>
        )}
        <MasonryItem />
        {enableMemberBackgroundCard && (
          <MasonryItem>
            <BackgroundCard
              memberId={id}
              intakeCompleted={!!intakeCompletedAt}
            />
          </MasonryItem>
        )}
        <MasonryItem />
      </DeprecatedMasonryLayout>
    </div>
  );
}
