import {
  field,
  Fields,
  numberField,
  stringField,
  useForm,
} from '@ginger.io/react-use-form';
import {
  CancelMemberCoachingSessionInput,
  CoachingSessionModificationScope,
  CreateMemberCoachingSessionInput,
  CreateMemberCoachingSessionRecurrenceInput,
  UpdateMemberCoachingSessionInput,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import {
  GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions_recurrence as Recurrence,
  GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions_sessions as CoachingSession,
} from '@headspace/carehub-graphql/dist/scheduler/generated/GetMemberUpcomingCoachingSessions';
import { useAppState } from 'app/state';
import { updateCoachingSession } from 'app/state/features/scheduler/schedulerSlice';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import moment from 'moment-timezone';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { CreationFields, RECURRENCE_VALUES, RecurrenceEnum } from '../types';
import {
  DATE_TIME_FORMAT_WITHOUT_TZ,
  DEFAULT_SESSION_COUNT,
  toDateTimeString,
} from '../utils';

export interface Props {
  memberId: string;
  coachTimeZone: string;
  memberTimeZone: string;
  existingCoachingSession?: {
    coachingSession: CoachingSession;
    recurrence: Recurrence | null;
  };
  onCreate: (input: CreateMemberCoachingSessionInput) => void;
  onUpdate?: (input: UpdateMemberCoachingSessionInput) => void;
  onCancelSession?: (input: CancelMemberCoachingSessionInput) => void;
}

export function useCoachingSessionForm(
  props: Props,
): {
  fields: Fields<CreationFields>;
  onClickSave: () => void;
  onConfirmEdit: (modificationScope: CoachingSessionModificationScope) => void;
  onDismissConfirmEdit: () => void;
  onClickCancel: () => void;
  onClickEdit: () => void;
  onConfirmCancel: (input: CancelMemberCoachingSessionInput) => void;
  onDismissConfirmCancel: () => void;
  showConfirmEdit: boolean;
  showConfirmCancel: boolean;
} {
  const dispatch = useDispatch();
  const {
    enable_coaching_scheduler: enableCoachingScheduler,
  } = useFeatureFlags().transientFeatureFlags;
  const { coachingSession, selectedSessionTimes } = useAppState(
    ({ scheduler: { coachingSession, selectedSessionTimes } }) => ({
      coachingSession,
      selectedSessionTimes,
    }),
  );
  const {
    memberId,
    coachTimeZone,
    memberTimeZone,
    existingCoachingSession,
    onCancelSession,
    onCreate,
    onUpdate,
  } = props;
  const [showConfirmCancel, setShowConfirmCancel] = useState<boolean>(false);
  const [showConfirmEdit, setShowConfirmEdit] = useState<boolean>(false);
  const form = useForm<CreationFields>(
    {
      duration: numberField(),
      everyNWeeks: field(),
      id: stringField({ rules: [] }),
      numOccurrences: field({
        default: DEFAULT_SESSION_COUNT,
        rules: [validateNumOccurrences],
      }),
      sessionFormat: field({ rules: [] }),
      sessionStartTime: {
        hour: numberField(),
        minute: numberField(),
      },
      sessionType: field({ rules: [] }),
      startDate: {
        date: numberField(),
        month: numberField(),
        year: numberField(),
      },
    },
    coachingSession,
    {
      onStateChange: async (data) =>
        dispatch(
          updateCoachingSession({
            coachingSession: {
              ...data,
              // Ensure numOccurrences is set to 1 for non-recurring sessions to avoid inconsistent
              // data scenarios where numOccurrences might incorrectly suggest multiple sessions.
              numOccurrences: data.everyNWeeks === 0 ? 1 : data.numOccurrences,
            },
          }),
        ),
    },
  );

  const onClickSave = () => {
    if (existingCoachingSession && existingCoachingSession.recurrence) {
      setShowConfirmEdit(true);
    } else {
      void validateAndSave(CoachingSessionModificationScope.THIS_ONLY);
    }
  };

  const onClickCancel = () => {
    setShowConfirmCancel(true);
  };

  const onClickEdit = () => {
    setShowConfirmEdit(true);
  };

  const onConfirmCancel = (input: CancelMemberCoachingSessionInput) => {
    setShowConfirmCancel(false);
    onCancelSession?.(input);
  };

  const onConfirmEdit = (
    modificationScope: CoachingSessionModificationScope,
  ) => {
    setShowConfirmEdit(false);
    void validateAndSave(modificationScope);
  };

  const onDismissConfirmEdit = () => setShowConfirmEdit(false);

  const onDismissConfirmCancel = () => setShowConfirmCancel(false);

  const validateAndSave = async (
    modificationScope: CoachingSessionModificationScope,
  ) => {
    const isValid = await form.validate();

    if (!isValid) {
      return;
    }

    if (existingCoachingSession) {
      let sessionData = toUpdatedCoachingSession(
        form.getValue(),
        coachTimeZone,
        memberTimeZone,
        modificationScope,
      );
      sessionData = { ...sessionData, modificationScope };
      onUpdate?.(sessionData);
    } else {
      const formData = form.getValue();
      let sessionData = toNewCoachingSession({
        coachTimeZone,
        formData,
        memberId,
        memberTimeZone,
      });

      if (enableCoachingScheduler) {
        sessionData = toNewCoachingSession({
          endTime: selectedSessionTimes?.endTime,
          formData: {
            ...formData,
            sessionStartTime:
              coachingSession?.sessionStartTime ?? formData.sessionStartTime,
          },
          memberId,
          memberTimeZone,
          startTime: selectedSessionTimes?.startTime,
        });
      }
      onCreate(sessionData);
    }
  };

  return {
    fields: form.fields,
    onClickCancel,
    onClickEdit,
    onClickSave,
    onConfirmCancel,
    onConfirmEdit,
    onDismissConfirmCancel,
    onDismissConfirmEdit,
    showConfirmCancel,
    showConfirmEdit,
  };
}

export function startEndTimesToMoment(
  formData: CreationFields,
  coachTimeZone: string,
): { startTime: moment.Moment; endTime: moment.Moment } {
  const { startDate, sessionStartTime, duration } = formData;
  const dateTime = toDateTimeString(startDate, sessionStartTime);
  const utcStartTime = moment
    .tz(dateTime, DATE_TIME_FORMAT_WITHOUT_TZ, coachTimeZone)
    .tz('utc');
  const utcEndTime = utcStartTime.clone().add(duration, 'minutes');
  return {
    endTime: utcEndTime,
    startTime: utcStartTime,
  };
}

interface NewCoachingSessionParams {
  formData: CreationFields;
  memberTimeZone: string;
  memberId: string;
  startTime?: ISODateString;
  endTime?: ISODateString;
  coachTimeZone?: string;
}

function toNewCoachingSession(
  params: NewCoachingSessionParams,
): CreateMemberCoachingSessionInput {
  const { coachTimeZone, memberTimeZone, memberId, formData } = params;
  let startTime: ISODateString;
  let endTime: ISODateString;

  if (coachTimeZone) {
    const computedTimes = startEndTimesToMoment(formData, coachTimeZone);
    startTime = computedTimes.startTime.toISOString();
    endTime = computedTimes.endTime.toISOString();
  } else if (params.startTime && params.endTime) {
    startTime = params.startTime;
    endTime = params.endTime;
  } else {
    throw new Error(
      'Either coachTimeZone or both startTime and endTime must be provided.',
    );
  }

  const { everyNWeeks, numOccurrences } = formData;
  let recurrenceData: CreateMemberCoachingSessionRecurrenceInput | null = null;
  if (RECURRENCE_VALUES.includes(everyNWeeks) && numOccurrences) {
    recurrenceData = {
      everyNWeeks,
      numOccurrences,
    };
  }
  return {
    endTime,
    memberId,
    memberTimezone: memberTimeZone,
    recurrence: recurrenceData,
    startTime,
  };
}

function toUpdatedCoachingSession(
  formData: CreationFields,
  coachTimezone: string,
  memberTimeZone: string,
  modificationScope: CoachingSessionModificationScope,
): UpdateMemberCoachingSessionInput {
  if (!formData.id) {
    throw new Error('An existing CoachingSession must have an ID!');
  }
  const { startTime, endTime } = startEndTimesToMoment(formData, coachTimezone);
  return {
    coachingSessionId: formData.id,
    endTime: endTime.toISOString(),
    memberTimezone: memberTimeZone,
    modificationScope,
    startTime: startTime.toISOString(),
  };
}

function validateNumOccurrences(
  value: number,
  data: CreationFields,
): string | undefined {
  if (RECURRENCE_VALUES.includes(data.everyNWeeks) && !value) {
    return 'This field is required.';
  }
  if (
    data.everyNWeeks === RecurrenceEnum.DOES_NOT_REPEAT ||
    (value >= 2 && value <= 12)
  ) {
    return undefined;
  }
  return 'Must be between 2 and 12.';
}
