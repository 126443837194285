import { Loader } from '@ginger.io/core-ui';
import { CoachClinicianCollaborationChatMessage } from '@ginger.io/vault-care-collaboration/dist/generated/protobuf-schemas/vault-care-collaboration/CoachClinicianCollaborationChatMessage';
import { scrollToTheBottomOfScrollableContainer } from 'app/coach/chat/utils';
import { AuthorWithName } from 'app/collaboration/type';
import { useCollaborationChatStream } from 'app/collaboration/useCollaborationChatStream';
import { ChatTextAreaGroup } from 'app/collaboration/v2/ChatTextAreaGroup';
import { useOnMount } from 'hooks/useOnMount';
import { useDispatch } from 'app/state';
import {
  collaborationNewMessageSendClick,
  collaborationTabClicked,
} from 'app/state/amplitude/actions/collaborationChat';
import { Status } from 'hooks/useHookState';
import React, { ReactNode, useRef } from 'react';

import { ErrorMessage } from '../ErrorMessage';
import styles from './CommunicationContainer.module.scss';
import { CommunicationEmptyState } from './CommunicationEmptyState';
import { CommunicationList } from './CommunicationList';

export interface Props {
  memberId: string;
  author: AuthorWithName;
}

export const CommunicationContainer = (props: Props) => {
  const { memberId, author } = props;
  const dispatch = useDispatch();

  const chatStream = useCollaborationChatStream(memberId, author);

  useOnMount(() => {
    dispatch(collaborationTabClicked({ author, memberId }));
  });

  const onSubmit = async (
    chatMessage: CoachClinicianCollaborationChatMessage,
  ) => {
    await chatStream.createChatMessage(chatMessage);
    dispatch(collaborationNewMessageSendClick({ author, memberId }));
  };

  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const scrollToTheBottom = () => {
    scrollToTheBottomOfScrollableContainer(messagesContainerRef);
  };

  let componentToRender: ReactNode;

  const chatMessageState = chatStream.chatMessages.current;

  switch (chatMessageState.status) {
    case Status.LOADING:
      componentToRender = <Loader />;
      break;
    case Status.ERROR:
      componentToRender = <ErrorMessage error={chatMessageState.error} />;
      break;
    case Status.DATA: {
      const chats = chatMessageState.data.chats ?? [];
      const reversedChats = [...chats].reverse();
      if (reversedChats.length > 0) {
        componentToRender = (
          <CommunicationList
            scrollToTheBottom={scrollToTheBottom}
            chats={reversedChats}
            author={author}
          />
        );
      } else {
        componentToRender = <CommunicationEmptyState />;
      }
    }
  }

  return (
    <div data-testid="communication-container" className={styles.wrapper}>
      <div className={styles.container}>
        <div ref={messagesContainerRef} className={styles.content}>
          {componentToRender}
        </div>
        <ChatTextAreaGroup
          memberId={memberId}
          author={author}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
};
