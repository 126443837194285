import { ItemState } from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/member-tasks/ItemState';
import { isTaskHighPriority } from 'app/inbox/utils';
import React, { useEffect, useState } from 'react';
import { CheckedCheckboxIcon } from 'shared-components/icons/CheckedCheckboxIcon';
import { DisabledCheckboxIcon } from 'shared-components/icons/DisabledCheckboxIcon';
import { UncheckedCheckboxIcon } from 'shared-components/icons/UncheckedCheckboxIcon';

import styles from './ThreeStateCheckbox.module.scss';

export type ThreeStateCheckboxProps = {
  id: string;
  label: string;
  testId?: string;
  onIconClick?: (checkboxIconState: ItemState) => void;
  onTextClick?: (id: string) => void;
  defaultState?: ItemState;
  enableTextStrikeThrough?: boolean;
  labelSize?: 'xs' | 's';
  disabled?: boolean;
  priority?: number;
};

export function ThreeStateCheckbox({
  id,
  label,
  testId,
  onIconClick,
  onTextClick,
  defaultState = ItemState.unchecked,
  enableTextStrikeThrough = true,
  labelSize = 's',
  disabled = false,
  priority = 0,
}: ThreeStateCheckboxProps) {
  const [checkboxIconValue, setCheckboxIconValue] = useState(defaultState);

  useEffect(() => {
    setCheckboxIconValue(defaultState);
  }, [defaultState]);

  const formatLabel = (label: string): JSX.Element => {
    const [title, ...subTitles] = label.split('\n');
    const classNames = [styles.title];
    if (isTaskHighPriority(priority)) {
      classNames.push(styles.priority);
    }
    return (
      <>
        <div className={classNames.join(' ')}>{title.trim()}</div>
        {subTitles.length > 0 && (
          <div className={styles.subTitle}>{subTitles.join(' ').trim()}</div>
        )}
      </>
    );
  };

  const handleIconClick = (): void => {
    let checkboxIconState: ItemState = checkboxIconValue;
    if (isUnChecked(checkboxIconValue)) {
      checkboxIconState = ItemState.checked;
    } else if (checkboxIconValue === ItemState.checked) {
      checkboxIconState = ItemState.not_applicable;
    } else if (checkboxIconValue === ItemState.not_applicable) {
      checkboxIconState = ItemState.unchecked;
    }
    setCheckboxIconValue(checkboxIconState);
    if (onIconClick) onIconClick(checkboxIconState);
  };

  const handleTextClick = (id: string): void => {
    if (onTextClick) onTextClick(id);
  };

  const icon = (
    <button
      className={`${styles.icon}`}
      type="button"
      name={`icon-${id}`}
      id={`icon-${id}`}
      data-testid={testId}
      value={checkboxIconValue}
      onClick={handleIconClick}
      disabled={disabled}
    >
      {checkboxIconValue === ItemState.checked ? (
        <CheckedCheckboxIcon data-testid="threeStateCheckbox-checkedIcon" />
      ) : isUnChecked(checkboxIconValue) ? (
        <UncheckedCheckboxIcon data-testid="threeStateCheckbox-uncheckIcon" />
      ) : (
        <DisabledCheckboxIcon data-testid="threeStateCheckbox-notApplicableIcon" />
      )}
    </button>
  );

  const classNames = [styles.label, styles[labelSize]];
  if (checkboxIconValue === ItemState.checked) {
    classNames.push(
      !enableTextStrikeThrough
        ? styles.checkedWithoutStrikeThrough
        : styles.checked,
    );
  } else if (checkboxIconValue === ItemState.not_applicable) {
    classNames.push(
      !enableTextStrikeThrough
        ? styles.notApplicableWithoutStrikeThrough
        : styles.notApplicable,
    );
  } else {
    classNames.push(styles.unchecked);
  }

  const text = (
    <div className={styles.text}>
      <div
        className={classNames.join(' ')}
        id={`text-${id}`}
        onClick={() => handleTextClick(id)}
        role="presentation"
      >
        {formatLabel(label)}
      </div>
    </div>
  );

  return (
    <div className={styles.checkbox}>
      {icon}
      {text}
    </div>
  );
}

export const isUnChecked = (state: ItemState | undefined) =>
  [
    ItemState.undefined_state,
    ItemState.unchecked,
    ItemState.UNRECOGNIZED,
  ].includes(state ?? ItemState.unchecked);
