import {
  FormatQuote,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import { Box, Button, Link } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import { GetClinicalAppointmentsBulkData_getClinicalAppointmentsBulkData_comments as Comment } from 'app/care-metrics/generated/GetClinicalAppointmentsBulkData';
import { CARE_METRICS_COMMENT_MAX_CHARS } from 'app/constants';
import { clickFeedbackCarousalAction } from 'app/state/amplitude/actions/careMetrics';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './CareMetrics.module.scss';

interface ReadMoreProps {
  text: string;
  maxChars: number;
}

function ReadMore(props: ReadMoreProps) {
  const { text, maxChars } = props;
  const [isReadMore, setIsReadMore] = useState(true);
  const handleClick = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <>
      <Box className={styles.comment}>
        {isReadMore ? `${text.substring(0, maxChars)}...` : text}
      </Box>
      <Box className={styles.readMore}>
        <Link component="button" onClick={handleClick}>
          {isReadMore ? 'read more' : 'show less'}
        </Link>
      </Box>
    </>
  );
}

function FeedbackQuotesComponent(props: {
  quotes: Array<Comment | null>;
  clinicianId: string;
}) {
  const dispatch = useDispatch();
  const style = () => ({
    '.MuiButton-root': {
      color: '#000',
      minWidth: 0,
    },
    '.MuiMobileStepper-dotActive': {
      backgroundColor: '#000',
    },
    '.MuiMobileStepper-dots': {
      padding: 0.5,
    },
  });
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = props.quotes.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    dispatch(
      clickFeedbackCarousalAction({
        clinicianId: props.clinicianId,
        direction: 'next',
      }),
    );
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    dispatch(
      clickFeedbackCarousalAction({
        clinicianId: props.clinicianId,
        direction: 'previous',
      }),
    );
  };

  const quote = props.quotes[activeStep];
  const comment = quote?.comment ?? '';

  const sentAtMoment = quote?.sentAt ? moment(quote.sentAt) : moment();
  return (
    <Box className={styles.feedbackWrapper}>
      <FormatQuote
        sx={{ color: '#dda532', fontSize: 40, transform: 'rotate(180deg)' }}
      />
      <div>{sentAtMoment.format('MMM D, YYYY')}</div>
      {comment.length > CARE_METRICS_COMMENT_MAX_CHARS ? (
        <ReadMore text={comment} maxChars={CARE_METRICS_COMMENT_MAX_CHARS} />
      ) : (
        <Box className={styles.comment}>{comment}</Box>
      )}
      <MobileStepper
        sx={style}
        className={styles.stepper}
        steps={maxSteps}
        variant="dots"
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
          </Button>
        }
      />
    </Box>
  );
}

export default FeedbackQuotesComponent;
