import { MemberChartQuery_getMember } from 'app/coach/member-chart/generated/MemberChartQuery';
import { MemberChartTab } from 'app/coach/member-chart/MemberChartTab';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { BackgroundCard } from 'app/member-chart-cards/member-background/BackgroundCard';
import { MemberProfile } from 'app/member-chart-cards/member-profile/MemberProfile';
import { SchedulerCard } from 'app/member-chart-cards/scheduler/SchedulerCard';
import { TasksCard } from 'app/member-chart-cards/tasks/TasksCard';
import { useAppState } from 'app/state';
import { isTeen } from 'utils';
import { UserRole } from 'generated/globalTypes';
import React from 'react';

export const SummaryTab = ({
  memberProfileInfo,
}: {
  memberProfileInfo: MemberChartQuery_getMember | null;
}) => {
  const {
    enable_member_tasks,
    enable_scheduler_v2,
    enable_profile_card_improvements,
  } = useFeatureFlags().transientFeatureFlags;
  const { role } = useAppState(({ user }) => ({
    role: user.role,
  }));

  const _isTeen = isTeen(memberProfileInfo?.dateOfBirth);

  if (!memberProfileInfo || !memberProfileInfo.id) {
    return null;
  }

  const showScheduler = enable_scheduler_v2 && role === UserRole.COACH;
  const shiftMemberProfileCard =
    enable_profile_card_improvements && role === UserRole.COACH;

  return (
    <MemberChartTab>
      {enable_member_tasks && <TasksCard memberId={memberProfileInfo.id} />}
      {shiftMemberProfileCard && <MemberProfile member={memberProfileInfo} />}
      {showScheduler ? (
        <SchedulerCard
          memberTimezone={memberProfileInfo.timezone ?? undefined}
          memberId={memberProfileInfo.id}
          isD2c={!!memberProfileInfo.isD2c}
        />
      ) : null}

      {!shiftMemberProfileCard && <MemberProfile member={memberProfileInfo} />}

      <BackgroundCard
        memberId={memberProfileInfo.id}
        isTeen={_isTeen}
        intakeCompleted={memberProfileInfo.intakeCompleted || false}
      />
    </MemberChartTab>
  );
};
