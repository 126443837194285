import { InboxSections } from 'app/inbox/types';
import { assignHomeworkResultStatus } from 'app/patients/tabs/content/types';
import { getInboxConversationTimetokens_getInboxConversationByIds_conversationStats } from 'app/state/features/conversationTimetokens/generated/getInboxConversationTimetokens';
import { redactSensitiveInfoFromPubhubData } from 'app/state/inbox/utils';
import { ILogger } from 'app/state/log/Logger';
import moment from 'moment';
import { FetchMessagesResponse } from 'pubnub';
import { RefObject } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  Envelope,
  GetHistory,
  MemberActionRPC,
  MessageStatus,
  MessageToDisplay,
  SenderType,
  UpdateReadStateRPC,
} from '../pubnub/types';
import {
  convertPubNubTimetokenToTimestamp,
  formatAndFilterHistory,
  isRPC,
  isTextMessage,
  tryToHandleAsTextMessage,
} from '../pubnub/utils';
import { noHistoryForCountingError } from './strings';
import {
  TimeTokensAndStamps,
  TypingState,
  UnreadMessagesFoConversation,
  UnreadMessagesForAllConversations,
  UpdateUnreadMessagesPayload,
} from './types';

export const getFormattedDateAndTime = (
  timetoken: string | number,
  timezone: string,
): { formattedString: string; date: Date } => {
  const timestamp = convertPubNubTimetokenToTimestamp(timetoken);
  const dateObj = new Date(timestamp);

  const formattedTime = moment(dateObj)
    .tz(timezone)
    .format('MMM D, YYYY · h:mma');

  const timeZoneAbbreviation = moment.tz(timezone).zoneName();
  return {
    date: dateObj,
    formattedString: `${formattedTime} ${timeZoneAbbreviation}`,
  };
};

export const TIMEOUT_FOR_SENDING_A_MESSAGE_MS = 60000;

export const updateMessagesStatuses = (
  callerLastReadTimetoken: string | null,
  messages: MessageToDisplay[],
) => {
  if (!callerLastReadTimetoken) {
    return messages;
  }
  return messages.map((currMess) => {
    const { timetoken } = currMess;
    if (timetoken > callerLastReadTimetoken) {
      return {
        ...currMess,
        status: MessageStatus.DELIVERED,
      };
    }
    if (timetoken <= callerLastReadTimetoken) {
      return {
        ...currMess,
        status: MessageStatus.SEEN,
      };
    }
    return currMess;
  });
};

export const scrollToTheBottomOfScrollableContainer = (
  containerRef: RefObject<HTMLDivElement>,
) => {
  const scrollableContainer = containerRef.current;
  if (scrollableContainer)
    scrollableContainer.scrollTop = scrollableContainer.scrollHeight;
};

export const isContainerScrolledToTheBottom = (
  containerRef: RefObject<HTMLElement>,
) => {
  const scrollableContainer = containerRef.current;
  if (scrollableContainer) {
    const res = scrollableContainer.scrollTop >= 0;
    return res;
  }
  return false;
};

export const getTooltipTextWithStatus = (
  status: MessageStatus,
  dateandTime: string,
): string => {
  const capitilizedStatus = status[0] + status.slice(1).toLowerCase();
  return `${capitilizedStatus} · ${dateandTime}`;
};

export const getUnreadMessagesForChannel = (
  rawMessages: Array<Envelope>,
  lastListenerReadTimeToken: string,
): UnreadMessagesFoConversation => {
  const allUnreadEnvelopes = rawMessages.filter(
    (envelope) => envelope.timetoken > lastListenerReadTimeToken,
  );
  const channelMessages = { ignoredRPCs: [], unreadMessages: [] };
  return allUnreadEnvelopes.reduce<UnreadMessagesFoConversation>(
    (accum, envelope) => {
      const isFromMember =
        isTextMessage(envelope) &&
        envelope.message.senderType === SenderType.CALLER;
      if (isFromMember) {
        const formattedMessageResponse = tryToHandleAsTextMessage(envelope);
        if (formattedMessageResponse.message) {
          return {
            ...accum,
            unreadMessages: [
              ...accum.unreadMessages,
              formattedMessageResponse.message,
            ],
          };
        }
        return accum;
      }
      if (isRPC(envelope)) {
        return {
          ...accum,
          ignoredRPCs: [
            ...accum.ignoredRPCs,
            redactSensitiveInfoFromPubhubData(envelope),
          ],
        };
      }
      return accum;
    },
    channelMessages,
  );
};

export const buildUpdateReadStateRPC = ({
  senderId,
  username,
  message_ids,
}: {
  senderId: string;
  username: string;
  message_ids: string[];
}): UpdateReadStateRPC => {
  const updateReadStateRPC: UpdateReadStateRPC = {
    id: uuidv4(),
    message_ids,
    oncall_listener_id: null,
    rpc: MemberActionRPC.UPDATE_READ_STATE,
    senderId,
    senderType: 'listener',
    username,
  };
  return updateReadStateRPC;
};

export const getDataOnChagesInTimetokens = ({
  existingTimetokens,
  newTimetokens,
}: {
  existingTimetokens: TimeTokensAndStamps | undefined;
  newTimetokens: getInboxConversationTimetokens_getInboxConversationByIds_conversationStats;
}) => {
  const {
    lastListenerReadTimeToken: newLastListenerReadTimeToken,
    lastMemberReadTimeToken: newLastMemberReadTimeToken,
    lastMemberWriteTimeToken: newLastMemberWriteTimeToken,
    latestWriteTimestamp: newLatestWriteTimestamp,
  } = newTimetokens;
  if (!existingTimetokens) {
    return {
      hasLastListenerReadChanged: true,
      hasLastMemberReadChanged: true,
      hasLastMemberWriteChanged: true,
      updatedConvoStatsData: newTimetokens,
    };
  }
  const {
    lastListenerReadTimeToken: existingLastListenerRead,
    lastMemberReadTimeToken: existingLastMemberRead,
    lastMemberWriteTimeToken: existingLastMemberWrite,
    latestWriteTimestamp: existingLatestWriteTimestamp,
  } = existingTimetokens;

  const hasLastListenerReadChanged = Boolean(
    newLastListenerReadTimeToken &&
      newLastListenerReadTimeToken !== existingLastListenerRead,
  );
  const hasLastMemberReadChanged = Boolean(
    newLastMemberReadTimeToken &&
      newLastMemberReadTimeToken !== existingLastMemberRead,
  );
  const hasLastMemberWriteChanged = Boolean(
    newLastMemberWriteTimeToken &&
      newLastMemberWriteTimeToken !== existingLastMemberWrite,
  );
  const hasLatestWriteTimestampChanged = Boolean(
    newLatestWriteTimestamp &&
      newLatestWriteTimestamp !== existingLatestWriteTimestamp,
  );
  let updatedConvoStatsData: Partial<TimeTokensAndStamps> = {};
  if (hasLastListenerReadChanged) {
    updatedConvoStatsData = {
      ...updatedConvoStatsData,
      lastListenerReadTimeToken: newLastListenerReadTimeToken,
    };
  }
  if (hasLastMemberReadChanged) {
    updatedConvoStatsData = {
      ...updatedConvoStatsData,
      lastMemberReadTimeToken: newLastMemberReadTimeToken,
    };
  }
  if (hasLastMemberWriteChanged) {
    updatedConvoStatsData = {
      ...updatedConvoStatsData,
      lastMemberWriteTimeToken: newLastMemberWriteTimeToken,
    };
  }
  if (hasLatestWriteTimestampChanged) {
    updatedConvoStatsData = {
      ...updatedConvoStatsData,
      latestWriteTimestamp: newLatestWriteTimestamp,
    };
  }

  return {
    hasLastListenerReadChanged,
    hasLastMemberReadChanged,
    hasLastMemberWriteChanged,
    updatedConvoStatsData: Object.keys(updatedConvoStatsData).length
      ? updatedConvoStatsData
      : null,
  };
};

export const countUnreadMessagesForAllConversations = ({
  messagesInEnvelopesMap,
  inboxItemsData,
  channelsWithAnyUnreadMessages,
  logger,
}: {
  messagesInEnvelopesMap: FetchMessagesResponse;
  inboxItemsData: UpdateUnreadMessagesPayload;
  channelsWithAnyUnreadMessages: string[];
  logger: ILogger;
}): UnreadMessagesForAllConversations => {
  // in this obj the memberCoachChannel is a key and the last listener read is a value
  const channelLastReadMap = inboxItemsData.reduce<Record<string, string>>(
    (accum, currentItem) => {
      // eslint-disable-next-line no-param-reassign
      accum[
        currentItem.memberCoachChannelId
      ] = currentItem.lastListenerReadTimetoken!;
      return accum;
    },
    {},
  );

  const countingResultsForChannels = { ignoredRPCs: {}, unreadMessages: {} };
  // iterate over the channels, check if the response from pubnub returned the history and count unread messages by: (1) counting text messages from a member and (2) recording ignored RPCs
  const countingResult = channelsWithAnyUnreadMessages.reduce<
    UnreadMessagesForAllConversations
  >((accum, channelId) => {
    if (!(channelId in messagesInEnvelopesMap.channels)) {
      logger.error(new Error(noHistoryForCountingError), { channelId });
    }
    const rawMessages = messagesInEnvelopesMap.channels[channelId];

    const { unreadMessages, ignoredRPCs } = getUnreadMessagesForChannel(
      rawMessages,
      channelLastReadMap[channelId],
    );
    // eslint-disable-next-line no-param-reassign
    accum = {
      ignoredRPCs: { ...accum.ignoredRPCs, [channelId]: ignoredRPCs },
      unreadMessages: {
        ...accum.unreadMessages,
        [channelId]: unreadMessages,
      },
    };
    return accum;
  }, countingResultsForChannels);

  return countingResult;
};

export const initializeTypingState = (): TypingState => ({
  clock: 0,
  isTyping: false,
  nextMessageId: uuidv4(),
});

export const getUrl = () => {
  return window.location.href;
};

export const getMemberLocationInAllInboxTab = (
  memberId: string,
  tabSections: Record<
    InboxSections,
    {
      ids: Set<string>;
      hasMore?: boolean | undefined;
      cursor: string | null;
    }
  >,
) => {
  const allTabSections = [
    InboxSections.SCHEDULED,
    InboxSections.PAST,
    InboxSections.CLOSED,
  ];
  return (
    allTabSections.find((section) => tabSections[section].ids.has(memberId)) ||
    null
  );
};

export const linkRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/; // eslint-disable-line no-useless-escape

export const checkIfStringHasALink = (
  input: string,
  linkRegEx: RegExp,
): { hasLinks: boolean; domains: string[] } => {
  let hasLinks: boolean = false;
  const domains: string[] = [];
  input.split(' ').forEach((word) => {
    const isLink = linkRegEx.test(word);
    if (isLink) {
      hasLinks = true;
      const domain = new URL(word).hostname;
      domains.push(domain);
    }
  });

  return { domains, hasLinks };
};

export const MIN_NUMBER_OF_MESSAGES = 30;

const getMessagesWithoutDuplication = (
  currentMessages: MessageToDisplay[],
  retrievedMessages: MessageToDisplay[],
) => {
  const firstCurrentMessage = currentMessages[0];
  // this should usually be the case, it means we're already displaying some messages for this channel
  if (firstCurrentMessage) {
    const numberOfRetrievedMessages = retrievedMessages.length;
    if (numberOfRetrievedMessages) {
      const lastRetrievedMessage =
        retrievedMessages[numberOfRetrievedMessages - 1];
      if (lastRetrievedMessage.id === firstCurrentMessage.id) {
        const messagesWithSlicedDuplication = [
          ...retrievedMessages,
          ...currentMessages.slice(1),
        ];
        return messagesWithSlicedDuplication;
      }
      return [...retrievedMessages, ...currentMessages];
    }
    return currentMessages;
  }
  // this is a corner case when, for some reason the current messages array is empty
  return retrievedMessages;
};

type GetMessageParams = {
  initialMessages?: MessageToDisplay[];
  getHistory: GetHistory;
  logger: ILogger;
  channelId: string;
  minMessageCount?: number;
  startTime?: string;
};

export const getMinNumberOfMessages = async (
  params: Omit<GetMessageParams, 'minMessageCount' | 'startTime'>,
): Promise<MessageToDisplay[]> => {
  return getMessages(params);
};

export const getMessages = async (
  params: GetMessageParams,
): Promise<MessageToDisplay[]> => {
  const {
    channelId,
    getHistory,
    initialMessages,
    logger,
    startTime,
    minMessageCount = MIN_NUMBER_OF_MESSAGES,
  } = params;
  let updatedMessages: MessageToDisplay[] = [...(initialMessages ?? [])];
  const fetchHistoryRecursively = async (
    startTimetoken?: string,
  ): Promise<MessageToDisplay[]> => {
    const messagesInEnvelopesMap = await getHistory({
      channelIds: [channelId],
      start: startTimetoken,
    });

    // when pubnub returns an empty obj - it means that during the last call we fetched all messages till the very beginning of the messages history
    if (!(channelId in messagesInEnvelopesMap.channels)) {
      return updatedMessages;
    }
    const rawMessages = messagesInEnvelopesMap.channels[channelId];
    const formattedMessages = formatAndFilterHistory(
      rawMessages,
      channelId,
      logger,
    );
    /* pubnub should return messages OLDER than the start timetoken according to the docs,
                but sometimes they return messages older AND equal to the start timetoken.
                This leads to the duplication of messages, so we need to check the
                first message in the messages against the last in the response to avoid the duplication */
    updatedMessages = getMessagesWithoutDuplication(
      updatedMessages,
      formattedMessages,
    );

    const timetokenOfEarliestMessage = rawMessages[0].timetoken.toString();

    return updatedMessages.length >= minMessageCount
      ? updatedMessages
      : fetchHistoryRecursively(timetokenOfEarliestMessage);
  };
  return fetchHistoryRecursively(
    startTime ?? (initialMessages ?? [])[0]?.timetoken,
  );
};

export const calculateDelayInSeconds = (
  messageText: string,
  messages: MessageToDisplay[],
  currentMillisecondTimestamp: number,
): number => {
  if (messageText.length === 0 || messageText.charAt(0) === '/') {
    return 0;
  }
  // It may be possible that there are no previous messages, so this
  // needs to be considered when calculating time since last message
  const delayInSeconds = messageText.length / 6;
  const lastMessage = messages[messages.length - 1] ?? null;
  let finalDelay = Math.round(delayInSeconds);
  if (lastMessage) {
    const lastMessageTimestamp = convertFromHighPrecisionTimestamp(
      lastMessage.timetoken,
    ); // convert to milliseconds

    const timeDiffInSeconds =
      (currentMillisecondTimestamp - lastMessageTimestamp) / 1000; // convert milliseconds to seconds
    const remainingDelayInSeconds = finalDelay - timeDiffInSeconds;
    finalDelay = Math.round(
      remainingDelayInSeconds > 0 ? remainingDelayInSeconds : 0,
    );
  }
  return Math.max(finalDelay, 5);
};

export const convertToHighPrecisionTimestamp = (
  timestampInMilliseconds: number,
): string => {
  // Convert milliseconds to microseconds
  const timestampInMicroseconds = timestampInMilliseconds * 1000;

  // Convert to 17-digit precision Unix time (in UTC)
  const highPrecisionTimestamp = timestampInMicroseconds * 10; // To make the timestamp 17 digits long

  // Return as a string
  return highPrecisionTimestamp.toString();
};

export const convertFromHighPrecisionTimestamp = (
  timestampInHighPrecision: string,
): number => {
  // Convert the string to a number and reverse the operations done in convertToHighPrecisionTimestamp
  const timestampInMicroseconds = Number(timestampInHighPrecision) / 10;
  const timestampInMilliseconds = timestampInMicroseconds / 1000;

  // Return as a number (rounding down to remove any fractional part)
  return Math.floor(timestampInMilliseconds);
};

export const getAssignHomeworkErrorMessage = (
  memberName: string,
  status: assignHomeworkResultStatus,
) => {
  switch (status) {
    case assignHomeworkResultStatus.IS_DUPLICATED: {
      return `This has already been recommended to ${memberName}. Please select another content recommendation.`;
    }
    case assignHomeworkResultStatus.IS_COMPLETED: {
      return `This has already been completed by ${memberName}. Please select another content recommendation.`;
    }
    case assignHomeworkResultStatus.NO_LINK: {
      return `No valid link provided, please double-check the link and if it looks correctly file a bug report`;
    }
    case assignHomeworkResultStatus.INVALID_ID: {
      return `No valid id provided, please double-check the link and if it looks correctly file a bug report`;
    }

    default: {
      return 'Something went wrong with assignining home work, please try again later';
    }
  }
};
