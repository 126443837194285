import { PatientProfileWrapper } from 'app/patients/PatientProfile';
import styles from 'app/patients/PatientsScreen.module.scss';
import { useAppState } from 'app/state';
import { selectPatientAction } from 'app/state/amplitude/actions/etc';
import { Routes, TopLevelRoutes } from 'app/top-nav/Routes';
import { UserRole } from 'generated/globalTypes';
import { useQuery } from 'hooks/useQuery';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import { useParams } from 'react-router-dom';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';

import { SearchMember, SearchMemberVariables } from './generated/SearchMember';
import { searchMemberQuery } from './MemberDemographicsGQL';
import MemberProfile from './MemberProfile';
import { MemberResultList } from './MemberResultList';

function MemberSearchScreen() {
  const { patientId } = useParams<{ patientId?: string }>();
  if (!patientId) {
    return <Redirect to={TopLevelRoutes.MEMBERS} />;
  }
  return <MemberSearch query={patientId} />;
}

function MemberSearch(props: { query: string }) {
  return useQuery<SearchMember, SearchMemberVariables>(
    (data) => <MemberSearchResults key={props.query} data={data} />,
    searchMemberQuery,
    {
      variables: { q: props.query },
    },
  );
}

function MemberSearchResults(props: { data: SearchMember }) {
  const { searchMember } = props.data;
  const [selectedId, setSelectedId] = useState<string | null>(
    searchMember.length === 1 ? searchMember[0].id : null,
  );

  const dispatch = useDispatch();
  const { role } = useAppState((_) => _.user);
  const isMemberSupport = role === UserRole.MEMBER_SUPPORT;

  const onSelect = (patientId: string) => {
    setSelectedId(patientId);
    dispatch(
      selectPatientAction({ location: 'Search Result List', patientId }),
    );
  };

  return (
    <Grid className={styles.grid}>
      <Columns
        className={styles.item}
        widths={[COLUMN_WIDTH.TWO, COLUMN_WIDTH.TEN]}
      >
        <MemberResultList
          selectedMemberId={selectedId}
          onSelect={onSelect}
          members={searchMember}
        />
        {selectedId ? (
          isMemberSupport ? (
            <MemberProfile memberId={selectedId} />
          ) : (
            <PatientProfileWrapper
              patientId={selectedId}
              className={styles.profileWrapper}
              pathPrefix={Routes.MEMBERS}
            />
          )
        ) : null}
      </Columns>
    </Grid>
  );
}

export default withRouter(MemberSearchScreen);
