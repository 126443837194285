import { Mutex } from 'async-mutex';

import { getBaseQuery } from '..';
import { BaseQueryWithExtraOptions } from './types';

// create a new mutex
const mutex = new Mutex();

export const baseQueryWithReauth: BaseQueryWithExtraOptions = async (
  args,
  api,
  extraOptions,
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();

  if (!extraOptions.server)
    throw new Error('server in extraOptions is required');

  const baseQuery = getBaseQuery(extraOptions.server);

  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        // to-do: refresh the token
        /* await api.dispatch(AuthService.startLogin()); */
        /* api.dispatch(updateSessionState(SessionState.EXPIRED)); */
        result = await baseQuery(args, api, extraOptions);
      } finally {
        // release must be called once the mutex should be released again
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};
