import { stringField, useForm } from '@ginger.io/react-use-form';
import { ItemState } from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/member-tasks/ItemState';
import { InputBase } from '@mui/material';
import { CheckedCheckboxIcon } from 'shared-components/icons/CheckedCheckboxIcon';
import { DisabledCheckboxIcon } from 'shared-components/icons/DisabledCheckboxIcon';
import { ThreeStateCheckbox } from 'shared-components/ThreeStateCheckbox/ThreeStateCheckbox';
import { UncheckedCheckboxIcon } from 'shared-components/icons/UncheckedCheckboxIcon';
import styles from 'app/member-chart-cards/tasks/Tasks.module.scss';
import {
  Metadata,
  TasksTooltip,
} from 'app/member-chart-cards/tasks/TasksTooltip';
import React, { useState } from 'react';

import { TaskItem } from './types';

export function ActiveFollowupItem(props: {
  item: TaskItem;
  onUpdate: (label: string, item: TaskItem) => Promise<boolean>;
  onCheckStateUpdate: (state: ItemState, item: TaskItem) => void;
  disabled: boolean;
  metadata: Metadata;
  showCreationDetails?: boolean;
}) {
  const {
    item,
    disabled,
    onUpdate,
    showCreationDetails,
    metadata,
    onCheckStateUpdate,
  } = props;
  const { label, id, state } = item;
  const [editMode, setEditMode] = useState(false);
  const { fields, isTouched, reset, getValue } = useForm<{ label: string }>({
    label: stringField({ default: label, rules: [] }),
  });
  const handleUpdate = async () => {
    const value = getValue();
    setEditMode(false);
    if (isTouched) {
      const success = onUpdate(value.label, item);
      setEditMode(!success);
      reset({ label: value.label });
    }
  };
  if (editMode) {
    return (
      <div data-testid={`edit-followup-item-${id}`} className={styles.row}>
        <div className={styles.col}>
          {state === ItemState.checked ? (
            <CheckedCheckboxIcon />
          ) : state === ItemState.not_applicable ? (
            <DisabledCheckboxIcon />
          ) : (
            <UncheckedCheckboxIcon />
          )}
          <InputBase
            name="edit_label"
            disabled={disabled}
            id={id}
            className={
              state === ItemState.checked
                ? `${styles.textarea} ${styles.checked}`
                : state === ItemState.not_applicable
                ? `${styles.textarea} ${styles.notApplicable}`
                : `${styles.textarea} ${styles.unchecked}`
            }
            multiline={true}
            defaultValue={fields.label.value}
            placeholder="Type new talking point..."
            onKeyDown={(e) => {
              if (!e.shiftKey && e.key === 'Enter') {
                e.preventDefault();
                void handleUpdate();
              }
            }}
            onFocus={(e) =>
              e.currentTarget.setSelectionRange(
                e.currentTarget.value.length,
                e.currentTarget.value.length,
              )
            }
            onBlur={(e) => {
              e?.preventDefault?.();
              void handleUpdate();
            }}
            onChange={(e) => fields.label.setValue(e.target.value)}
            inputRef={(input) => input && input.focus()}
          />
        </div>
      </div>
    );
  }

  return (
    <div key={`new_row_${id}`} className={styles.row}>
      <div key={`new_col_${id}`} className={styles.col}>
        <TasksTooltip
          metadata={metadata}
          showCreationDetails={showCreationDetails}
          labelForAnalytics="Member Tasks: Follow-Up Item"
        >
          <div data-testid={`followup-item-${id}`}>
            <ThreeStateCheckbox
              id={id}
              label={label}
              defaultState={state}
              onIconClick={(state) => onCheckStateUpdate(state, item)}
              onTextClick={() => setEditMode(true)}
              disabled={disabled}
            />
          </div>
        </TasksTooltip>
      </div>
    </div>
  );
}
