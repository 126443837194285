import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { formatEnumValue } from 'utils';
import gql from 'graphql-tag';
import React from 'react';
import { CareTeamRole } from 'generated/globalTypes';
import { Box } from 'shared-components/Box';
import { GraphQLProps } from 'app/typeUtils';
import styles from './CareTeam.module.scss';
import CareTeamTooltip from './CareTeamTooltip';
import {
  CareTeamFragment as CareTeamData,
  CareTeamFragment_careTeam_coaches,
  CareTeamFragment_careTeam_ms,
  CareTeamFragment_careTeam_psychiatrists,
  CareTeamFragment_careTeam_therapists,
} from './generated/CareTeamFragment';
import { Avatar } from '@ginger.io/core-ui';

export const CareTeamFragment = gql`
  fragment CareTeamFragment on Member {
    careTeam {
      coaches {
        id
        name
        avatar
        role
        isTeamLead
        lastEngagement
        totalMessagesExchanged
      }
      ms {
        id
        name
        avatar
        role
        isTeamLead
        lastEngagement
        totalMessagesExchanged
      }

      therapists {
        id
        userId
        name
        avatar
        role
        lastAppointment
        nextAppointment
      }

      psychiatrists {
        id
        userId
        name
        avatar
        role
        lastAppointment
        nextAppointment
      }
    }
  }
`;

type Props = {
  name: string;
  avatar: string;
  role: CareTeamRole;
  nextAppointment?: string | null;
  lastAppointment?: string | null;
  totalMessagesExchanged?: number;
  isTeamLead?: boolean;
  lastEngagement?: string | null;
};

export function CareTeam(props: GraphQLProps<CareTeamData>) {
  const { careTeam } = props;
  const title = 'Care Team';
  if (
    careTeam !== null &&
    (careTeam.therapists.length > 0 ||
      careTeam.coaches.length > 0 ||
      careTeam.psychiatrists.length > 0 ||
      careTeam.ms.length > 0)
  ) {
    return (
      <Box title={title} testId="careTeamCard">
        <ImageList rowHeight={100} cols={2}>
          {careTeam.therapists.map(Clinician)}
          {careTeam.psychiatrists.map(Clinician)}
          {careTeam.coaches.map(CoachOrMS)}
          {careTeam.ms.map(CoachOrMS)}
        </ImageList>
      </Box>
    );
  } else {
    return (
      <Box title={title} testId="careTeamCard">
        <div data-testid="careTeamNotAvailable">Care Team not available</div>
      </Box>
    );
  }
}

export function CareTeamMember(props: Props) {
  const { name, role, avatar, isTeamLead } = props;
  const careTeamRole = getRoleLabel(role, isTeamLead || false);
  return (
    <div data-testid="careTeamMember" className={styles.careTeam}>
      <CareTeamTooltip {...props}>
        <div>
          <Avatar
            className={styles.avatar}
            firstName={name}
            lastName=""
            src={avatar}
          />
        </div>
      </CareTeamTooltip>
      <div className={styles.details}>
        <span className={styles.role}>{careTeamRole}</span>
        <span>{name}</span>
      </div>
    </div>
  );
}

type ClinicianProps =
  | CareTeamFragment_careTeam_therapists
  | CareTeamFragment_careTeam_psychiatrists;

function Clinician(props: ClinicianProps) {
  const { avatar, role, name, nextAppointment, lastAppointment } = props;
  return (
    <ImageListItem key={`clinician_${name}`}>
      <CareTeamMember
        avatar={avatar}
        role={role}
        name={name}
        nextAppointment={nextAppointment}
        lastAppointment={lastAppointment}
      />
    </ImageListItem>
  );
}

type CoachOrMSProps =
  | CareTeamFragment_careTeam_coaches
  | CareTeamFragment_careTeam_ms;

function CoachOrMS(props: CoachOrMSProps) {
  const {
    id,
    avatar,
    name,
    role,
    lastEngagement,
    isTeamLead,
    totalMessagesExchanged,
  } = props;
  return (
    <ImageListItem key={`coach_or_ms_${id}`}>
      <CareTeamMember
        avatar={avatar}
        name={name}
        role={role}
        lastEngagement={lastEngagement}
        isTeamLead={isTeamLead}
        totalMessagesExchanged={totalMessagesExchanged}
      />
    </ImageListItem>
  );
}

export function getRoleLabel(role: CareTeamRole, isTeamLead: boolean) {
  return role === CareTeamRole.COACH && isTeamLead
    ? `lead ${formatEnumValue(role).toLocaleLowerCase()}`
    : formatEnumValue(role).toLocaleLowerCase();
}
