import {
  getMessages,
  MIN_NUMBER_OF_MESSAGES,
  updateMessagesStatuses,
} from 'app/coach/chat/utils';
import { getPubErrorLogData } from 'app/coach/pubnub/errors';
import { usePubNubAPI } from 'app/coach/pubnub/PubNubContextProvider';
import { MessageToDisplay, MessageType } from 'app/coach/pubnub/types';
import { useInterval } from 'app/notes-ui/useInterval';
import { selectLastMemberRead } from 'app/state/features/conversationTimetokens/selectors';
import { useAppDispatch, useAppSelector } from 'app/state/hooks/baseTypedHooks';
import { setMessages } from 'app/state/inbox/actions';
import { selectMessages } from 'app/state/inbox/selectors';
import { displayableMessagesToString } from 'app/state/inbox/utils';
import { useLogger } from 'app/state/log/useLogger';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useOnMount } from 'hooks/useOnMount';
import { useEffect, useRef } from 'react';

export const POLL_INTERVAL_MS = 60_000; // 1 minute

export function usePollMessageHistory({
  channelId,
  memberId,
}: {
  channelId: string;
  memberId: string;
}) {
  const dispatch = useAppDispatch();
  const logger = useLogger();
  const {
    enable_periodic_refresh_of_chat_history,
  } = useFeatureFlags().transientFeatureFlags;
  const { getHistory } = usePubNubAPI();
  const currentMessages = selectMessages(channelId);
  const lastMemberReadTimetoken = useAppSelector((state) =>
    selectLastMemberRead(state, channelId),
  );
  const currentMessageIdSetRef = useRef(
    new Set(currentMessages.map((m) => m.id)),
  );
  const minMessageCount = currentMessageIdSetRef.current.size;
  useEffect(() => {
    currentMessageIdSetRef.current = new Set(currentMessages.map((m) => m.id));
  }, [currentMessages]);

  function updateMessageList(
    timetoken: string | null,
    messages: MessageToDisplay[],
    channel: string,
  ) {
    const messagesWithStatuses = updateMessagesStatuses(timetoken, messages);
    dispatch(
      setMessages({
        channelId: channel,
        messages: messagesWithStatuses,
      }),
    );
  }
  const retrieveAndCompareMessages = async () => {
    // load messages from pubnub, the number of messages returned could be >= minMessageCount.
    const messages = await getMessages({
      channelId,
      getHistory,
      logger,
      minMessageCount,
    });
    // To correctly compare messages in store and from pubnub, we need to sort the messages in descending order of
    // timetoken before splitting the messages from pubnub to equal the minMessageCount.
    const slicedMessages = [...messages]
      .sort((a, b) => parseInt(b.timetoken, 10) - parseInt(a.timetoken, 10))
      .slice(0, MIN_NUMBER_OF_MESSAGES);

    const missedMessages = slicedMessages.filter(
      (m) => !currentMessageIdSetRef.current.has(m.id),
    );
    // Only report the messages if they are not from the coach.
    const hasMissedMessages = missedMessages.some(
      (_) => _.type !== MessageType.TEXT_FROM_LISTENER,
    );

    return {
      hasMissedMessages,
      messages,
      missedMessages,
      slicedMessages,
    };
  };

  const pollAndCompareMessages = async () => {
    logger.debug('usePollMessageHistory: Polling message history', {
      channelId,
      memberId,
    });
    try {
      const {
        hasMissedMessages,
        slicedMessages,
        missedMessages,
        messages,
      } = await retrieveAndCompareMessages();
      if (hasMissedMessages) {
        logger.warning(
          'UsePollMessageHistory: some messages are currently not displayed to the coach',
          {
            channelId,
            currentMessagesInStore: displayableMessagesToString(
              currentMessages,
            ),
            memberId,
            messagesFromHistory: displayableMessagesToString(slicedMessages),
            missedMessages: displayableMessagesToString(missedMessages),
          },
        );
        updateMessageList(lastMemberReadTimetoken, messages, channelId);
      }
    } catch (e) {
      logger.warning('usePollMessageHistory: Error polling message history', {
        channelId,
        error: e,
        memberId,
        pubnubError: getPubErrorLogData(e),
      });
    }
  };

  useOnMount(() => {
    const run = async () => {
      try {
        const {
          slicedMessages,
          missedMessages,
          messages,
          hasMissedMessages,
        } = await retrieveAndCompareMessages();
        const additionalData: Record<string, string> = {};
        // only update if there are missed messages and we've initialized messages in the store
        if (hasMissedMessages && currentMessages.length > 0) {
          additionalData.currentMessagesInStore = displayableMessagesToString(
            currentMessages,
          );
          additionalData.messagesFromHistory = displayableMessagesToString(
            slicedMessages,
          );
          additionalData.missedMessages = displayableMessagesToString(
            missedMessages,
          );
          updateMessageList(lastMemberReadTimetoken, messages, channelId);
        }

        logger.info(
          `usePollMessageHistory: initial poll message history for ${memberId}`,
          additionalData,
        );
      } catch (e) {
        logger.warning('usePollMessageHistory: Error polling message history', {
          channelId,
          error: e,
          memberId,
          pubnubError: getPubErrorLogData(e),
        });
      }
    };
    run();
  });

  useInterval(
    pollAndCompareMessages,
    POLL_INTERVAL_MS,
    enable_periodic_refresh_of_chat_history,
  );
}
